<ng-container *appFeatureActive="['RingCentral']">
    <button *ngIf="ring_info?.is_app_alive && requester?.phone_number?.number && view_type==='default'" type="button" class="btn btn-sm btn-primary btn-icon" rippleEffect (click)="requesterCallingToggel(requester, requester_profile)">
        <i class="fa-light fa-phone"></i>
    </button>
    <button
        type="button"
        class="btn btn-sm btn-icon btn-outline-tertiary reply-sec action-list"
        ngbTooltip="{{ action.label | translate }}"
        container="body"
        (click)="requesterCallingToggel(requester, requester_profile)"
        rippleEffect
        *ngIf="ring_info?.is_app_alive && requester?.requester_phone && view_type==='panel_view'"
        attr.data-testid="btn_{{ action.label }}_{{ currentCardDisplayId }}"
    >
        <i class="fa-light {{ action.icon }} text-{{ action.iconColor }}"></i>
    </button>
    <button
        type="button"
        class="btn btn-sm btn-icon btn-outline-tertiary pointer mr-50"
        *ngIf="ring_info?.is_app_alive && requester?.requester_phone && view_type==='grid'"
        [ngStyle]="{
            color:
                action.action_name == 'UI.k_delete' ? '#ea5455' : '#0d51d9'
        }"
        (click)="requesterCallingToggel(requester, requester_profile)"
        tooltipClass="fadeInAnimation"
        placement="bottom"
        ngbTooltip="{{ action.tooltip | translate }}"
        id="link__{{ action['key'] }}__{{ currentCardDisplayId }}"
        attr.data-testid="link__{{ action['key'] }}__{{
            currentCardDisplayId
        }}"
    >
        <!--<i [size]="14" [data-feather]="action.icon_name"></i>-->
        <span
            ><i
                class="fa-light {{ action.icon_name }} {{ action?.class }}"
            ></i
        ></span>
    </button>
    <span
        *ngIf="ring_info?.is_app_alive && requester?.requester_phone && view_type==='normal_grid'"
        tooltipClass="fadeInAnimation"
        placement="left"
        class="mr-half"
        [ngbTooltip]="action.label"
        id="btn{{ action.key }}_{{ rowIndex }}"
        (click)="requesterCallingToggel(requester, requester_profile)"
    >
        <!--<i-->
        <!--[data-feather]="action.icon"-->
        <!--[class]="action.class"-->
        <!--[size]="18"-->
        <!--&gt;</i>-->
        <i class="fa-light {{ action.icon }} fa-lg {{ action.class }}"></i>
    </span>
</ng-container>