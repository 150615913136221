<!-- Menu header -->

<div
	class="navbar-header"
	[ngClass]="
		coreConfig?.layout?.menu?.logoCustomBackgroundColor === true ? coreConfig?.layout?.menu?.logoBackgroundColor : ''
	"
	[ngStyle]="{
		'background-color': coreConfig?.layout?.menu?.logoBackgroundColor?.includes('#')
			? coreConfig?.layout?.menu?.logoBackgroundColor
			: null
	}"
>
	<ul class="nav navbar-nav flex-row">
		<li class="nav-item mr-auto">
			<!-- App Branding -->
			<a class="navbar-brand">
				<span
					class="brand-logo"
					*ngIf="coreConfig.layout.menu.collapsed && (logo == 'null' || logo == 'undefined' || !logo)"
				>
				<ng-container *ngIf="coreConfig.app.appIconImage.match(regex)?.[0]">
					<img src="{{ coreConfig.app.appIconImage }}" alt="brand-logo" height="48" />
				</ng-container>
				<ng-container *ngIf="!coreConfig.app.appIconImage.match(regex)?.[0]">
					<img src="{{ coreConfig.app.appIconImage }}?time={{current_date}}" alt="brand-logo" height="48" />
				</ng-container>
					<!-- style="margin-left: -3.5rem"
						width="150px"
						height="50px" -->
				</span>
				<span class="brand-logo" *ngIf="logo != 'null' && logo !== 'undefined' && logo">
					<ng-container *ngIf="logo.match(regex)?.[0]">
						<img class="logo-check" src="{{ logo }}" alt="brand-logo" height="48" />
					</ng-container>
					<ng-container *ngIf="!logo.match(regex)?.[0]">
						<img class="logo-check" src="{{ logo }}?time={{current_date}}" alt="brand-logo" height="48" />
					</ng-container>
					<!-- style="margin-left: -3.5rem"
						width="150px"
						height="50px" -->
				</span>
				<span class="brand-logo 3" *ngIf="!coreConfig.layout.menu.collapsed">
					<ng-container *ngIf="coreConfig.app.appLogoImage.match(regex)?.[0]">
						<img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" />
					</ng-container>
					<ng-container *ngIf="!coreConfig.app.appLogoImage.match(regex)?.[0]">
						<img src="{{ coreConfig.app.appLogoImage }}?time={{current_date}}" alt="brand-logo" />
					</ng-container>
				</span>
				<h2
					class="brand-text mb-0 text-primary"
					style="
						font-size: 18px;
						font-weight: 900;"
				>
					{{ coreConfig.app.appName }}
				</h2>
			</a>
		</li>

		<!-- Menu Toggler -->
		<li class="nav-item nav-toggle">
			<!-- <a
				class="nav-link modern-nav-toggle d-none d-xl-block pr-0"
				(click)="toggleSidebarCollapsible()"
				id="toggleLeftPanel"
			>
				<i
					*ngIf="coreConfig.layout.menu.logoBackgroundColor"
					[ngClass]="isCollapsed === true ? 'icon-circle' : 'icon-disc'"
					class="toggle-icon feather font-medium-4 collapse-toggle-icon text-white"
				></i>
				<i
					*ngIf="!coreConfig.layout.menu.logoBackgroundColor"
					[ngClass]="isCollapsed === true ? 'icon-circle' : 'icon-disc'"
					class="toggle-icon feather font-medium-4 collapse-toggle-icon text-primary"
				></i>
			</a>
			<a
				class="nav-link modern-nav-toggle d-block d-xl-none pr-0"
				(click)="toggleSidebar()"
			>
				<i
					[data-feather]="'x'"
					class="font-medium-4 text-primary toggle-icon"
				></i>
			</a>-->
		</li>
	</ul>
</div>
<!--/ Menu header -->

<!-- Navbar shadow -->
<!-- <div class="shadow-bottom" [ngClass]="{ 'd-block': isScrolled }"></div> -->

<!-- Main menu -->
<!-- <div class="main-menu-content" [perfectScrollbar] (scroll)="onSidebarScroll()">
	<ul class="navigation navigation-main" layout="vertical" core-menu></ul>
</div> -->
<div class="main-menu-content">
	<ng-scrollbar class="custom-scrollbar section-scrollbar main-menu-scroll">
		<ul class="navigation navigation-main" layout="vertical" core-menu></ul>
	</ng-scrollbar>
</div>
<!--/ Main menu -->

<!-- Footer menu -->
<ng-container *ngIf="isAdminUser">
	<div class="footer-menu-content">
		<ul class="navigation navigation-main getStart">
			<!--<li class="navigation-header">-->
			<!--<span class="navigation-section-text">More</span>-->
			<!--&lt;!&ndash;<span [data-feather]="'more-horizontal'" [size]="18"></span>&ndash;&gt;-->
			<!--<span class="fa-light fa-ellipsis-stroke fa-lg"></span>-->
			<!--</li>-->
			<!--<li>-->
			<!--<a-->
			<!--class="d-flex align-items-center"-->
			<!--target="_self"-->
			<!--href="/"-->
			<!--id="manage"-->
			<!--id="linkMInfa"-->
			<!--name="linkMInfa"-->
			<!--&gt;-->
			<!--&lt;!&ndash; <span><i class="fa-light fa-screwdriver-wrench fa-lg"></i></span> &ndash;&gt;-->
			<!--<lottie-player-->
			<!--class="lottyicon-left"-->
			<!--src="../../../../../../assets/images/lotty/settings.json"-->
			<!--background="transparent"-->
			<!--speed="1"-->
			<!--style="width: 24px; height: 24px"-->
			<!--hover-->
			<!--&gt;-->
			<!--</lottie-player>-->
			<!--<span class="menu-title text-truncate">&nbsp;&nbsp;{{ "UI.k_manage_infraon" | translate }}</span>-->
			<!--</a>-->
			<!--</li>-->
			<!--<li>-->
			<!--<a-->
			<!--id="linkGSearch"-->
			<!--name="linkGSearch"-->
			<!--class="d-flex align-items-center"-->
			<!--(click)="openModal()"-->
			<!--aria-label="Close"-->
			<!--&gt;-->
			<!--&lt;!&ndash; <span><i class="fa-light fa-magnifying-glass fa-lg"></i></span> &ndash;&gt;-->
			<!--<lottie-player-->
			<!--class="lottyicon-left"-->
			<!--src="../../../../../../assets/images/lotty/search.json"-->
			<!--background="transparent"-->
			<!--speed="1"-->
			<!--style="width: 24px; height: 24px"-->
			<!--hover-->
			<!--&gt;-->
			<!--</lottie-player>-->
			<!--<span class="menu-title text-truncate">&nbsp;&nbsp;Search & Menu</span>-->
			<!--</a>-->
			<!--</li>-->

			<!-- <div class=" pl-75 getStart"
      style="
    
    "> -->
			<li class="border-top pt-1" routerLinkActive="active" [routerLinkActive]="'active'">
				<a
					class="d-flex align-items-center"
					target="_self"
					id="linkgetting_start"
					name="linkgetting_start"
					[routerLink]="['/get-started']"
				>
					<span id="get-trigger" name="get-trigger" class="d-flex justify-content-center align-items-center">
						<div style="width: 24px; height: 24px" background="transparent" speed="1" hover>
							<span id="get-container"></span>
						</div>

						<span class="menu-title text-truncate">
							&nbsp;&nbsp;
							{{ "UI.k_getting_started" | translate }}</span
						>
					</span>
				</a>
			</li>
			<ng-container *ngIf="isSuperAdminUser && !isPartnerUser">
				<li class="btn-bottom">
					<a
						class="d-flex align-items-center"
						target="_blank"
						id="linkMInfa1"
						name="linkMInfa1"
						[routerLink]="[]"
						(click)="ssoToAdminPortal($event)"
					>
						<!-- [href]="redirectURL" -->
						<!-- [routerLink]="[redirectURL, 'admin/dashboard']" -->
						<img src="../../../../../../assets/images/infraon-logo.svg" alt="brand-logo" height="24" />
						<span class="menu-title text-truncate"
							>&nbsp;&nbsp;{{ "UI.k_manage_subscription" | translate }}</span
						>
					</a>
				</li>
			</ng-container>
			<!-- </div> -->
		</ul>
	</div>
</ng-container>
<!-- <span><i class="fa-light fa-screwdriver-wrench fa-lg"></i></span> -->
<!--/ Footer menu -->
