import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UntypedFormControl, Validators } from "@angular/forms";
import { AdminService } from "../../service";
import {
	CustomerCard,
	InfraonProducts,
	InstanceIdGroup,
	InstanceTypeGroup,
	InstanceData,
	Product,
	Instance,
} from "../../models";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { map } from "rxjs/operators";
import store from "store";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";

@Component({
	selector: "app-admin-dashboard",
	templateUrl: "./admin-dashboard.component.html",
	styleUrls: ["./admin-dashboard.component.scss"],
})
export class AdminDashboardComponent implements OnInit {
	public infinityProduct: Product;
	public infraonProduct: InfraonProducts = {};
	public instanceSubscriptions: InstanceIdGroup[];
	public instanceTypeList: InstanceTypeGroup[];
	public instanceList: Instance[];
	public modalInstanceType: string = "prod";
	selectedData: InstanceData[];
	instanceForm = new UntypedFormControl("", Validators.required);
	public customerData: CustomerCard;
	public infinity_url = "https://infraon.io/infraon-infinity.html";
	public hideConfig: boolean = false;
	public paymentEvent = "add";
	public domain = "";
	public allotedUsers = 0;
	public allotedAssets = 0;
	public allotedUsersMap = {};
	public allotedAssetsMap = {};
	public sumInstanceMap = {};
	public colorInstanceMap = {};
	public usageObj = {};
	public instanceConfigMap = {};
	public reactivateObj: any;
	public isPartnerOrg: boolean = false;

	constructor(
		private _modalService: NgbModal,
		private router: Router,
		private _service: AdminService,
		private _coreSidebarService: CoreSidebarService,
		private _toastr: ToastrService,
		private _translateService: TranslateService
	) {}

	ngOnInit(): void {
		this.isPartnerOrg = store.get("is_partner_org");
		this.getOrgDetails();
		this.getUsageMetrics();
		this.getInstanceReactivateMetrics();
		this._service.getProductFamilies({ type: "category" }).subscribe({
			next: (response: any) => {
				this.infraonProduct = <InfraonProducts>response;
				// Skipping infraon-infinity
				for (let g of this.infraonProduct?.groups) {
					let newProductList = [];
					for (let gl of g.productList) {
						if (gl.is_infinity) this.infinityProduct = gl;
						else newProductList.push(gl);
					}
					g.productList = newProductList;
				}
			},
			error: (e: any) => {
				console.error(e);
			},
		});

		this._service
			.getInstanceSubscriptions({
				type: "instance",
				limit: 100,
			})
			.subscribe({
				next: (response: InstanceIdGroup[]) => {
					this.instanceSubscriptions = response;
					this.formatSubscriptionData();
				},
				error: (e: any) => {
					console.error(e);
				},
			});

		this._service
			.getInstances({
				type: "prod", // fetching prod specific instance
			})
			.subscribe({
				next: (response: InstanceTypeGroup[]) => {
					this.instanceTypeList = response;
					let newInstanceData = [];
					if (response && response.length < 2) {
						for (let d of this.instanceTypeList) {
							if (d.instance_type !== "trial")
								newInstanceData.push({
									instance_type: "trial",
									instances: [],
								});
							if (d.instance_type !== "prod")
								newInstanceData.push({
									instance_type: "prod",
									instances: [],
								});
						}
					}
					if (newInstanceData.length > 0) {
						this.instanceTypeList = newInstanceData.concat(this.instanceTypeList);
					}
					this.instanceTypeList.forEach((d) => {
						if (d.instance_type === this.modalInstanceType) {
							this.instanceList = d.instances;
						}
					});
				},
				error: (e: any) => {
					console.error(e);
				},
			});
	}

	instanceTotalPrice(data: InstanceData[]): number {
		let sum = 0;
		if (data) {
			for (let i = 0; i < data.length; i++) {
				if (data[i]?.item?.type === "plan") {
					let sub_items = data[i]?.subscription?.subscription_items;
					for (let i = 0; i < sub_items.length; i++) {
						sum += sub_items[i].amount;
					}
				}
			}
		}
		if (sum) sum = sum / 100;
		return sum;
	}

	trialCheck(data: InstanceData[]): boolean {
		if (data) {
			for (let i = 0; i < data.length; i++) {
				if (data[i].instance.domain_type === 0) return true;
			}
		}
		return false;
	}

	isTrailPaused(data: InstanceData[]): boolean {
		if (data) {
			for (let i = 0; i < data.length; i++) {
				if (data[i].subscription.status === "cancelled") return true;
			}
		}
		return false;
	}

	reactivateNextCycle(data: InstanceData[]) {
		let subsIds = new Set();
		if (data) {
			for (let i = 0; i < data.length; i++) {
				subsIds.add(data[i].subscription.id);
			}
		}
		if (subsIds.size > 0) {
			this._service
				.reactiveSubscription({
					data: JSON.stringify({
						subscriptions: JSON.stringify(Array.from(subsIds)),
						instance_id: data[0].instance.instance_id,
					}),
				})
				.subscribe({
					next: (response: any) => {
						// console.log(response);
						this._service
							.getInstanceSubscriptions({
								type: "instance",
								limit: 100,
							})
							.subscribe({
								next: (response: InstanceIdGroup[]) => {
									// console.log("172 response", response);
									this.instanceSubscriptions = [];
									this._toastr.success(
										this._translateService.instant("Message.msg_trial_extend"),
										this._translateService.instant("Message.msg_successfull_reactivation"),
										{
											toastClass: "toast ngx-toastr",
											closeButton: true,
											positionClass: "toast-top-right",
										}
									);
									this.instanceSubscriptions = response;
									this.formatSubscriptionData();
								},
								error: (e: any) => {
									this._toastr.error(
										this._translateService.instant("Message.msg_trial_extendf"),
										this._translateService.instant("Message.msg_failed_reactivation"),
										{
											toastClass: "toast ngx-toastr",
											closeButton: true,
											positionClass: "toast-top-right",
										}
									);
								},
							});
					},
					error: (e: any) => {
						console.error(e);
					},
				});
		}
	}

	getInstanceDetail(instance_id: any) {
		let url: string = "admin/instance/" + instance_id;
		this.router.navigateByUrl(url);
	}

	getProductDetail(product_id: any) {
		let url: string = "admin/product/" + product_id;
		this.router.navigateByUrl(url);
	}

	onInstanceChange(val: any) {
		this.instanceForm.setValue(val);
	}

	closePopup() {
		this._modalService.dismissAll();
	}

	togglePublishModal(modalPreview: string, data: InstanceData[], event) {
		// const modalRef = this._modalService.open(modalPreview, {
		// 	centered: true,
		// 	size: "lg", // size: 'xs' | 'sm' | 'lg' | 'xl',
		// 	windowClass: "modal-body-inner-footer",
		// });
		// console.log(".........data", typeof data, data);
		this.selectedData = data;
		this.paymentEvent = event;
		this.proceedToPayment();
	}

	toggleCInfo = (name) => {
		this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
		this.hideConfig = true;
	};

	proceedToPayment = () => {
		// console.log(".....proceedToPayment", this.selectedData);
		// if (this.instanceForm.value && this.selectedData) {
		if (this.selectedData) {
			// this.closePopup();
			console.warn("submitted: ", this.instanceForm.value);

			let subscription_id = [];
			let items = [];
			let quantity = [];
			let instances = [];
			let period_unit = "month";
			for (let i = 0; i < this.selectedData.length; i++) {
				subscription_id.push(this.selectedData[i].subscription.id);
				items.push(this.selectedData[i].item.id);
				if (this.selectedData[i].item?.type === "plan") {
					instances.push(this.selectedData[i].instance.instance_id);
				}
				this.selectedData[i].subscription.subscription_items.forEach((d) => {
					if (this.selectedData[i].item_price.id == d.item_price_id) quantity.push(d.quantity);
				});
				period_unit = this.selectedData[i].item_price.period_unit;
				if (this.selectedData[i].instance?.sub_domain) {
					this.domain = this.selectedData[i].instance.sub_domain;
				}
			}

			let params = {
				instance: instances.join(","), // this.instanceForm.value,
				items: items.join(","),
				period: period_unit,
				quantity: quantity.join(","),
				event: this.paymentEvent,
			};
			if (subscription_id.length > 0) {
				// TODO - need to move only one
				params["subscription"] = subscription_id[0];
			}
			if (this.domain) {
				params["domain"] = this.domain;
			}
			// console.log("params", params);
			this.router.navigate(["/admin/payment"], { queryParams: params });
		}
	};

	onHideProfile = (e) => {
		this.getOrgDetails();
		this.hideConfig = false;
	};

	getOrgDetails = () => {
		this._service.getCustomerData({}).subscribe({
			next: (response: CustomerCard) => {
				this.customerData = response;
			},
			error: (e: any) => {
				console.error(e);
			},
		});
	};

	getUsageMetrics = () => {
		this._service
			.getUsageCount()
			.pipe(
				map((response: any) => {
					if (response) {
						this.usageObj = response;
					}
					// Success
				})
			)
			.subscribe();
	};

	getSeverityColor = (allocated, key) => {
		let usage_per = (this.usageObj[key] / allocated) * 100;
		if (usage_per > 0 && usage_per <= 30) {
			return "success";
		} else if (usage_per > 30 && usage_per <= 70) {
			return "warning";
		} else if (usage_per > 70 && usage_per <= 100) {
			return "danger";
		} else {
			return "primary";
		}
	};

	formatSubscriptionData = () => {
		let addonCount = 0
		for (let i = 0; i < this.instanceSubscriptions.length; i++) {
			for (let curr_obj of this.instanceSubscriptions[i].data) {
				// console.log("curr_obj", curr_obj);
				if (curr_obj?.item?.type === "plan") {
					let curr_product = curr_obj?.product?.id;
					let count_obj = curr_obj?.subscription?.meta_data?.COUNT;
					if ("user" in count_obj) {
						this.allotedUsers = count_obj?.user[curr_product];
						this.allotedUsersMap[curr_obj?.instance?.instance_id] = count_obj?.user[curr_product];
					}
					if ("asset" in count_obj) {
						this.allotedAssets = count_obj?.asset[curr_product];
						this.allotedAssetsMap[curr_obj?.instance?.instance_id] = count_obj?.asset[curr_product];
						for (let addon_cnt in count_obj?.asset){
							if (addon_cnt !== curr_product){
								addonCount += count_obj?.asset[addon_cnt]
							}
						}
						if (addonCount > 0) {
							this.allotedAssetsMap[curr_obj?.instance?.instance_id] += addonCount
						}
					}
					let sum = 0;
					let sub_items = curr_obj?.subscription?.subscription_items;
					for (let sub_obj of sub_items) {
						sum += sub_obj.amount;
					}
					if (sum) sum = sum / 100;
					this.sumInstanceMap[curr_obj?.instance?.instance_id] = sum;
					this.colorInstanceMap[curr_obj?.instance?.instance_id] = {};
					this.colorInstanceMap[curr_obj?.instance?.instance_id]["user"] = this.getSeverityColor(
						this.allotedUsers,
						"user"
					);
					this.colorInstanceMap[curr_obj?.instance?.instance_id]["asset"] = this.getSeverityColor(
						this.allotedAssets,
						"asset"
					);
					if (!(curr_obj?.instance?.instance_id in this.instanceConfigMap)) {
						this.instanceConfigMap[curr_obj?.instance?.instance_id] = {};
					}
					this.instanceConfigMap[curr_obj?.instance?.instance_id]["status"] = curr_obj?.subscription.status;
					this.instanceConfigMap[curr_obj?.instance?.instance_id]["is_trial"] =
						curr_obj?.instance.domain_type === 0 ? true : false;
					this.instanceConfigMap[curr_obj?.instance?.instance_id]["is_cancelled"] =
						curr_obj?.subscription.status === "cancelled" ? true : false;
					this.instanceConfigMap[curr_obj?.instance?.instance_id]["show_buy"] = this.showBuy(
						curr_obj?.subscription.status,
						curr_obj?.instance.domain_type
					);
					this.instanceConfigMap[curr_obj?.instance?.instance_id]["show_reactivate"] = this.showReactivate(
						curr_obj?.subscription.status
					);
				}
			}
		}
		// console.log("instanceConfigMap", this.instanceConfigMap);
	};

	ssoToInstance = (event, domain) => {
		event.preventDefault();
		let accessToken = store.get("accessToken");
		if (!domain) {
			domain = "http://localhost:4300";
		}
		let abs_domain = domain + "/redirect/instance/" + accessToken;
		window.open(abs_domain, "_blank");
		// let instance_win = window.open(abs_domain, "_blank");
	};

	showBuy = (status, domain_type) => {
		if (
			(status !== "future" || status !== "non_renewing") &&
			((this.instanceSubscriptions.length === 1 && domain_type === 0) || domain_type === 1)
		) {
			// this.instanceSubscriptions.length > 1 &&
			return true;
		}
		return false;
	};

	showReactivate = (current_status) => {
		if (
			this.instanceSubscriptions.length === 1 &&
			this.reactivateObj?.count < 2 &&
			current_status === "cancelled"
		) {
			return true;
		}
		return false;
	};

	getInstanceReactivateMetrics = () => {
		this._service
			.getInstanceReactivateCount()
			.pipe(
				map((response: any) => {
					if (response) {
						this.reactivateObj = response;
					}
					// Success
				})
			)
			.subscribe();
	};
}
