import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewContainerRef } from "@angular/core";
import { RingCentralService } from "app/admin/service/ringcentral.service";
import { decryptData } from "app/common/utils/utils";
import { CommonService } from "app/services/common.service";
import WebPhone from "ringcentral-web-phone/lib/src/index";
import CryptoJS from "crypto-js";
import { MarketplaceService } from "app/marketplace/services/marketplace.service";
@Component({
	selector: "app-infraon-call",
	templateUrl: "./infraon-call.component.html",
	styleUrls: ["./infraon-call.component.scss"],
})
export class InfraonCallComponent implements OnInit {
	@Input() requester: any;
	@Input() requester_profile: any;
	@Input() view_type: any = "default";
	@Input() action: any = {};
	@Input() currentCardDisplayId: any = "";
	ring_info: any = {};
	clientId: any;
	clientSecret: any;
	serverURL: any;
	jwt_token: any;
	sender_number: any;
	webPhone: any;
	primaryNumber: any;
	extension: any;

	constructor(
		private _commonService: CommonService,
		private _ringCentral: RingCentralService,
		private _marketplaceService: MarketplaceService
	) {}

	ngOnInit(): void {
		this._marketplaceService.getMarketplaceAppDetails({ app_name: "RingCentral" }).subscribe((response) => {
			this.ring_info = response;
			this.clientId = decryptData(this.ring_info?.client_id);
			this.clientSecret = decryptData(this.ring_info?.secret_key);
			this.serverURL = this.ring_info?.server_url;
			this.jwt_token = decryptData(this.ring_info?.jwd_token);
		});
	}

	onLoginSuccess = (loginResponse) => {
		const remoteVideoElement = document.getElementById('remoteVideo') as HTMLMediaElement;
		const localVideoElement = document.getElementById('localVideo') as HTMLMediaElement;
		this._ringCentral
			.getRingCentralInstance()
			.get("/restapi/v1.0/account/~/extension/~")
			.then((res) => res.json())
			.then((res) => {
				this.extension = res;
				this._ringCentral.getRingCentralInstance().get("/restapi/v1.0/account/~/extension/~/phone-number");
			});

		this._ringCentral
			.getRingCentralInstance()
			.get("/restapi/v1.0/account/~/extension/~/phone-number")
			.then((res) => res.json())
			.then((res) => {
				let primary_no_list = res.records.filter((r) => r.primary === true);
				if (primary_no_list?.length > 0) {
					this.primaryNumber = res.records.filter((r) => r.primary === true)[0].phoneNumber;
				} else {
					if (res.records?.length > 0) {
						this.primaryNumber = res.records[0].phoneNumber;
					}
				}
			});

		return this._ringCentral
			.getRingCentralInstance()
			.post("/restapi/v1.0/client-info/sip-provision", {
				sipInfo: [{ transport: "WSS" }],
			})
			.then((res) => {
				return res.json().then((resp: any) => {
					return new WebPhone(resp, {
						// optional
						clientId: this.clientId,
						appName: "appName",
						appVersion: "appVersion",
						uuid: loginResponse.json().endpoint_id,
						logLevel: 1, // error 0, warn 1, log: 2, debug: 3
						maxReconnectionAttempts: 0,
						audioHelper: {
							enabled: true, // enables audio feedback when web phone is ringing or making a call
							incoming: "../../../../../assets/audio/incoming.ogg", // path to audio file for incoming call
							outgoing: "../../../../../assets/audio/outgoing.ogg", // path to aduotfile for outgoing call
						},
						media: {
							remote: remoteVideoElement, //this.remoteVideo.nativeElement,
							local: localVideoElement,
						},
						//to enable QoS Analytics Feature
						enableQos: true,
					});
				});
			});
	};

	handleError = (e) => {
		console.error(e);
		alert(e.message);
	};

	cleanPhoneNumber(phoneNumber: string): string {
		// Replace any non-digit character with an empty string
		const cleanedPhoneNumber = phoneNumber.replace(/\D/g, "");
		return cleanedPhoneNumber;
	}

	ngOnDestroy() {
		// this.hangUp();
		// this._ringCentral
		// 	.logout()
	}

	decryptContactData(data: any): any {
		let contact = data?.number;
		const Base64CBC = contact.replace('"', "").replace('"', "");
		const iv = CryptoJS.enc.Utf8.parse("Sa2017GO2021AR86");
		let key = "d99630sam7058639"; //key used in Python
		key = CryptoJS.enc.Utf8.parse(key);
		const decrypted = CryptoJS.AES.decrypt(Base64CBC, key, { iv: iv, mode: CryptoJS.mode.CBC });
		const decrypted_data = decrypted.toString(CryptoJS.enc.Utf8);
		data["number"] = decrypted_data;
		return data;
	}

	requesterCallingToggel = (requester, profile) => {
		this._ringCentral.initRingCentral(this.clientId, this.clientSecret, this.serverURL);
		this._ringCentral
			.getRingCentralInstance()
			.login({
				jwt: this.jwt_token,
			})
			.then((res) => {
				return this.onLoginSuccess(res);
			})
			.then((webPhone) => {
				this.webPhone = webPhone;
				this._commonService.requester.next({
					req: requester,
					reqProfile: profile,
					webPhone: webPhone,
					primaryNumber: this.primaryNumber,
				});
			})
			.catch((e) => {
				console.error(e.stack);
			});
	};
}
