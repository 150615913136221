import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { CoreConfigService } from '@core/services/config.service';
import { NgbModal, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { GlobalConstants } from 'app/app.globalConstants';
import { User } from 'app/auth/models';
import { AuthService } from 'app/base/auth/services/auth.service';
import { KBService } from 'app/base/knowledge-base/services/kb.service';
import { TagService } from 'app/base/tags/services/tag.service';
import { colors } from 'app/colors.const';
import { Breadcrumb } from 'app/common/layout/components/content-header/breadcrumb/breadcrumb.component';
import { customSearch, getAssetImageSrc, _deepCopyJson, convertToCalendarTime } from 'app/common/utils/utils';
import { IncidentViewService } from 'app/servicedesk/incident-view/services/incident-view.service';
import { FileUploader } from 'ng2-file-upload';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { ToastrService } from 'ngx-toastr';
import { TreeviewItem } from 'ngx-treeview';
import { Observable, Subscription, Subject, map, catchError, throwError, takeUntil } from 'rxjs';
import Swal from 'sweetalert2';
import { InfinityModuleIDs } from '../../types/module_type';
import { InfinityModules } from '../../types/modules';
import { DnsCommunicationComponent } from '../dns-communication/dns-communication.component';
import { TreeDataService } from '../single-tree-dropdown/tree-data.service';
import { StatusPickerComponent } from '../status-picker/status-picker.component';
import { environment } from 'environments/environment';
import cloneDeep from "lodash-es/cloneDeep";
import { formatDate, Location } from "@angular/common";
import { WSSService } from 'app/common/services/wss/wss.service';
import { RequestViewService } from 'app/servicedesk/request/services/request-view.service';
import { ProblemService } from 'app/servicedesk/problem/services/problem.service';
import { ReleaseService } from 'app/servicedesk/release-manage/services/release-services.service';
import { ChangeViewService } from 'app/servicedesk/change-management/services/change-view.service';
const URL = `${environment.instance_url}` + "media";
import { PermissionService } from "app/common/permission/service";
import moment from 'moment';
@Component({
  selector: 'app-common-process-view-sidebar',
  templateUrl: './common-process-view-sidebar.component.html',
  styleUrls: ['./common-process-view-sidebar.component.scss']
})
export class CommonProcessViewSidebarComponent implements OnInit {
	@Input() incidentRowData;
	@Input() incidentID;
	@Input() processID;
	@Input() handelType;
	@Input() moduleName;
	@Input() updateProcessData;
	@Output() onHideDetailSidebar = new EventEmitter<boolean>();
	editdiv;
	@ViewChild("sliderStatusPickerComponent") sliderStatusPickerComponent: StatusPickerComponent;
	@ViewChild("appovalChangeComp") appovalChangeComp: ElementRef;
	@Output("onApprove") onApprove = new EventEmitter();
	@ViewChild("modalReq") modalReq: ElementRef;
  private $trackBgColor = '#D8D8E3';
  public chartoptions;  
  isBreached: any = false;
	selectedItemName = "incident_details"
	selectedItem = 0;
	treeInputData = [];
  @Input() sidebarName: any;
  showData: boolean = true;
  public change_process_data:any;
  public stageInputData = [
		{
			id: "logged",
			name: "logged",
			label: "Logged",
			active: false,
			colorCode: "#1A5AD9",
			borderColorCode: "#1A5AD9",
			iconNonActive:true,
			currentStage:false,
			nonActiveColor:"#A0A3BD",
			code: 1,
			optionList: [
				{
					id: 1,
					label: "UI.k_assignment",
					callback: this.openAssignee.bind(this),
					// subOptionList: [
					// 	{ id: 1, label: "In Progress" },
					// 	{ id: 2, label: "On Hold" },
					// ],
				},
				{
					id: 2,
					label: "UI.k_On_Hold",
					state_id: 3,
					callback: this.onStageStatusChange.bind(this),
					// subOptionList: [
					// 	{ id: 1, label: "In Progress" },
					// 	{ id: 2, label: "On Hold" },
					// ],
				},
				{
					id: 3,
					label: "UI.k_resolution",
					state_id: 4,
					callback: this.onStageStatusChange.bind(this),
				},
			],
		},
		{
			id: "responded",
			name: "responded",
			label: "Responded",
			active: false,
			colorCode: "#1A5AD9",
			borderColorCode: "#1A5AD9",
			iconNonActive:true,
			currentStage:false,
			nonActiveColor:"#A0A3BD",
			code: 2,

			optionList: [
				{
					id: 1,
					label: "UI.k_In_progress",
					state_id: 2,
					callback: this.onStageStatusChange.bind(this),
					// subOptionList: [
					// 	{ id: 1, label: "In Progress" },
					// 	{ id: 2, label: "On Hold" },
					// ],
				},
				{
					id: 2,
					label: "UI.k_On_Hold",
					state_id: 3,
					callback: this.onStageStatusChange.bind(this),
					// subOptionList: [
					// 	{ id: 1, label: "In Progress" },
					// 	{ id: 2, label: "On Hold" },
					// ],
				},
				{
					id: 3,
					label: "UI.k_resolution",
					state_id: 4,
					callback: this.onStageStatusChange.bind(this),
				},
			],
		},
		// {
		// 	id: "assigned",
		// 	name: "assigned",
		// 	label: "Assigned",
		// 	active: false,
		// 	colorCode: "#28c76f",
		// 	borderColorCode: "#28c76f",
		// 	code: 3,

		// 	optionList: [
		// 		{
		// 			id: 1,
		// 			label: "Assigned In Progress",
		// 			subOptionList: [
		// 				{ id: 1, label: "In Progress" },
		// 				{ id: 2, label: "On Hold" },
		// 			],
		// 		},
		// 		{
		// 			id: 2,
		// 			label: "Assigned On Hold",
		// 			subOptionList: [
		// 				{ id: 1, label: "In Progress" },
		// 				{ id: 2, label: "On Hold" },
		// 			],
		// 		},
		// 		{ id: 3, label: "Assigned Resolution" },
		// 	],
		// },
		{
			id: "investigation",
			name: "investigation",
			label: "Investigation",
			active: false,
			colorCode: "#1A5AD9",
			borderColorCode: "#1A5AD9",
			iconNonActive:true,
			currentStage:false,
			nonActiveColor:"#A0A3BD",
			code: 4,
			check_state_id: 2,
			optionList: [
				{
					id: 1,
					label: "UI.k_In_progress",
					class: "dropdown-menu",
					state_id: 2,
					callback: this.onStageStatusChange.bind(this),
				},
				{
					id: 2,
					label: "UI.k_On_Hold",
					state_id: 3,
					class: "dropdown-menu",
					callback: this.onStageStatusChange.bind(this),
				},
				{
					class: "dropdown-item",
					id: 3,
					label: "UI.k_resolution",
					state_id: 4,
					callback: this.onStageStatusChange.bind(this),
				},
			],
		},
		{
			id: "onhold",
			name: "onhold",
			label: "On hold",
			active: false,
			colorCode: "#1A5AD9",
			borderColorCode: "#1A5AD9",
			iconNonActive:true,
			currentStage:false,
			nonActiveColor:"#A0A3BD",
			code: 5,
			check_state_id: 3,
			optionList: [
				{
					id: 1,
					label: "UI.k_In_progress",
					state_id: 2,
					callback: this.onStageStatusChange.bind(this),
				},
				{
					id: 2,
					label: "UI.k_On_Hold",
					state_id: 3,
					callback: this.onStageStatusChange.bind(this),
				},
				{
					id: 3,
					label: "UI.k_resolution",
					state_id: 4,
					callback: this.onStageStatusChange.bind(this),
				},
			],
		},
		{
			id: "approval",
			name: "approval",
			label: "Approval",
			active: false,
			colorCode: "#1A5AD9",
			borderColorCode: "#1A5AD9",
			iconNonActive:true,
			currentStage:false,
			nonActiveColor:"#A0A3BD",
			code: 8,
			check_state_id: 6,
			is_disabled: true,
			optionList: [
				{
					id: 1,
					label: "UI.k_In_progress",
					class: "dropdown-menu",
					state_id: 2,
					callback: this.onStageStatusChange.bind(this),
				},
				{
					id: 2,
					label: "UI.k_On_Hold",
					state_id: 3,
					class: "dropdown-menu",
					callback: this.onStageStatusChange.bind(this),
				},
				{
					class: "dropdown-item",
					id: 3,
					label: "UI.k_resolution",
					state_id: 4,
					callback: this.onStageStatusChange.bind(this),
				},
			],
		},
		{
			id: "resolution",
			name: "resolution",
			label: "Resolution",
			active: false,
			colorCode: "#1A5AD9",
			borderColorCode: "#1A5AD9",
			iconNonActive:true,
			currentStage:false,
			nonActiveColor:"#A0A3BD",
			code: 6,
			check_state_id: 4,
			optionList: [
				{
					id: 1,
					label: "UI.k_in_progress",
					state_id: 2,
					callback: this.onStageStatusChange.bind(this),
				},
				{
					id: 2,
					label: "UI.k_resolution",
					state_id: 4,
					callback: this.onStageStatusChange.bind(this),
				},
				{
					id: 3,
					label: "UI.k_closure",
					state_id: 5,
					callback: this.onStageStatusChange.bind(this),
				},
			],
		},
		{
			id: "closure",
			name: "closure",
			label: "Closure",
			active: false,
			colorCode: "#1A5AD9",
			borderColorCode: "#1A5AD9",
			iconNonActive:true,
			currentStage:false,
			nonActiveColor:"#A0A3BD",
			code: 7,
			check_state_id: 5,
			optionList: [
				{
					id: 1,
					label: "UI.k_closure",
					state_id: 5,
					callback: this.onStageStatusChange.bind(this),
				},
			],
		},
	];
  public team_list = [
		{
			id: 0,
			handle: "one",
			team_name: "Service Team",
			team_location: "Bangalore"
		},
		{
			id: 1,
			handle: "two",
			team_name: "Service Team",
			team_location: "Bangalore"
		},
		{
			id: 2,
			handle: "three",
			team_name: "Service Team",
			team_location: "Bangalore"
		},			
	];
	public configuration_list = [];
	public hideProblemSideBar: boolean = true;
	hideProblemSolutionSideBar: boolean = true;
	public hideProblemDraftSideBar: boolean = true;
	current_root_cause_id: any = "-1";
	current_root_cause_type: any = "";
	@ViewChild(DnsCommunicationComponent) dnsCommunication: DnsCommunicationComponent;
	private service_change_data: any;
	prev_current_assignment_info: any = {};
	matricsData:any;
	change_permissions: any = {};
	problem_permissions: any = {};
	release_permissions: any = {};
	temp_status: any;
	is_priority_change: boolean = false;
	is_priority_load: boolean = false;
	service_config = {};
	classification_config = {};
	is_service_change: boolean;
	copyOfstageInputData: any = [];
	allRelations: any = [];
	change_data: any = {};
	incident_data: any = {};
	problem_data: any = {};
	release_data: any = {};
	selectedIMAPValue: any;
	imapSelectedText: any;
	signatureInputData = [];
	selectedSignatureValue: any;
	previewData: any;
	featureViewTaskData: any;
	timeout: any = null;
	signatureTreeHeading = this._translateService.instant("UI.k_manage_signature");
	selectSignatureText = this._translateService.instant("UI.k_add_signature");
	@ViewChild("previewSignature") previewSignature: ElementRef;
	@ViewChild("previewResponse") previewResponse: ElementRef;
	// IMAP
	imapInputData=[]
	selectImapText = this._translateService.instant("UI.k_add_imap_config")
	is_from_disabled = false;
	imap_list: any;
	sourceID: any;
	commonActionMap: any;
	communicationModuleType: string;
	showReqResolve: boolean;
	showReqClose: boolean;
	showProbResolve: boolean;
	showProbClose: boolean;
	showConvReqToInci: boolean;
	showConvReqToInciView: boolean;
	showConvReqToChange: boolean;
	hideRequestInlineEdit: boolean;
	hideProblemInlineEdit: boolean;
	hideChangeInlineEdit: boolean;
	hideReleaseInlineEdit: boolean;
	processSourceId:  any;
	module = InfinityModules.INCIDENT;
	source_id_map: any = {
		10: "incident_source_id",
		42: "request_source_id",
		45: "problem_source_id",
		47: "change_source_id",
		53: "release_source_id",
	};
	source_name_map: any = {
		10: "incident_source_name",
		42: "request_source_name",
		45: "problem_source_name",
		47: "change_source_name",
		53: "release_source_name",
	};
	router_link_map: any = {
		10: "/incident_view/incident-edit/",
		42: "/request_list/request-edit/",
		45: "/problem_management/problem-edit/",
		47: "/change/edit/",
		53: "/rel/edit/",
	};
	taskDropDown: any = [
		{
			key: "task",
			name: "Task",
			type_id: 1,
		},
		{
			key: "feature",
			name: "Feature",
			type_id: 2,
		},
		{
			key: "issue",
			name: "Issue",
			type_id: 3,
		},
	];
	showConvProbToInci: boolean;
	showConvProbToInciView: boolean;
	showConvProbToChange: boolean;
	showConvChnToInciView: boolean;
	showConvChnToInci: boolean;
	startEndDateInvalid: boolean = false;
	startEndDateToaster: any;
	isExpandSearchWidth: boolean;
	public savedTaskData = [];
	hideViewTaskProfile: boolean;
	public dueDateTimeOptions: FlatpickrOptions = {
		altInput: true,
		allowInput: false,
		enableTime: true,
		onClose: (selectedDates: any) => {
			if(selectedDates && selectedDates instanceof Object 
			&& selectedDates?.length > 0){
				let formatedDate = formatDate(selectedDates, 'MMM d, yyyy HH:mm a','en')
				this.updateChangeDueDate(selectedDates, formatedDate)
			}
		},
	};
	showAddReleaseAttachment: boolean;
	showChangeClose: boolean;
	showReleaseClose: boolean;
	showAssetListView: boolean = false;
	infraonAIFeautreFlag = ["InfraonAI"];
	kbAISolution : any = {}
	showAIKKSolution = false
	is_ssp_login: boolean = false;
	resolve_action: any;
	closeSSPSidebarName: string;
	showCloseRequestSidebar: boolean;
	showCloseTicketSidebar: boolean;
	notClosedTask: any;
	constructor(
		private _router: Router,
		private _coreSidebarService: CoreSidebarService,
		private _incidentViewService: IncidentViewService,
		private _requestViewService: RequestViewService,
		private _problemViewService: ProblemService,
		private _releaseViewService: ReleaseService,
		private _changeViewService: ChangeViewService,
		private _toastr: ToastrService,
		private _modalService: NgbModal,
		private _tagService: TagService,
		private _translateService: TranslateService,
		private route: ActivatedRoute,
		private _domSanitizerService: DomSanitizer,
		private _authService: AuthService,
		private _coreConfigService: CoreConfigService,
		// private datepipe:DatePipegetIncidentRichTextData
		private _location: Location,
		public _kbService: KBService,
		public treeSvc: TreeDataService,
		private _wssService: WSSService,
		private permissionManagerS: PermissionService
	) {

		// 	this.someHtmlCode = '<div class="xs-font"> (mail_list?.new_reply_mail? mail_list.new_reply_mail: mail_list?.content)</div>'
		this._authService.currentUser.subscribe((x) => (this.currentUser = x));
		this._unsubscribe = new Subject();
		// Goal Overview  Chart
		this.goalChartoptions = {
			chart: {
				height: 245,
				type: "radialBar",
				sparkline: {
					enabled: true,
				},
				dropShadow: {
					enabled: true,
					blur: 3,
					left: 1,
					top: 1,
					opacity: 0.1,
				},
			},
			colors: [this.$goalStrokeColor2],
			plotOptions: {
				radialBar: {
					offsetY: -10,
					startAngle: -150,
					endAngle: 150,
					hollow: {
						size: "77%",
					},
					track: {
						background: this.$strokeColor,
						strokeWidth: "50%",
					},
					dataLabels: {
						name: {
							show: false,
						},
						value: {
							color: this.$textHeadingColor,
							fontSize: "2.86rem",
							fontWeight: "600",
						},
					},
				},
			},
			fill: {
				type: "gradient",
				gradient: {
					shade: "dark",
					type: "horizontal",
					shadeIntensity: 0.5,
					gradientToColors: [colors.solid.success],
					inverseColors: true,
					opacityFrom: 1,
					opacityTo: 1,
					stops: [0, 100],
				},
			},
			stroke: {
				lineCap: "round",
			},
			grid: {
				padding: {
					bottom: 30,
				},
			},
		};

	}
	_unsubscribe: any;
	@ViewChild("scrollMe") scrollMe: ElementRef;
	@ViewChild("CurrentAssigneeComp") currentAssigneeComp: ElementRef;
	// @ViewChild(NgScrollbar, { static: true }) scrollbarRef: NgScrollbar;
	// public someHtmlCode:any
	disable_config = false;
	disable_status_config = false
	scrolltop: number = null;
	scrollBottom = true;
	stickySec = true;
	dataLoaded = false;
	currentEvent: String;
	currentRequester: Number;
	hideProfile = false;
	public data: any;
	public breadcrumbObj: Breadcrumb;
	public isCollapsedDetails = true;
	private $goalStrokeColor2 = "#51e5a8";
	private $textHeadingColor = "#5e5873";
	private $strokeColor = "#ebe9f1";
	public goalChartoptions: Partial<any>;
	public allAttachments: any = [];
	public historyDataset: any = [];
	mail_list = [];
	copyOfAllCard = [];
	// kb_list = [];
	showKB = true;
	showSearch = true;
	showSearchInput = false;
	showEditor = false;
	showReplyBox = true;
	blured = false;
	focused = false;
	showResolveTicket = false;
	service = {};
	summary_card_config = {};
	hideIncidentInlineEdit = false;
	incident_id: number;
	public process_data;
	public process_data_copy;
	emailContent;
	custom_field_config: any;
	custom_field_data: any;
	communicationFilterSelection = "all";
	internalNoteFilterSelection = "all";
	public prev_incident_id = "";
	public next_incident_id = "";
	public gridViewRef = false;
	public kbInput = {};
	public attachedKb = [];
	public selected_attachment_id = "all";
	public selected_attachment_name = this._translateService.instant("UI.k_all");
	// Select Multi with Icon
	public multiIconGithubUsers: Observable<any[]>;
	public multiIconGithubUsersSelected = ["anjmao"];
	public load_alert_details = false;
	public modifiedtime: any;
	module_events = InfinityModules.EVENTS;
	public custom_workflow_fields: any;
	reloadCommunication = false;
	public resolution_date = undefined;
	public dropdown = {
		expertise: [],
		level: [],
		assignee: [],
		classifications: [],
		state: [],
		status: [],
		urgency: [],
		priority: [],
		severity: [],
		event_status: [],
		type: [],
		impact: [],
		tagList: [],
		tagTypeList: [],
		users: [],
		teams: [],
		requesters: [],
		formatted_services: [],
		formatted_classification_data: [],
		// service_category_classification_map: {},
		incident_source: [],
		groupt_type_list: [],
		close_type: [],
		closure_category_list: [],
		attach_category_list: [],
		// hold_status: [],
		state_status_map: {},
		current_user_group: [],
		teams_and_self_assign: [],
		to_address: [],
		cc_address: [],
		attach_filter_category_list: [],
		state_id_map: {},
		filtered_status: [],
		current_user_expertise: [],
		stage_state_option_map: {},
		team_user_profile_map: {},
		incident_prefix_list: [],
		relation_type: [],
		transition_status: [],
		service_change_status: [],
		CHANGE_MAJOR_CHANGE_TYPE: -1,
		CHANGE_MINOR_CHANGE_TYPE: -1,
		user_profile_map: []
	};
	// stateStatusMap :{};
	stateKeyIdMap: {};
	public extra_info = {
		selected_assignee_info: {
			profile_image: "",
			full_name: "",
			email: "",
			profile: {
				avatar_color: "",
			},
		},
		group_type: "Expert",
		level: "",
		expertise: "",
		level_id: "",
		expertise_id: "",
		group_name: "",
	};
	public ALLOWED_TYPES;
	public MAX_IMAGE_SIZE;
	public MAX_HEIGHT;
	public MAX_WIDTH;
	public incidentId = undefined;
	public attachmentUploader: FileUploader = new FileUploader({
		url: URL,
		isHTML5: true,
	});
	isEmailCompOpen = false;
	supportEmail;
	domain;
	removeQuotedTxt = false;
	showQuotedTxt = false;
	showSymptomsEditor = false;
	public erichTextData = "";
	public quillEditorStyles = { "min-height": "80px" };
	public CustomDayDPdata: NgbDateStruct;
	public editorId = "";
	public root_cause = "";
	public recovery_action = "";
	public trouble_reason = "";
	impact_id: String = "Add";
	showImpactAdd: boolean = false;
	showImpact: boolean = false;
	showRisk: boolean = false;
	public isPrivate: any = "false";
	taskFilterData: boolean = false;
	taskShowAll: boolean = false;
	public workDuration = {
		day: undefined,
		hr: undefined,
		min: undefined,
	};

	public editorIDMap = {
		"Add Notes": this._translateService.instant("UI.k_add_notes"),
		Worklog: this._translateService.instant("UI.k_worklog"),
		Symptoms: this._translateService.instant("UI.k_symptoms"),
		Analyse: this._translateService.instant("UI.k_analyse"),
		"Root Cause": this._translateService.instant("UI.k_root_cause"),
		"Closure Note": this._translateService.instant("UI.k_close_incident"),
		Resolution: this._translateService.instant("UI.k_resolution"),
		Diagnosis: this._translateService.instant("UI.k_diagnosis"),
		"Service Change": this._translateService.instant("UI.k_service_change"),
		Approval: this._translateService.instant("UI.k_approval"),
		"Reopen Note": this._translateService.instant("UI.k_reopen_note"),
	};
	public hourList = [];
	public minList = [];
	public rich_text_list = [];
	public isHideAddAttachment = true;
	public imgHeightWidthStr = "img height='400px' width='100%'";
	public emailCCSelected = [];
	public emailBCCSelected = [];
	private _scrollSubscription = Subscription.EMPTY;
	public isOpenCC = false;
	public isOpenBCC = false;
	public filesList: any = [];
	showEmails = true;
	showComments = true;
	showSMS = true;
	isAddTask: boolean = false;
	isViewTask: boolean = false;
	public attachmentCategoryList = [
		{ id: "1", value: "Incident Screenshot" },
		{ id: "2", value: "Others" },
		{ id: "3", value: "Team Docs" },
	];
	public filteredAttachmentByCategory: any = {};
	public attach_category_list;
	status = "1";
	// status_color_map = {
	// 	5: "success",
	// 	4: "success",
	// 	3: "warning",
	// 	2: "danger",
	// 	1: "danger",
	// };
	public selectedAttachCategory = "all";
	commDataTotalCount = 0;
	commDataCount = 0;
	page = 1;
	items_per_page = 10;
	notEmptyCommData = true;
	finished = false;
	fromInit = true;
	incidentRelations: any = [];
	requestProcessMapping: any = {};
	problemProcessMapping: any = {};
	changeProcessMapping: any = {};
	releaseProcessMapping: any = {};
	incidentMapping: any = {};
	kbCurrentItem = "solution";
	kbEvent: any;
	kbSolution: any = {};
	kbHideProfile = false;
	kbSolutionHideProfile = false;
	public allKBs = [];
	public filteredKBs = [];
	public isConvertedToKB = false;
	selfAssignSel = false;
	currentUser: User;
	team_id;
	currentLevel;
	showTaskData: boolean = false;
	public dateTimeOptions: FlatpickrOptions = {
		altInput: true,
		enableTime: true,
		dateFormat: "%b %d, %Y %I:%M %p",
		altFormat: "M d, Y H:i K",
		mode: "single",
		altInputClass: "form-control flat-picker flatpickr-input",
	};
	public agreedDateTimeOptions: FlatpickrOptions = {
		altInput: true,
		enableTime: true,
		dateFormat: "%b %d, %Y %I:%M %p",
		altFormat: "M d, Y H:i K",
		mode: "single",
		altInputClass: "form-control flat-picker flatpickr-input",
	};
	public showDetails = false;
	currentUserProfileId: any;
	currentAssignmentInfo: any = {};
	current_extra_info: any = {};
	isReport = false;
	public showKb = true;
	public dataDup = [];
	public kbDataDup = [];
	public suggested_kb = [];
	isStageDataLoaded = false;
	load_inci_resolve = false;
	process_extra_info: any = {};
	historydup: any;
	firstKey = 0;
	selectedOption = 10;
	cardCount = this.selectedOption;
	lastKey = this.cardCount - 1;
	notScrolly = true;
	latestEmail: any;
	userTZ: any;
	permissions: any = {};
	kb_permissions: any = {};
	request_permissions: any = {};
	events_permissions: any = {};
	ticket_permissions: any = {};
	module_kb = InfinityModules.KNOWLEDGEBASE;
	module_request = InfinityModules.REQUEST_PROCESS;
	// module: any = "incident";
	riskFilter: any = [];
	module_id = 10;
	request_module_id = 42;
	incident_module_id = InfinityModuleIDs?.INCIDENT;
	showConvInciToReq: boolean = false;
	showConvInciToReqView: boolean = false;
	request_data: any = {};
	relationModuleIDSel: any = InfinityModuleIDs.INCIDENT;
	relationModule: any;
	kb_module_id = InfinityModuleIDs?.KNOWLEDGEBASE;
	problem_module_id = InfinityModuleIDs?.PROBLEM;
	change_module_id = InfinityModuleIDs?.CHANGE;
	release_module_id = InfinityModuleIDs?.RELEASE;
	infinity_modules = InfinityModules;
	infinity_module_ids = InfinityModuleIDs;
	public riskchangeData: any = [];
	riskCount = 0;
	risk_columns: any = [];
	risk_id: any = "-1";
	hideRiskProfile: boolean;
	riskPageNumber = 0;
	riskLimit = 10;
	risk_list: any;
	riskSortKey: any = "name";
	riskReverse: any = "";
	riskShowAll: boolean = false;
	is_risk_loading: boolean = false;
	currentRiskEvent: String = "Add";
	filterData: boolean = false;
	public savedData = [];
	public columnOptions = [];
	summaryInput = {};
	hideTaskProfile: boolean;
	currentTaskEvent: string;
	is_task_loading: boolean;
	taskCount: any;
	taskFilter: any;
	taskPageNumber: number = 1;
	taskLimit: number = 10000;
	task_id: string = "-1";
	taskMaxDueDate: any;
	showDataTable = false;
	public taskColumnOptions = [];
	public taskConditionOptions = [];
	taskSortKey: any = "name";
	taskReverse: any = "";
	task_type_id: any = 1;
	private _unsubscribeAll: Subject<any>;
	isShownRequester: boolean = false; // hidden by default
	requester_temp;
	requesterSearchStr: any;
	public incident_data_temp;
	setImpactService: boolean = false;
	searcRequestLen = 0;
	requesterList: any = [];
	public searchFalse = true;
	sourceIconMap = {
		1: "globe",
		2: "envelope",
		3: "bell",
		4: "mobile",
		5: "phone",
		6: "comment-lines",
		7: "comment-dots",
		8: "microsoft"
	};
	callbackIconMap = {
		email: "envelope",
		phone: "phone",
		self_service: "user",
	};
	callbackNameMap = {
		email: this._translateService.instant("UI.k_email"),
		phone: this._translateService.instant("UI.k_phone"),
		self_service: this._translateService.instant("UI.k_self_service"),
	};
	selectedCount = 10;
	showAttachedKB = false;
	totalCount: number;
	kbPageNumber = 1;
	kbCount: number;
	selected_status: any;
	asset_module = InfinityModules.CMDB;
	asset_permissions: any = {};
	lav_module = "SERVICEDESK";
	module_permissions: any = {};
	showAddRelation = false;
	disableCommentSubmit = false;
	asgnDisabled = false;
	escalataionData: any = {};
	showEscalation = false;
	impactData: any = [];
	impactSortKey: any = "creation_time";
	impactReverse: any = "";
	imageMap = {
		article: "assets/images/illustration/personalization.svg",
		faq: "assets/images/illustration/marketing.svg",
		information: "assets/images/illustration/api.svg",
		known_error: "assets/images/illustration/demand.svg",
		solution: "assets/images/illustration/email.svg",
	};
	public selectedFiles = [];
	internelNotes = ["Comment", "Worklog", "Symptoms", "Analyse", "Diagnosis", "RootCause"];

	disk = {
		edit: [
			{ key: "Impact", value: "Bussiness" },
			{ key: "Service classification", value: "Servive classification12" },
			{ key: "Severity", value: "Critical" },
			{ key: "Event type", value: "Jp" },
			{ key: "Actual start time", value: "-" },
			{ key: "Workflow", value: "workflow name23" },
		],
		report: [
			{ key: "Name", value: "-" },
			{ key: "Email", value: "-" },
			{ key: "Phone number", value: "-" },
		],
	};
	is_accept_button = false;
	is_rollback = false;
	submitActions: any = ["RESOLVE", "APPROVE", "COMPLETE"];
	public taskData: any = [];
	resolutionDetails = "We contained the incident and prevented further exposure, conducted a thorough investigation, and implemented additional security measures to prevent future breaches. We also communicated with affected customers and reviewed our policies and procedures to prevent similar incidents."
	displayAllInteraction: boolean = true;
	requester_history = [];
	public requester_history_dup = [];
	showConvIncidentToChange: boolean = false;
	showConvIncidentToChangeView: boolean = false;
	showConvInciToProblemView: boolean;
	showConvInciToProblem: boolean;
	search_db_cols = [
		{ name: "task", db_col: "display_id", cond: "equal to" },
		{ name: "Name", db_col: "name", cond: "contains" },
		{ name: "Status", db_col: "status__name", cond: "contains" },
		{ name: "Status", db_col: "status__state", cond: "contains" },
		{ name: "Priority", db_col: "priority__name", cond: "contains" },
		{ name: "Assignee", db_col: "assignee_profile__full_name", cond: "contains" },
		{ name: "Type", db_col: "type__name", cond: "contains" },
	];
	public getImageSrc = getAssetImageSrc;
	@ViewChild("planStartDate") calendar: FlatpickrOptions;
	@ViewChild("planEndDate") Duedate: FlatpickrOptions;
	public plnStartDateOptions: FlatpickrOptions = {
		altInput: true,
		enableTime: true,
	};
	public plnEndDateOptions: FlatpickrOptions = {
		altInput: true,
		enableTime: true,
	};
	isinfraonAIFeautreFlagActive : boolean = false;
	/**
	 * Toggle CC & BCC
	 *
	 * @param toggleRef
	 */


	ngOnInit(): void {
		this.commonActionMap =
		{
			'Incident': {
				'edit': this.incidentEdit,
				'get': this.getIncidentData,
				'getTeamUser': this.getIncidentTeamUser,
				'getAttachmentDetails': this.getincidentAttachmentDetail,
				'getHistoryDetails': this.getIncidentHistoryDetail,
				'getAllKBs': this.getIncidentAllKBs,
				'getRelation': this.getIncidentRelation,
				'getRichTxtData': this.getIncidentRichTextData,
				'getMailConversations': this.getIncidentMailConversations,
				'convertToKB': this.convertIncidentToKB,
				'getSLAInfoData': this.getIncidentSLAInfoData,
				'getUserProfile': this.getIncidentUserProfile,
				'saveConversation': this.saveIncidentConversation,
				'getApprovalStatus': this.getIncidentApprovalStatus,
				'getRefMailConversations': this.getIncidentRefMailConversations,
				'getRequesterHistory': this.getIncidentRequesterHistory,
				'getIncidentProblem': this.getIncidentProblem,
				'converIncidentToProblem': this.converIncidentToProblem,
				'getProcessChange': this.getIncidentChange,
				'converProcessToChange': this.converIncidentToChange,
				'getServiceBaseWorkflow': this.getIncidentServiceBaseWorkflow,
				'getRequesterImpactService': this.getIncidentRequesterImpactService,
				'getImpactServiceClassification': this.getIncidentImpactServiceClassification,
				'updateMsgReadStatus': this.updateIncidentMsgReadStatus,
				'saveRichTextData': this.saveIncidentRichTextData,
				'updateApprovalData': this.updateIncidentApprovalData,
				'getIncidentRequest': this.getIncidentRequest,
				'conIncidentToRequest': this.conIncidentToRequest,
				'deleteAttachment': this.deleteIncidentAttachment
			},
			'Request': {
				'edit': this.requestEdit,
				'get': this.getRrequestData,
				'getTeamUser': this.getRequestTeamUser,
				'getAttachmentDetails': this.getRequestAttachmentDetail,
				'getHistoryDetails': this.getRequestHistoryDetail,
				'getAllKBs': this.getRequestAllKBs,
				'getSLAInfoData': this.getRequestSLAInfoData,
				'getRelation': this.getRequestRelation,
				'getRichTxtData': this.getRequestRichTextData,
				'getMailConversations': this.getRequestMailConversations,
				'convertToKB': this.convertRequestToKB,
				'getUserProfile': this.getRequestUserProfile,
				'saveConversation': this.saveRequestConversation,
				'getApprovalStatus': this.getRequestApprovalStatus,
				'getRefMailConversations': this.getRequestRefMailConversations,
				'getRequesterHistory': this.getRequestRequesterHistory,
				'getProcessChange': this.getRequestChange,
				'converProcessToChange': this.converRequestToChange,
				'getServiceBaseWorkflow': this.getRequestServiceBaseWorkflow,
				'getRequesterImpactService': this.getRequestRequesterImpactService,
				'getImpactServiceClassification': this.getRequestImpactServiceClassification,
				// 'updateMsgReadStatus': this.updateRequestMsgReadStatus,
				'saveRichTextData': this.saveRequestRichTextData,
				'updateApprovalData': this.updateRequestApprovalData,
				'deleteAttachment': this.deleteRequestAttachment,
				'converProcessToIncident': this.convertRequestToIncident,
				'getProcessIncident': this.getRequestIncident,
			},
			'Problem': {
				'edit': this.problemEdit,
				'get': this.getProblemData,
				'getTeamUser': this.getProblemTeamUser,
				'getAttachmentDetails': this.getProblemAttachmentDetail,
				'getHistoryDetails': this.getProblemHistoryDetail,
				'getAllKBs': this.getProblemAllKBs,
				'getRelation': this.getProblemRelation,
				'getRichTxtData': this.getProblemRichTextData,
				'getMailConversations': this.getProblemMailConversations,
				'convertToKB': this.convertProblemToKB,
				'getUserProfile': this.getProblemUserProfile,
				'saveConversation': this.saveProblemConversation,
				'getApprovalStatus': this.getProblemApprovalStatus,
				'getRefMailConversations': this.getProblemRefMailConversations,
				'getRequesterHistory': this.getProblemRequesterHistory,
				'getProcessChange': this.getProblemChange,
				'converProcessToChange': this.converProblemToChange,
				'getServiceBaseWorkflow': this.getProblemServiceBaseWorkflow,
				'getRequesterImpactService': this.getProblemRequesterImpactService,
				'getImpactServiceClassification': this.getProblemImpactServiceClassification,
				// 'updateMsgReadStatus': this.updateRequestMsgReadStatus,
				'saveRichTextData': this.saveProblemRichTextData,
				'updateApprovalData': this.updateProblemApprovalData,
				'deleteAttachment': this.deleteProblemAttachment,
				'converProcessToIncident': this.convertProblemToIncident,
				'getProcessIncident': this.getProblemIncident,
			},
			'Release': {
				'edit': this.releaseEdit,
				'get': this.getReleaseData,
				'getTeamUser': this.getReleaseTeamUser,
				'getAttachmentDetails': this.getReleaseAttachmentDetail,
				'getHistoryDetails': this.getReleaseHistoryDetail,
				'getAllKBs': this.getReleaseAllKBs,
				'getRelation': this.getReleaseRelation,
				'getRichTxtData': this.getReleaseRichTextData,
				'getMailConversations': this.getReleaseMailConversations,
				'convertToKB': this.convertReleaseToKB,
				'getUserProfile': this.getReleaseUserProfile,
				'saveConversation': this.saveReleaseConversation,
				'getApprovalStatus': this.getReleaseApprovalStatus,
				'getRefMailConversations': this.getReleaseRefMailConversations,
				'getRequesterHistory': this.getReleaseRequesterHistory,
				'getServiceBaseWorkflow': this.getReleaseServiceBaseWorkflow,
				'getRequesterImpactService': this.getReleaseRequesterImpactService,
				'getImpactServiceClassification': this.getReleaseImpactServiceClassification,
				// 'updateMsgReadStatus': this.updateRequestMsgReadStatus,
				'saveRichTextData': this.saveReleaseRichTextData,
				'updateApprovalData': this.updateReleaseApprovalData,
				'deleteAttachment': this.deleteReleaseAttachment,
				'converProcessToIncident': this.convertReleaseToIncident,
				'getFilterOptions': this.getReleaseFilterOptions,
				'getTaskList': this.getReleaseTask,
				'getSavedFilters':this.getReleaseSavedFilters,
				'deleteTask': this.deleteReleaseTask,
			},
			'Change': {
				'edit': this.changeEdit,
				'get': this.getChangeData,
				'getTeamUser': this.getChangeTeamUser,
				'getAttachmentDetails': this.getChangeAttachmentDetail,
				'getHistoryDetails': this.getChangeHistoryDetail,
				'getAllKBs': this.getChangeAllKBs,
				'getRelation': this.getChangeRelation,
				'getRichTxtData': this.getChangeRichTextData,
				'getMailConversations': this.getChangeMailConversations,
				'convertToKB': this.convertChangeToKB,
				'getUserProfile': this.getChangeUserProfile,
				'saveConversation': this.saveChangeConversation,
				'getApprovalStatus': this.getChangeApprovalStatus,
				'getRefMailConversations': this.getChangeRefMailConversations,
				'getRequesterHistory': this.getChangeRequesterHistory,
				'getProcessChange': this.getChangeChange,
				'getServiceBaseWorkflow': this.getChangeServiceBaseWorkflow,
				'getRequesterImpactService': this.getChangeRequesterImpactService,
				'getImpactServiceClassification': this.getChangeImpactServiceClassification,
				// 'updateMsgReadStatus': this.updateRequestMsgReadStatus,
				'saveRichTextData': this.saveChangeRichTextData,
				'updateApprovalData': this.updateChangeApprovalData,
				'updateReviewData': this.updateChangeReviewData,
				'deleteAttachment': this.deleteChangeAttachment,
				'converProcessToIncident': this.convertChangeToIncident,
				'getProcessIncident': this.getChangeIncident,
				'getFilterOptions': this.getChangeFilterOptions,
				'getTaskList': this.getChangeTask,
				'getSavedFilters':this.getChangeSavedFilters,
				'deleteTask': this.deleteChangeTask,
			}
		}
		this.configuration_list = [
			{
				id: 0,
				handle: "incident_details",
				icon: "fa-circle-info",
				title: this._translateService.instant("UI.k_inci_detail"),
				listener: this.getProcessData
			},
			{
				id: 1,
				handle: "communication_tab",
				icon: "fa-messages",
				title: this._translateService.instant("UI.k_communication"),
				listener: this.getMailConversations
			},
			{
				id: 2,
				handle: "relation",
				icon: "fa-diagram-project",
				title: this._translateService.instant("UI.k_relation"),
				listener: this.getRelations,
				diableSSP: true
			},
			{
				id: 3,
				handle: "analysis_and_solutions",
				icon: "fa-key",
				title: this._translateService.instant("UI.k_analysis_&_submission"),
				listener: this.getProcessData,
				diableSSP: true
			},
			{
				id: 4,
				handle: "planning",
				icon: "fa-key",
				title: this._translateService.instant("UI.k_planning"),
				listener: this.getProcessData,
				diableSSP: true
			},
			{
				id: 5,
				handle: "task",
				icon: "fa-list-check",
				title: this._translateService.instant("UI.k_task"),
				listener: this.getTaskList,
				diableSSP: true
			},
			{
				id: 6,
				handle: "risk",
				icon: "fa-shield-exclamation",
				title: this._translateService.instant("UI.k_risk"),
				listener: this.getRiskList,
				diableSSP: true
			},
			{
				id: 7,
				handle: "sla",
				icon: "fa-timer",
				title: this._translateService.instant("UI.k_sla"),
				listener: this.getSlaInfoData,
				diableSSP: true  
			},
			{
				id: 8,
				handle: "attachment",
				icon: "fa-paperclip",
				title: this._translateService.instant("UI.k_attachment"),
				listener: this.getAttachmentDetails,
			},
			{
				id: 9,
				handle: "knowledge_base",
				icon: "fa-book-open",
				title: this._translateService.instant("UI.k_knowledgebase"),
				listener: this.getKBDetails,
				diableSSP: true  
			},
			{
				id: 10,
				handle: "interaction",
				icon: "fa-diagram-nested",
				title: this._translateService.instant("UI.k_interaction"),
				listener: this.getRequesterHistory,
				diableSSP: true
			},
			{
				id: 11,
				handle: "resent_activity",
				icon: "fa-list-timeline",
				title: this._translateService.instant("UI.k_recent_activities"),
				listener: this.getHistoryDetails,
				diableSSP: true
			},					
		];

		let accessType = localStorage.getItem("accessType") || 0
		if (accessType == 2){
			this.is_ssp_login = true
		}
		let user_permissions = {};
		if (GlobalConstants.dnsPermissions !== undefined) {
			user_permissions = JSON.parse(GlobalConstants.dnsPermissions);
		}
		// this.permissions = user_permissions[this.module];
		this.kb_permissions = user_permissions[InfinityModules.KNOWLEDGEBASE] || {};
		// this.request_permissions = user_permissions[this.module_request];
		// this.change_permissions = user_permissions[InfinityModules.CHANGE];
		// this.problem_permissions = user_permissions[InfinityModules.PROBLEM];
		// this.release_permissions = user_permissions[InfinityModules.RELEASE];
		this.events_permissions = user_permissions[InfinityModules.EVENTS] || {};
		this.asset_permissions = user_permissions[InfinityModules.CMDB] || {};
		this.ticket_permissions = user_permissions[InfinityModules.INCIDENT] || {};
		this.request_permissions = user_permissions[InfinityModules.REQUEST_PROCESS] || {};
		this.change_permissions = user_permissions[InfinityModules.CHANGE] || {};
		this.problem_permissions = user_permissions[InfinityModules.PROBLEM] || {};
		this.release_permissions = user_permissions[InfinityModules.RELEASE] || {};
		const userParams = JSON.parse(localStorage.getItem("userParams"));
		// this.userTZ = userParams?.user_tz;
		if (userParams && userParams["user_tz"]) {
			this.userTZ = userParams["user_tz"];
		}
		if(this.moduleName === 'Incident'){
			this.permissions = this.ticket_permissions || {};
			this.communicationModuleType = "INCIDENT"
			this.configuration_list = this.configuration_list.filter((f) => f.handle !== 'analysis_and_solutions' && f.handle !== 'task' && f.handle !== 'risk' && f.handle !== 'planning');
		}else if(this.moduleName === 'Request'){
			this.permissions = this.request_permissions || {};
			this.communicationModuleType = "REQUEST";
			this.configuration_list = this.configuration_list.filter((f) => f.handle !== 'analysis_and_solutions' && f.handle !== 'task' && f.handle !== 'risk' && f.handle !== 'planning');
			this.module_id = this.request_module_id
			this.module = InfinityModules.REQUEST_PROCESS;
		}else if(this.moduleName === 'Problem'){
			this.permissions = this.problem_permissions || {};
			this.communicationModuleType = "PROBLEM"
			this.configuration_list = this.configuration_list.filter((f) => f.handle !== 'sla' && f.handle !== 'task' && f.handle !== 'risk' && f.handle !== 'planning');
			this.module_id = this.request_module_id
			this.module_id = this.problem_module_id
			this.module = InfinityModules.PROBLEM;
		}else if(this.moduleName === 'Change'){
			this.permissions = this.change_permissions || {};
			this.communicationModuleType = "CHANGE"
			this.configuration_list = this.configuration_list.filter((f) => f.handle !== 'sla' && f.handle !== 'analysis_and_solutions');
			this.module_id = this.change_module_id
			this.module = InfinityModules.CHANGE;
			this.submitActions = ["APPROVE", "COMPLETE", "REVIEW"];
		}else if(this.moduleName === 'Release'){
			this.permissions = this.release_permissions || {};
			this.communicationModuleType = "RELEASE"
			this.configuration_list = this.configuration_list.filter((f) => f.handle !== 'sla' && f.handle !== 'analysis_and_solutions' && f.handle !== 'risk');
			this.module_id = this.release_module_id
			this.module = InfinityModules.RELEASE;
			this.submitActions = ["APPROVE", "COMPLETE"];
		}
		if(!this.permissions?.edit){
			this.disable_status_config = true;
			this.disable_config = true;
			this.service_config["is_editable"] = false;
			this.classification_config["is_editable"] = false;
		}
		this.module_permissions = GlobalConstants.dnsLavModules?.keys_dict;
		try {
			const today = new Date(new Date().toLocaleString("en-US", { timeZone: this.userTZ }));
			this.dateTimeOptions["defaultDate"] = today;
		} catch (e) {
			console.log("Error in selecting current time based on timezone ", e);
		}
		if(this.moduleName !== "Change"){
			if(this.handelType){
				this.selectedItem = this.configuration_list.findIndex((f) => f.handle === this.handelType)
				this.menuClick(this.handelType, this.selectedItem, this.configuration_list[this.selectedItem])
			}
		}
		this.isinfraonAIFeautreFlagActive = this.permissionManagerS?.is_feature_active(this.infraonAIFeautreFlag);
		this.getProcessData();
		// this.getRelations(this.moduleName);
		// this.getAttachmentDetails(this.processID, 'getAttachmentDetails');
		// this.getHistoryDetails(this.processID, 'getHistoryDetails');
		// this.getRichTextData('getRichTxtData');
		// this.getImpactList();
		// this.getRiskList();
		// if (this.module_permissions?.["BASE"]?.["KNOWLEDGE_BASE"]) {
		// 	this.getKBDetails(this.processID, 'getAllKBs');
		// }
		// this.updateNavBar();
		// this.getMailConversations('getMailConversations');
		// this.getMinutes();
		// this.getHours();
		// this.getHistoryDetails(this.processID, 'getHistoryDetails');
		// this.getSlaInfoData();
		// this.getTaskList();
		this._wssService.connect();
		this.risk_columns = [
			{
				label: this._translateService.instant("UI.k_name"),
				key: "name",
				sortable: true,
			},
			{
				label: this._translateService.instant("UI.k_mitigation_plan"),
				key: "mitigation_plan",
				sortable: true,
			},
			{
				label: this._translateService.instant("UI.k_risk_type"),
				key: "risk_type",
				sortable: true,
			},
			{
				label: this._translateService.instant("UI.k_description"),
				key: "description",
				sortable: true,
			},

			{
				label: this._translateService.instant("UI.k_action"),
				key: "action",
				actions: [
					{
						label: this._translateService.instant("UI.k_edit"),
						icon: "fa-pen-to-square",
						key: "edit",
						class: "text-primary",
						listener: this.toggleEditRisk,
					},
					{
						label: this._translateService.instant("UI.k_delete"),
						icon: "fa-trash-can",
						key: "edit",
						class: "text-danger mr-50",
						hide_key: "is_enable",
						listener: this.deleteRisk,
					},
				],
			},
		];

	}

	updateNavBar() {
		// Configure the layout
		this._coreConfigService.config = {
			layout: {
				navbar: {
					hidden: false,
					pageTitle: this._translateService.instant("UI.k_incident"),
					moduleName: "Incidents",
					pageIcon: "ticket",
					// backgroundColor: "",
					breadcrumb: {
						type: "",
						links: [
							{
								name: this._translateService.instant("UI.k_incident"),
								isLink: true,
								link: "/incident_view",
							},
							{
								name: this._translateService.instant("UI.k_view"),
								isLink: false,
								link: "/incident_view/incident-edit",
							},
						],
					},
				},
			},
		};
	}
	togglCcBcc(toggleRef) {
		if (toggleRef === "cc") {
			this.isOpenCC = !this.isOpenCC;
		} else {
			this.isOpenBCC = !this.isOpenBCC;
		}
	}

	getAttachmentDetails = (incident_id, actionType) => {
		this.commonActionMap[this.moduleName][actionType](incident_id)
	};

	formatHistoryData = () => {
		for (const idx in this.historyDataset) {
			this.historyDataset[idx]["timeAgoStr"] = this.timeAgo(this.historyDataset[idx]["creation_time"]);
		}
	};

	getHistoryDetails = (incident_id, actionType) => {
		this.commonActionMap[this.moduleName][actionType](incident_id);
	};

	getKBDetails = (incident_id, actionType) => {
		this.commonActionMap[this.moduleName][actionType](incident_id);
	};
	searchConfig(event): void {
		const searchStr = event.target.value;
		if (searchStr) {
			const filteredConfigs = this.historydup?.filter((config) => {
				return (
					Object.values(config["event_data"]).some((val) => {
						if (typeof val === "object") {
							for (const valKey in val) {
								const jsonString = JSON.stringify(val[valKey]);
								if (jsonString.toLowerCase().includes(searchStr.toLowerCase())) {
									return true;
								}
							}
						}
						return String(val).toLowerCase().includes(searchStr.toLowerCase());
					}) ||
					config?.event_title.toLowerCase().includes(searchStr.toLowerCase()) ||
					config?.owner_name.toLowerCase().includes(searchStr.toLowerCase()) ||
					config?.creation_time.toLowerCase().includes(searchStr.toLowerCase())
				);
			});
			this.historyDataset = filteredConfigs;
		} else {
			this.historyDataset = this.historydup;
		}
	}

	getProcessData = () => {
		this.commonActionMap[this.moduleName]['get']()
	}

	created(event) {
		// tslint:disable-next-line:no-console
	}

	focus($event) {
		// tslint:disable-next-line:no-console
		this.focused = true;
		this.blured = false;
	}

	blur($event) {
		// tslint:disable-next-line:no-console
		this.focused = false;
		this.blured = true;
	}

	downloadAttachment = (path, file) => {
		const file_path = URL + path.split("media")[1];
		const downloadable_file_path = file_path + "/" + file;
		window.open(downloadable_file_path, "_blank");
	};

	toggleInciToKbSidebar = (name): void => {
		if (name === "app-dns-kb-add") {
			this.kbEvent = "add";
			const solutions = [];
			let rootcause = "";
			const worklog = [];
			this.rich_text_list.forEach(function (value, i) {
				const temp = { root_cause: "" };
				if (value["type"] === "Resolution") {
					temp["solution"] = value["without_dom_sanitizer_descr"];
					solutions.push(temp);
				}
				if (value["type"] === "Root Cause") {
					rootcause = value["without_dom_sanitizer_descr"];
					worklog.push(rootcause);
				}
			});
			worklog.forEach(function (value, i) {
				if (solutions.length > i) {
					solutions[i]["root_cause"] = value;
				} else {
					solutions.push({ root_cause: value });
				}
			});
			let kbtype = "solution"
			if (this.process_data?.is_known_error_enable){
				this.kbCurrentItem = "known_error"
				kbtype = "known_error"
				let known_error = {
					error_detail :this.process_data.basic_info.summary,

				}
				if (solutions){
					known_error["root_cause"] = solutions[0]?.root_cause
					known_error["workaround"] = solutions[0]?.solution
				}
				this.kbSolution = {
					title: this.process_data.display_id + " - Known Error",
					kbtype: kbtype,
					keywords: ["Known Error"],
					catalogue_service: this.process_data.basic_info.impact_service,
					known_error: known_error,
				};
			}else {
				this.kbSolution = {
					title: this.process_data.display_id + " - Solution",
					content: this.process_data.basic_info.summary,
					kbtype: "solution",
					keywords: ["Solution"],
					catalogue_service: this.process_data.basic_info.impact_service,
					solutions: solutions,
				};
			}

			if(this.moduleName  === 'Incident'){
				this.kbSolution['keywords'].push('Incident')
			}else if (this.moduleName  === 'Request'){
				this.kbSolution['keywords'].push('Request')
			}else if (this.moduleName  === 'Problem'){
				this.kbSolution['keywords'].push('Problem')
			}else if (this.moduleName  === 'Change'){
				this.kbSolution['keywords'].push('Change')
			}
			this.kbHideProfile = true;
		} else if (name === "app-incident-kb-solution") {
			this.kbSolution = this.process_data_copy?.kb_sln || {};
			this.kbSolutionHideProfile = true;
		}
		this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
	};

	onHideKBProfile = (e) => {
		this._coreSidebarService.getSidebarRegistry("app-dns-kb-add").toggleOpen();
		this.kbHideProfile = false;
		this.getProcessData();
	};

	onHideKBSolution = (e) => {
		this.kbSolutionHideProfile = false;
		// this._coreSidebarService.getSidebarRegistry("app-incident-kb-solution").toggleOpen();
	};
	attachmentSidebar = () => {
		let sidebarName;
		if(this.moduleName === 'Incident'){
			sidebarName = 'app-dns-attachment';
			this._coreSidebarService.getSidebarRegistry(sidebarName).toggleOpen();
		}else if(this.moduleName === 'Request'){
			sidebarName = 'app-request-attachment';
			this._coreSidebarService.getSidebarRegistry(sidebarName).toggleOpen();
		}else if(this.moduleName === 'Problem'){
			sidebarName = 'app-problem-attachment';
			this._coreSidebarService.getSidebarRegistry(sidebarName).toggleOpen();
		}else if(this.moduleName === 'Change'){
			sidebarName = 'app-change-attachment';
			this._coreSidebarService.getSidebarRegistry(sidebarName).toggleOpen();
		}else if(this.moduleName === 'Release'){
			sidebarName = 'app-release-attachment';
			this._coreSidebarService.getSidebarRegistry(sidebarName).toggleOpen();
		}
		this.isHideAddAttachment = false;
	};

	getKBID = (kbData) => {
		let kbID = kbData?.kb_id;
		if(this.moduleName == "Incident" || this.moduleName == "Problem"){
			let kbPaylod = {}
			kbPaylod['files'] = []
			if (this.kbSolution?.files){
				for (let filekey in this.kbSolution?.files) {
					let fileValue = this.kbSolution?.files?.[filekey];
					fileValue["categoryId"] = "1"
					fileValue["category_id"] = "1"
					delete fileValue?.category;
					delete fileValue?.category_name;
					kbPaylod['files'].push(fileValue)
				}
				kbPaylod['kbID'] = kbData?.kb_id
				kbPaylod['kb_title'] = kbData?.kb_title
				kbPaylod['kb_type'] = kbData?.kb_type
				kbPaylod['displayId'] = kbData?.display_id
				this._kbService.addAttachments(kbPaylod).subscribe(
					(response) => {
						if (response["status"] == "success") {
							this._toastr.success(
								this._translateService.instant("Message.msg_file_upload_success"),
								this._translateService.instant("Message.msg_success"),
								{
									toastClass: "toast ngx-toastr",
									closeButton: true,
									positionClass: "toast-top-right",
								}
							);
						} else if (response["status"] == "error") {
							if (response["msg"] == "invalid_file_name") {
								this._toastr.error(
									this._translateService.instant("Error.err_file_name_too_long"),
									this._translateService.instant("Error.err_fail"),
									{
										toastClass: "toast ngx-toastr",
										closeButton: true,
										positionClass: "toast-top-right",
									}
								);
							} else {
								this._toastr.error(
									this._translateService.instant("Error.err_file_upload_failed"),
									this._translateService.instant("Error.err_fail"),
									{
										toastClass: "toast ngx-toastr",
										closeButton: true,
										positionClass: "toast-top-right",
									}
								);
							}
						}
					},
					(error) => {
						// todo
					}
				);
			}
		}
		
		this.commonActionMap[this.moduleName]['convertToKB'](kbID)
	};


	onhideInlineEdit = (e) => {
		if(this.moduleName === 'Incident'){
			this.hideIncidentInlineEdit = false;
		} else if(this.moduleName === 'Request'){
			this.hideRequestInlineEdit = false;
		}else if(this.moduleName === 'Problem'){
			this.hideProblemInlineEdit = false;
		}else if(this.moduleName === 'Change'){
			this.hideChangeInlineEdit = false;
		}else if(this.moduleName === 'Release'){
			this.hideReleaseInlineEdit = false;
		}
		// this._incidentViewService.setEditIncidentSidebar(false);
		this.getProcessData();
		this.getHistoryDetails(this.processID, 'getHistoryDetails');
	};
	toggleInLineEdit = (name: string) => {
		this.process_extra_info["selected_assignee_info"] = this.extra_info["selected_assignee_info"];
		this.process_extra_info["custom_field_config"] = this.custom_field_config;
		this.process_extra_info["group_type"] = this.extra_info["group_type"];
		this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
		if(this.moduleName === 'Incident'){
			this.hideIncidentInlineEdit = true;
		} else if(this.moduleName === 'Request'){
			this.hideRequestInlineEdit = true;
		}else if(this.moduleName === 'Problem'){
			this.hideProblemInlineEdit = true;
		}else if(this.moduleName === 'Change'){
			this.hideChangeInlineEdit = true;
			this.change_process_data = _deepCopyJson(this.process_data);
		}else if(this.moduleName === 'Release'){
			this.hideReleaseInlineEdit = true;
		}
	};

	openModalAD(AssignmentComp) {
		Object.assign(this.currentAssignmentInfo, this.process_data?.current_assignment_info);
		Object.assign(this.current_extra_info, this.extra_info);
		this._modalService.open(AssignmentComp, {
			centered: true,
			animation: false,
			size: "md",
		});
	}

	closeCurrAssignPopup() {
		this.team_id = this.process_data?.current_assignment_info?.group;
		this._modalService.dismissAll();
		this.current_extra_info = {};
		this.currentAssignmentInfo = {};
		Object.assign(this.currentAssignmentInfo, this.process_data.current_assignment_info);
		Object.assign(this.current_extra_info, this.extra_info);
		this.dropdown["expertise"] = this.process_data_copy["expertise_list"];
		this.dropdown["level"] = this.process_data_copy["level_list"];
		this.dropdown["assignee"] = this.process_data_copy["assignee_list"];
	}

	// data-form reference, payload-incident data
	saveProcessData(data, from_close_incident = false, from_assign = false) {
		if (from_assign) {
			this.asgnDisabled = true;
		}
		if (data.form.valid === true) {
			this.process_data["current_assignment_info"] = this.currentAssignmentInfo;
			this.get_custom_data(
				this.process_extra_info?.custom_field_config?.custom_fields,
				this.process_data?.custom_field_data
			);
			if (this.process_data.actual_start_time instanceof Array) {
				this.process_data.actual_start_time = this.process_data.actual_start_time[0];
			}
			if (this.process_data.agreed_closure_date instanceof Array) {
				this.process_data.agreed_closure_date = this.process_data.agreed_closure_date[0];
			}
			if (this.process_data.actual_closure_date instanceof Array) {
				this.process_data.actual_closure_date = this.process_data.actual_closure_date[0];
			}
			this.updateIncident(
				this.process_data,
				(from_close_incident = from_close_incident),
				(from_assign = from_assign)
			);
		}
	}

	updateFieldName(evt, id, dropdown_name, set_field, ref_field) {
		const selected = this.dropdown[dropdown_name].filter((e) => e[id] === evt);
		set_field = selected[0][ref_field];
	}

	updateIncident(payload, from_close_incident = false, from_assign = false) {
		this.commonActionMap[this.moduleName]['edit'](payload, from_close_incident, from_assign)
	}

	groupAssignChange(dropdown, team_id) {
		if (team_id === "self_assign") {
			if (this.dropdown?.current_user_group?.length < 1) {
				this.currentAssignmentInfo["level"] = null;
				this.currentAssignmentInfo["expertise"] = null;
				this.currentAssignmentInfo["level_id"] = null;
				this.currentAssignmentInfo["expertise_id"] = null;
				this.currentAssignmentInfo["group"] = null;
				this.currentAssignmentInfo["group_name"] = null;
				this.currentAssignmentInfo["group_type"] = null;
				this.currentAssignmentInfo["assignee"] = this.currentUser?.profile?.profile_id;
				this.currentAssignmentInfo["assignee_profile"] = this.currentUser?.profile;
			}
		} else {
			this.currentAssignmentInfo["group"] = team_id;
			this.groupChange(dropdown);
		}
	}

	clearExpertiselevelAssignee(assignment) {
		assignment["level"] = null;
		assignment["expertise"] = null;
		assignment["level_id"] = null;
		assignment["expertise_id"] = null;
		assignment["assignee"] = null;
		assignment["assignee_profile"] = null;
	}
	clearlevelAssignee(assignment) {
		assignment["level"] = undefined;
		assignment["level_id"] = undefined;
		assignment["assignee"] = undefined;
		assignment["assignee_profile"] = undefined;
	}

	groupChange(options) {
		if (this.currentAssignmentInfo?.group) {
			const selected_group = options?.filter((e) => e.team_id === this.currentAssignmentInfo["group"]);
			this.commonActionMap[this.moduleName]['getTeamUser'](selected_group[0]["team_id"])
			// this._incidentViewService
			// 	.getTeamUserMap({ team_id: selected_group[0]["team_id"] })
			// 	.subscribe((response) => {
			// 		this.dropdown.team_user_profile_map = response;
			// 	});
			this.current_extra_info["group_type"] = selected_group[0]["group_type"];
			this.currentAssignmentInfo["group_type"] = selected_group[0]["group_type"];
			this.currentAssignmentInfo["group_name"] = selected_group[0]["name"];
			if (selected_group[0]["group_type"] === "Approval" ||
			selected_group[0]["group_type"] === "Servicedesk" ||  
			selected_group[0]["group_type"] === "Expert") {
				this.dropdown["assignee"] = selected_group[0]["staffs"];
				this.dropdown["expertise"] = [];
				this.dropdown["level"] = [];
			} else {
				this.dropdown["expertise"] = selected_group[0]["level_staff"];
				this.dropdown["assignee"] = [];
			}
			this.clearExpertiselevelAssignee(this.currentAssignmentInfo);
			this.current_extra_info["selected_assignee_info"] = {
				profile_image: "",
				full_name: "",
				email: "",
				profile: { avatar_color: "" },
			};
			this.dropdown["level"] = [];
			if (this.selfAssignSel && this.dropdown?.current_user_group?.length > 0) {
				if (
					selected_group[0]["group_type"] === "Approval" ||
					selected_group[0]["group_type"] === "Servicedesk" ||
					selected_group[0]["group_type"] === "Expert"
				) {
					this.currentAssignmentInfo["assignee"] = this.currentUserProfileId;
					this.currentAssignmentInfo["assignee_profile"] = this.currentUser?.profile;
					this.currentAssignmentInfo["level"] = null;
					this.currentAssignmentInfo["expertise"] = null;
					this.currentAssignmentInfo["level_id"] = null;
					this.currentAssignmentInfo["expertise_id"] = null;
					this.dropdown["current_user_expertise"] = [];
				} else {
					if (selected_group[0]["level_staff"].length > 1) {
						this.dropdown["current_user_expertise"] = selected_group[0]["level_staff"];
						this.currentAssignmentInfo["expertise"] = null;
						this.currentAssignmentInfo["level"] = null;
						this.currentAssignmentInfo["expertise_id"] = null;
						this.currentAssignmentInfo["level_id"] = null;
					} else {
						this.currentAssignmentInfo["expertise"] = selected_group[0]["level_staff"][0]["expertise"];
						this.currentAssignmentInfo["level"] =
							selected_group[0]["level_staff"][0]["expertGroupLevel"][0]["level"];
						this.currentAssignmentInfo["expertise_id"] =
							selected_group[0]["level_staff"][0]["expertise_id"];
						this.currentAssignmentInfo["level_id"] =
							selected_group[0]["level_staff"][0]["expertGroupLevel"][0]["level_id"];
					}
					this.currentAssignmentInfo["assignee"] = this.currentUserProfileId;
					this.currentAssignmentInfo["assignee_profile"] = this.currentUser?.profile;
				}
				this.assigneeChange();
			} else {
				this.clearExpertiselevelAssignee(this.currentAssignmentInfo);
			}
		} else {
			this.currentAssignmentInfo["group_name"] = "";
			this.clearExpertiselevelAssignee(this.currentAssignmentInfo);
		}
	}

	// evt-expertise
	expertiseChange(dropdown) {
		const selected_expertise = dropdown?.filter((e) => e.expertise === this.currentAssignmentInfo["expertise"]);
		if (selected_expertise?.length > 0) {
			this.currentAssignmentInfo["expertise_id"] = selected_expertise[0]["expertise_id"];
			if (this.selfAssignSel && this.dropdown?.current_user_group?.length > 0) {
				this.currentAssignmentInfo["level"] = selected_expertise[0]["expertGroupLevel"][0]["level"];
				this.currentAssignmentInfo["level_id"] = selected_expertise[0]["expertGroupLevel"][0]["level_id"];
			} else {
				this.dropdown["assignee"] = [];
				this.current_extra_info["selected_assignee_info"] = {
					profile_image: "",
					full_name: "",
					email: "",
					profile: { avatar_color: "" },
				};
				this.currentAssignmentInfo["assignee"] = null;
				this.currentAssignmentInfo["assignee_profile"] = null;
				this.currentAssignmentInfo["level"] = null;
				this.currentAssignmentInfo["level_id"] = null;
				this.dropdown["level"] = selected_expertise[0]["expertGroupLevel"];
				if (this.prev_current_assignment_info?.group !== undefined) {
					if (
						this.currentAssignmentInfo?.expertise_id !== this.prev_current_assignment_info?.expertise_id &&
						this.currentAssignmentInfo?.group === this.prev_current_assignment_info.group &&
						this.prev_current_assignment_info?.level_id !== undefined
					) {
						this.currentAssignmentInfo["level"] = this.prev_current_assignment_info?.level;
						this.currentAssignmentInfo["level_id"] = this.prev_current_assignment_info?.level_id;
						this.levelChange();
					}
				}
			}
		} else {
			this.clearExpertiselevelAssignee(this.currentAssignmentInfo);
		}
	}

	//evt-level
	levelChange() {
		const selected_level = this.dropdown?.level?.filter((e) => e.level === this.currentAssignmentInfo["level"]);
		if (selected_level.length > 0) {
			this.currentAssignmentInfo["level_id"] = selected_level[0]["level_id"];
			this.dropdown["assignee"] = selected_level[0]["users"];
			this.current_extra_info["selected_assignee_info"] = {
				profile_image: "",
				full_name: "",
				email: "",
				profile: { avatar_color: "" },
			};
			this.currentAssignmentInfo["assignee"] = null;
			this.currentAssignmentInfo["assignee_profile"] = null;
		} else {
			this.clearlevelAssignee(this.currentAssignmentInfo);
		}
	}

	assigneeChange() {
		this.commonActionMap[this.moduleName]['getUserProfile']();
	}

	openMailComp() {}

	linkifyEmail = (emails) => {
		let result = "";
		if (emails) {
			const emailsArray = emails?.split(",").join(";").split(";");
			for (const email of emailsArray) {
				result += `<a href="mailto:${email}">${email};</a>`;
			}
		}
		return result;
	};
	composeMail = function (index, dataSet, event, view_more, append_subject, elem, replyall = false) {
		// var emailContent: any;
		this.showQuotedTxt = false;
		this.removeQuotedTxt = false;
		this.emailContent = {};
		this.filesList = [];
		this.emailContent = cloneDeep(dataSet);
		this.emailContent["quoted_text"] = `<hr><br><br>
			                                     ${
														this.emailContent.from_address
															? "<b>From:</b> " + this.emailContent.from_address + "<br>"
															: ""
													}
			                                    ${
													this.emailContent.creation_time
														? "<b>Sent:</b> " + this.emailContent.creation_time + "<br>"
														: ""
												}
			                                    <b>To:</b> ${this.linkifyEmail(dataSet.to_address)}<br>
			                                    ${
													dataSet.cc_address
														? "<b>Cc:</b> " + this.linkifyEmail(dataSet.cc_address) + "<br>"
														: ""
												}
			                                    <b>Subject:</b> ${this.emailContent?.subject} <br><br>
			                                    <span class="" [innerHTML]=${this.emailContent?.content}></span>`;
		// <span class="">${this.emailContent?.tx_content ? this.emailContent?.txt_content : this.emailContent?.content}</span>`;

		// if (this.emailContent.type != 'Send'){
		// 	//email received --
		// 	var to_address_str = dataSet.to_address + ',' + dataSet.from_address;
		// 	// this.emailContent['from_address'] = 'System';
		// 	this.emailContent['to_address'] = to_address_str;
		// }
		// else{
		// 	// this.emailContent['from_address'] = dataSet.from_address;
		// 	this.emailContent['to_address'] = dataSet.to_address;
		// }
		if (dataSet?.type === "Send" && !dataSet?.from_address && dataSet?.user_id === "-1") {
			this.emailContent["to_address"] = dataSet?.to_address?.split(";").join(",").split(",");
		} else if (this.emailContent?.from_address) {
			this.emailContent["to_address"] = dataSet?.from_address?.split(";").join(",").split(",");
		} else {
			this.emailContent["to_address"] = dataSet?.to_address?.split(";").join(",").split(",");
		}
		// this.emailContent["to_address"] = dataSet.to_address.split(",");
		const to_addr_li = [];
		for (const key of this.dropdown?.to_address) {
			to_addr_li.push(key["email"]);
		}
		const cc_addr_li = [];
		for (const key of this.dropdown?.cc_address) {
			cc_addr_li.push(key["email"]);
		}
		for (const key of this.emailContent?.to_address) {
			if (to_addr_li.indexOf(key) === -1) {
				this.dropdown["to_address"].push({ full_name: key, email: key });
				to_addr_li.push(key);
			}
			if (cc_addr_li.indexOf(key) === -1) {
				this.dropdown["cc_address"].push({ full_name: key, email: key });
				cc_addr_li.push(key);
			}
		}
		if (dataSet?.cc_address) {
			this.emailContent["cc_address"] = dataSet?.cc_address?.split(";").join(",").split(",");
			for (const key of this.emailContent?.cc_address) {
				if (cc_addr_li.indexOf(key) === -1) {
					this.dropdown["cc_address"].push({ full_name: key, email: key });
					cc_addr_li.push(key);
				}
				if (to_addr_li.indexOf(key) === -1) {
					this.dropdown["to_address"].push({ full_name: key, email: key });
					to_addr_li.push(key);
				}
			}
			if (replyall) {
				this.togglCcBcc("cc");
			}
		} else {
			this.emailContent["cc_address"] = undefined;
		}
		// if (dataSet.cc_address) {
		// 	this.emailContent["bcc_address"] = dataSet.bcc_address;
		// }
		//  else {
		// 	this.emailContent["bcc_address"] = undefined;
		// }

		if (append_subject === "Fw:") {
			this.emailContent["to_address"] = undefined;
			this.emailContent["cc_address"] = undefined;
			// this.emailContent["bcc_address"] = undefined;
			// this.emailContent['from_address'] = 'System';
			this.showQuotedText();
		}
		if (append_subject === undefined) {
			append_subject = "Re:";
		}
		if (
			this.emailContent.subject.indexOf(this.dropdown?.incident_prefix_list[0]) === -1 &&
			this.process_data.display_id
		) {
			this.emailContent["subject"] = "[" + this.process_data.display_id + "]" + this.emailContent.subject;
		}
		if (this.emailContent.subject.indexOf(append_subject) === -1) {
			this.emailContent["subject"] = append_subject + this.emailContent.subject;
		}
		this.isEmailCompOpen = true;
		this.emailContent["attached_files"] = [];
		this.emailContent["signature"] = "";
		this.emailContent.imap_config_id = null;
		this.selectedSignatureValue = null;
		if (this.signatureInputData?.length === 1) {
			this.signatureChange(this.signatureInputData[0]);
		}
		if (!this.is_from_disabled){
			if (this.imapInputData?.length > 0){
				let imap_config_id = this.imapInputData[0]?.value;
				this.imapChange({ value: imap_config_id, text: this.imapInputData[0]?.value });
			}
		}
		if (this.process_data?.imap_config_id){
			let imap_config_id = this.process_data?.imap_config_id
			let text = "";
			let selected_row = this.imap_list?.filter(function(element) {
				return element.config_id === imap_config_id;
			  });
			if (selected_row?.length > 0){
				text = selected_row[0]?.username;
			}
			this.imapChange({ value: imap_config_id, text: text });
		}
		setTimeout(() => {
			elem.scrollIntoView();
		}, 0);
	};

	/**
	 * Close Compose
	 */
	closeMailComp() {
		this.isEmailCompOpen = false;
		setTimeout(() => {
			this.scrolltop = this.scrollMe?.nativeElement.scrollHeight;
		}, 0);
	}

	getEmailContent(evt) {
		this.emailContent.reply_mail = evt.html;
	}

	getMailConversations = (actionType) => {
		this.commonActionMap[this.moduleName][actionType]()
	}

	fileUpload = function (evt) {
		const files = evt.target.files;
		const new_file_len = evt.target.files.length;
		const added_file_len = this.filesList.length;
		for (let i = 0; i < new_file_len; i++) {
			const file = files[i];
			const pos = added_file_len + i;
			const reader = new FileReader();
			reader.onload = (function (filesList, file, pos) {
				return function (e) {
					const data = e.target.result;
					filesList[pos] = {
						file_name: file.name,
						file_type: file.type,
						file_size: file.size,
						file_data: data,
						file_date: new Date(),
						// 'file_date': this.datepipe.transform(new Date(),'MMM dd,yyyy hh:mm a')
					};
				};
			})(this.filesList, file, pos);
			reader.readAsDataURL(file);
		}
	};
	// removing the file from POP UI.
	removeAttachedFile = function (index, filesList) {
		filesList.splice(index, 1);
	};
	// Function to save the reply
	saveSentEmail = function (mailReplyForm, data, files) {
		if (mailReplyForm.form.valid) {
			data["new_reply_mail"] = data["reply_mail"];
			data["reply_mail_modified"] = 0;
			if (this.emailContent["reply_mail"] === undefined) {
				data["reply_mail_modified"] = 0;
			}
			data["newly_added_files"] = files;
			if (this.showQuotedTxt === false && this.removeQuotedTxt === false) {
				if (this.emailContent["reply_mail"]) {
					this.emailContent["reply_mail"] =
						this.emailContent["reply_mail"] + this.emailContent["quoted_text"];
				} else {
					this.emailContent["reply_mail"] = this.emailContent["quoted_text"];
				}
			}
			// Forming the message parameters
			const messageParam = {
				ref_id: this.processID,
				display_id: this.process_data?.display_id,
				imap_config_id: this.process_data?.imap_config_id,
				email_content: data,
			};
			this.commonActionMap[this.moduleName]['saveConversation'](messageParam, files)
			// this._incidentViewService.saveConversation(messageParam).subscribe((response) => {
			// 	if (response.status === "success") {
			// 		this.isOpenCC = false;
			// 		this.isOpenBCC = false;
			// 		this._toastr.success(
			// 			this._translateService.instant(response["message"]),
			// 			this._translateService.instant("Message.msg_save_success"),
			// 			{
			// 				toastClass: "toast ngx-toastr",
			// 				closeButton: true,
			// 				positionClass: "toast-top-right",
			// 			}
			// 		);
			// 		this.page = 1;
			// 		this.commDataTotalCount = 0;
			// 		this.getMailConversations('getMailConversations');
			// 		this.showReplyBox = true;
			// 		this.isEmailCompOpen = false;
			// 		setTimeout(() => {
			// 			this.scrolltop = this.scrollMe?.nativeElement.scrollHeight + 100;
			// 		}, 0);
			// 		if (files) {
			// 			this.loadAttachments("");
			// 		}
			// 	} else if (response["status"] === "error") {
			// 		this._toastr.error(this._translateService.instant(response["message"]), "Error", {
			// 			toastClass: "toast ngx-toastr",
			// 			closeButton: true,
			// 			positionClass: "toast-top-right",
			// 		});
			// 	}
			// });
		}
	};

	showQuotedText() {
		this.showQuotedTxt = true;
		if (this.emailContent["reply_mail"]) {
			this.emailContent["reply_mail"] = this.emailContent["reply_mail"] + this.emailContent["quoted_text"];
		} else {
			this.emailContent["reply_mail"] = this.emailContent["quoted_text"];
		}
	}

	removeQuotedText() {
		this.removeQuotedTxt = true;
		this.emailContent["attached_files"] = [];
	}

	getHours = () => {
		for (let i = 0; i < 24; i++) {
			this.hourList.push(i);
		}
	};

	getMinutes = () => {
		for (let i = 0; i < 60; i++) {
			this.minList.push(i);
		}
	};

	getEnrichTextData = (event) => {
		const tmpHTML = event.html.replace("img", this.imgHeightWidthStr);
		this.erichTextData = tmpHTML;
	};

	getTroubleReasonData = (event) => {
		const tmpHTML = event.html.replace("img", this.imgHeightWidthStr);
		this.trouble_reason = tmpHTML;
	};

	getRecoveryActionData = (event) => {
		const tmpHTML = event.html.replace("img", this.imgHeightWidthStr);
		this.recovery_action = tmpHTML;
	};

	enableEditor = (id, elem) => {
		try {
			const today = new Date(new Date().toLocaleString("en-US", { timeZone: this.userTZ }));
			this.dateTimeOptions["defaultDate"] = today;
		} catch (e) {
			console.log("Error in selecting current time based on timezone ", e);
		}
		if (id === "Closure Note") {
			this.editorId = id;
			this.dropdown["filtered_status"] = this.dropdown?.status?.filter(
				(e) => e["state_id"] === this.stateKeyIdMap["Close"]
			);
			if (this.process_data?.close_info?.closure_note) {
				this.erichTextData = this.process_data?.close_info?.closure_note;
			} else {
				this.process_data.basic_info.state["id"] = this.dropdown?.filtered_status?.[0]["state_id"];
				this.process_data.basic_info.status["id"] = this.dropdown?.filtered_status?.[0]["id"];
			}
		}
		if (id === "Resolution") {
			this.editorId = id;
			this.dropdown["filtered_status"] = this.dropdown?.status?.filter(
				(e) => e["state_id"] === this.stateKeyIdMap["Resolved"]
			);
			this.process_data.basic_info.state["id"] = this.dropdown?.filtered_status?.[0]["state_id"];
			this.process_data.basic_info.status["id"] = this.dropdown?.filtered_status?.[0]["id"];
		}
		if (id === "Approval") {
			this.commonActionMap[this.moduleName]['getApprovalStatus'](id)

		} else {
			this.editorId = id;
			this.showEditor = true;
		}
		this.disableCommentSubmit = false;
		setTimeout(() => {
			elem.scrollIntoView();
			// this.scrolltop = this.scrollMe?.nativeElement.scrollHeight+1300;
		}, 0);
	};


	getRichTextData = (actionType) => {
		this.commonActionMap[this.moduleName][actionType]();
	};

	reloadCommunicationData() {
		this.getRichTextData('getRichTxtData');
		this.page = 1;
		this.commDataTotalCount = 0;
		setTimeout(() => {
			this.getMailConversations('getMailConversations');
		}, 500);
		setTimeout(() => {
			this.scrolltop = this.scrollMe?.nativeElement.scrollHeight;
		}, 0);
		this.erichTextData = "";
		this.trouble_reason = "";
		this.recovery_action = "";
		this.showEditor = false;
		this.isPrivate = "false";
		this.workDuration = {
			day: undefined,
			hr: undefined,
			min: undefined,
		};
		this.showEditor = false;
		this.showReplyBox = true;
		setTimeout(() => {
			this.scrolltop = this.scrollMe?.nativeElement.scrollHeight + 100;
		}, 0);
	}

	closeEditor = (opt, data) => {
		this.disableCommentSubmit = true;
		if (data.form.valid) {
			let section = "";
			if (opt === "Closure Note") {
				section = "closure";
				const cf_data = this.process_extra_info?.custom_field_config?.custom_fields?.filter(
					(d) => d?.section === section
				);
				if (!this.process_data?.close_info) {
					this.process_data["close_info"] = {};
				}
				this.process_data.close_info["closure_note"] = this.erichTextData;
				this.process_data.close_info["custom_field_data"] = this.get_custom_data(
					cf_data,
					this.process_data?.custom_field_data
				);
				this.reloadCommunication = true;
				this.showEditor = false;
				this.saveProcessData(data, true);
				return true;
			}
			let final_content = "";
			if (opt === "Add Notes") {
				final_content = this.erichTextData;
			}
			if (opt === "Symptoms") {
				final_content = this.erichTextData;
			}
			if (opt === "Analyse") {
				final_content = this.erichTextData;
			}
			if (opt === "Worklog") {
				final_content = this.erichTextData;
			}
			if (opt === "Resolution") {
				section = "resolution";
				const resolution = this.erichTextData;
				if (this.process_data?.inci_resolution_time instanceof Array) {
					this.process_data.inci_resolution_time = this.process_data.inci_resolution_time[0];
				}
				if (this.process_data?.inci_resolution_time && resolution) {
					final_content = resolution;
					this.commonActionMap[this.moduleName]['updateApprovalData'](this.processID, this.process_data, 'resolution')
					// this._incidentViewService
					// 	.editIncident(this.processID, this.process_data)
					// 	.pipe(
					// 		map((response: any) => {
					// 			if (response) {
					// 				this._modalService.dismissAll();
					// 				this.selfAssignSel = false;
					// 				this.getProcessData();
					// 			} else {
					// 				this._toastr.error(
					// 					this._translateService.instant(response["message"]),
					// 					this._translateService.instant("Error.err_save_failed"),
					// 					{
					// 						toastClass: "toast ngx-toastr",
					// 						closeButton: true,
					// 						positionClass: "toast-top-right",
					// 					}
					// 				);
					// 			}
					// 			// Success
					// 		}),
					// 		catchError((error) => {
					// 			return throwError(error.statusText);
					// 		})
					// 	)
					// 	.subscribe();
				}
			}
			if (opt === "Root Cause") {
				final_content = this.erichTextData;
				// final_content = `Root Cause: ` + root_cause + `<br>Trouble Reason: ` + this.trouble_reason + `<br>Recovery Action: ` + this.recovery_action
			}
			// handling approval
			if (opt === "Approval") {
				let approval_data = {
					approval_comment: this.erichTextData,
					approval_state: this.is_accept_button ? 1 : 2,
					is_rollback: this.is_rollback,
				};
				this.disableCommentSubmit = true;
				this.process_data["approval_data"] = approval_data;
				this.commonActionMap[this.moduleName]['updateApprovalData'](this.processID, this.process_data, 'Approval')
				this.disableCommentSubmit = false;
				return;
			}

			let isPrivate = false;
			if (this.isPrivate === "true") {
				isPrivate = true;
			} else {
				isPrivate = false;
			}
			const cf_data = this.process_extra_info?.custom_field_config?.custom_fields?.filter(
				(d) => d?.section === section
			);
			this.get_custom_data(cf_data, this.process_data?.custom_field_data);
			const payload = {
				ref_id: this.processID,
				display_id: this.process_data.display_id,
				rich_text_content: {
					description: final_content,
					type: opt,
					is_private: isPrivate,
					work_duration: this.workDuration,
					resolution_date: this.process_data?.inci_resolution_time,
				},
				custom_fields: cf_data,
				custom_field_data: this.process_data?.custom_field_data,
			};
			this.commonActionMap[this.moduleName]['saveRichTextData'](payload)

		} else {
			this.disableCommentSubmit = false;
		}
	};


	addRelations = (relationModule) => {
		this.relationModule = relationModule;
		this._coreSidebarService.getSidebarRegistry("app-associate-incident").toggleOpen();
		this.showAddRelation = true;
	};
	onHideAssociateRelations = ($event) => {
		this._coreSidebarService.getSidebarRegistry("app-associate-incident").close();
		this.showAddRelation = false;
	};
	getRelations = (relId) => {
		this.commonActionMap[this.moduleName]['getRelation'](relId)
	};

	listView() {
		this.gridViewRef = false;
	}

	gridView() {
		this.gridViewRef = true;
	}

	hideAddAttachment = (evt) => {
		this.isHideAddAttachment = evt;
	};

	loadAttachments = (evt) => {
		this.getAttachmentDetails(this.processID, 'getAttachmentDetails');
		this.getHistoryDetails(this.processID, 'getHistoryDetails');
	};

	selectAttachmentCategory = (opt) => {
		const opt_name = opt.name;
		const opt_id = opt.id;
		this.selected_attachment_id = opt.id;
		this.selected_attachment_name = opt.name;
		this.filteredAttachmentByCategory = {};
		this.filteredAttachmentByCategory[opt_name] = this.allAttachments[opt_name] || [];
		if (opt_id === "all") {
			this.filteredAttachmentByCategory = this.allAttachments || {};
		}
	};

	deleteAttachment = (payload) => {
		if(this.moduleName === 'Incident'){
			payload["incident_id"] = this.processID;
		} else if(this.moduleName === 'Request'){
			payload["request_id"] = this.processID;
		}else if(this.moduleName === 'Problem'){
			payload["problem_id"] = this.processID;
		}else if(this.moduleName === 'Change'){
			payload["change_id"] = this.processID;
		}else if(this.moduleName === 'Release'){
			payload["release_id"] = this.processID;
		}
		Swal.fire({
			title: this._translateService.instant("Message.msg_delete_title"),
			text: this._translateService.instant("Message.msg_delete_text"),
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: this._translateService.instant("Message.msg_delete_confirm"),
			customClass: {
				confirmButton: "btn btn-primary",
				cancelButton: "btn btn-danger ml-1",
			},
		}).then((result) => {
			if (result.isConfirmed) {
				this.commonActionMap[this.moduleName]['deleteAttachment'](payload.attachment_id, payload)

			}
		});
	};

	ngOnDestroy(): void {
		this._modalService.dismissAll();
		Swal.close();
	}

	stringToColor(string) {
		if (string && string !== undefined) {
			let hash = 0;
			let i;

			/* eslint-disable no-bitwise */
			for (i = 0; i < string.length; i += 1) {
				hash = string.charCodeAt(i) + ((hash << 5) - hash);
			}

			let colour = "#";

			for (i = 0; i < 3; i += 1) {
				const value = (hash >> (i * 8)) & 0xff;
				colour += `00${value.toString(16)}`.substr(-2);
			}
			/* eslint-enable no-bitwise */

			return colour;
		} else {
			return "#2d2d2d";
		}
	}

	getFormattedDate(date, prefomattedDate, hideYear = false) {
		const day = date.getDate();
		const MONTH_NAMES = [
			"January",
			"February",
			"March",
			"April",
			"May",
			"June",
			"July",
			"August",
			"September",
			"October",
			"November",
			"December",
		];
		const month = MONTH_NAMES[date.getMonth()];
		const year = date.getFullYear();
		const hours = date.getHours();
		let minutes = date.getMinutes();

		if (minutes < 10) {
			// Adding leading zero to minutes
			minutes = `0${minutes}`;
		}

		if (prefomattedDate) {
			// Today at 10:20
			// Yesterday at 10:20
			return `${prefomattedDate} at ${hours}:${minutes}`;
		}

		if (hideYear) {
			// 10. January at 10:20
			return `${day} ${month} at ${hours}:${minutes}`;
		}

		// 10. January 2017. at 10:20
		return `${day} ${month} ${year} at ${hours}:${minutes}`;
	}

	timeAgo(dateParam) {
		if (!dateParam) {
			return null;
		}
		const date = typeof dateParam === "object" ? dateParam : new Date(dateParam);
		const DAY_IN_MS = 86400000; // 24 * 60 * 60 * 1000
		let today: any;
		try {
			today = new Date(new Date().toLocaleString("en-US", { timeZone: this.userTZ }));
		} catch (e) {
			console.log("Error in selecting current time based on timezone ", e);
		}
		const yesterday = new Date(today - DAY_IN_MS);
		const seconds = Math.round((today - date) / 1000);
		const minutes = Math.round(seconds / 60);
		const isToday = today.toDateString() === date.toDateString();
		const isYesterday = yesterday.toDateString() === date.toDateString();
		const isThisYear = today.getFullYear() === date.getFullYear();

		if (seconds < 5) {
			this.status = "2";
			return "now";
		} else if (seconds < 60) {
			this.status = "2";
			return `${seconds} seconds ago`;
		} else if (seconds < 90) {
			this.status = "2";
			return "about a minute ago";
		} else if (minutes < 60) {
			this.status = "4";
			return `${minutes} minutes ago`;
		} else if (isToday) {
			this.status = "4";
			return this.getFormattedDate(date, "Today"); // Today at 10:20
		} else if (isYesterday) {
			this.status = "3";
			return this.getFormattedDate(date, "Yesterday"); // Yesterday at 10:20
		} else if (isThisYear) {
			this.status = "3";
			return this.getFormattedDate(date, "", true); // 10. January at 10:20
		}
		this.status = "3";
		return this.getFormattedDate(date, ""); // 10. January 2017. at 10:20
	}

	onScrollDown() {
		// this.page = 1;
		this.finished = false;
	}

	onScrollUp = () => {
		if (this.commDataCount <= this.commDataTotalCount) {
			if (this.showEditor === false) {
				this.firstKey += Number(this.selectedOption);
				this.lastKey += Number(this.selectedOption);
				this.loadNextComments();
			}
		}
	};
	loadNextComments = () => {
		this.selectedOption = this.selectedOption + 10;
		if (this.selectedOption > this.commDataTotalCount) {
			this.selectedOption = this.commDataTotalCount;
		}
		this.getMailConversations('getMailConversations');
	};

	// loadNextComments = () => {
	// 	this.page = this.page + 1;
	// 	const payload = {
	// 		page: this.page,
	// 		items_per_page: this.selectedOption,
	// 		firstKey: this.firstKey,
	// 		lastKey: this.lastKey,
	// 		ref_id: this.processID,
	// 		comments: this.showComments,
	// 		emails: this.showEmails,
	// 		sms: this.showSMS
	// 	};
	// 	this._incidentViewService.getMailConversations(payload).subscribe(
	// 		(response) => {
	// 			if (response) {
	// 				const newComments = response?.data;
	// 				this.finished = false;
	// 				if (newComments.length > 0) {
	// 					this.mail_list = this.mail_list.concat(newComments);
	// 					if (this.latestEmail == undefined) {
	// 						this.latestEmail = response.latest_email;
	// 					}
	// 				}
	// 				this.copyOfAllCard = response?.data;
	// 				this.finished = true;
	// 				this.notScrolly = true;
	// 				this.commDataTotalCount = response["count"];
	// 				// this.mail_list.sort(function (a, b) {
	// 				// 	return a.creation_time_int - b.creation_time_int
	// 				// })
	// 				// setTimeout(() => {
	// 				// 	this.scrolltop = this.scrollMe?.nativeElement.scrollHeight-(this.scrollMe?.nativeElement.scrollHeight -100);
	// 				//   }, 0);
	// 			}
	// 		},
	// 		(error) => {
	// 			//todo
	// 			console.log("errr0r1");
	// 		}
	// 	);
	// };
	communicationFilter(type) {
		this.communicationFilterSelection = type;
		if (type === "email") {
			this.showEmails = true;
			this.showComments = false;
			this.showSMS = false;
		} else if (type === "comment") {
			this.internalNoteFilterSelection = "all";
			this.showEmails = false;
			this.showComments = true;
			this.showSMS = false;
		} else if (type === "sms") {
			this.showEmails = false;
			this.showComments = false;
			this.showSMS = true;
		} else {
			this.showEmails = true;
			this.showComments = true;
			this.showSMS = true;
		}
		this.page = 1;
		this.commDataTotalCount = 0;
		this.selectedOption = 10;
		this.getMailConversations('getMailConversations');
	}

	internalNoteFilter(type) {
		this.notEmptyCommData = true;
		this.internalNoteFilterSelection = type;
		// if(type === 'all') {
		// 	this.mail_list = this.copyOfAllCard;
		// }
		// else if (type == 'Add Notes'){
		// 	const filterCard = this.copyOfAllCard?.filter(id => (id.type === type || id.type === 'Closure Note' || id.type === 'Resolution'));
		// 	if(filterCard) {
		// 		this.mail_list = filterCard;
		// 	}
		// }
		// else {
		// 	const filterCard = this.copyOfAllCard.filter(id => id.type === type);
		// 	if(filterCard) {
		// 		this.mail_list = filterCard;
		// 	}
		// }
		this.selectedOption = 10;
		this.getMailConversations('getMailConversations');
		if (this.mail_list.length === 0) {
			this.notEmptyCommData = false;
		}
	}

	assignToMyself() {
		if (this.dropdown.current_user_group.length === 1) {
			this.currentAssignmentInfo["group_type"] = this.dropdown.current_user_group[0]["group_type"];
			this.currentAssignmentInfo["group_name"] = this.dropdown.current_user_group[0]["name"];
			this.currentAssignmentInfo["group"] = this.dropdown.current_user_group[0].team_id;
			this.commonActionMap[this.moduleName]['getTeamUser'](this.currentAssignmentInfo.team_id)
			// this._incidentViewService
			// 	.getTeamUserMap({ team_id: this.currentAssignmentInfo.team_id })
			// 	.subscribe((response) => {
			// 		this.dropdown.team_user_profile_map = response;
			// 	});
			if (
				this.dropdown.current_user_group[0].group_type === "Approval" ||
				this.dropdown.current_user_group[0].group_type === "Servicedesk" ||
				this.dropdown.current_user_group[0].group_type === "Expertise"
			) {
				this.currentAssignmentInfo["assignee"] = this.currentUserProfileId;
				this.currentAssignmentInfo["assignee_profile"] = this.currentUser?.profile;
				this.currentAssignmentInfo["expertise"] = null;
				this.currentAssignmentInfo["level"] = null;
				this.currentAssignmentInfo["expertise_id"] = null;
				this.currentAssignmentInfo["level_id"] = null;
			} else {
				if (this.dropdown.current_user_group[0]["level_staff"].length > 1) {
					this.dropdown["current_user_expertise"] = this.dropdown.current_user_group[0]["level_staff"];
					this.currentAssignmentInfo["expertise"] = null;
					this.currentAssignmentInfo["level"] = null;
					this.currentAssignmentInfo["expertise_id"] = null;
					this.currentAssignmentInfo["level_id"] = null;
				} else {
					this.currentAssignmentInfo["expertise"] =
						this.dropdown.current_user_group[0]["level_staff"][0]["expertise"];
					this.currentAssignmentInfo["level"] =
						this.dropdown.current_user_group[0]["level_staff"][0]["expertGroupLevel"][0]["level"];
					this.currentAssignmentInfo["expertise_id"] =
						this.dropdown.current_user_group[0]["level_staff"][0]["expertise_id"];
					this.currentAssignmentInfo["level_id"] =
						this.dropdown.current_user_group[0]["level_staff"][0]["expertGroupLevel"][0]["level_id"];
				}
				this.currentAssignmentInfo["assignee"] = this.currentUserProfileId;
				this.currentAssignmentInfo["assignee_profile"] = this.currentUser?.profile;
			}
		} else if (this.dropdown.current_user_group.length > 1) {
			this.currentAssignmentInfo["group"] = null;
		}
		this.selfAssignSel = true;
	}

	onCancelButtonClick() {
		this.showEditor = false;
		this.showReplyBox = true;
		this.erichTextData = "";
		this.process_data["inci_resolution_time"] = undefined;
		this.isPrivate = "false";
		this.workDuration = {
			day: undefined,
			hr: undefined,
			min: undefined,
		};
		setTimeout(() => {
			this.scrolltop = this.scrollMe?.nativeElement.scrollHeight;
		}, 0);
	}

	onBackButtonClick() {
		this._location.back();
	}
	OnShowConvInciToReq() {
		this._coreSidebarService.getSidebarRegistry("incident-to-request").open();
		this.showConvInciToReq = true;
	}
	onHideConvInciToReq(args) {
		this._coreSidebarService.getSidebarRegistry("incident-to-request").close();
		this.showConvInciToReq = false;
	}
	onShowConvInciToReqView() {
		this._coreSidebarService.getSidebarRegistry("app-incident-request-view").open();
		this.showConvInciToReqView = true;
	}
	onHideConvInciToReqView(args) {
		this._coreSidebarService.getSidebarRegistry("app-incident-request-view").close();
		this.showConvInciToReqView = false;
	}
	onShowReqToChn() {
		this._coreSidebarService.getSidebarRegistry("request-to-change-panelView").open();
		this.showConvReqToChange = true;
	}
	onHideConvReqToChange(args) {
		this._coreSidebarService.getSidebarRegistry("request-to-change-panelView").close();
		this.showConvReqToChange = false;
	}
	OnShowConvReqToInci() {
		this._coreSidebarService.getSidebarRegistry("request-to-incident").open();
		this.showConvReqToInci = true;
	}
	onHideConvReqToInci(args) {
		this._coreSidebarService.getSidebarRegistry("request-to-incident").close();
		this.showConvReqToInci = false;
	}
	onShowConvReqToInciView() {
		this._coreSidebarService.getSidebarRegistry("app-request-incident-view").open();
		this.showConvReqToInciView = true;
	}
	onHideConvReqToInciView(args) {
		this._coreSidebarService.getSidebarRegistry("app-request-incident-view").close();
		this.showConvReqToInciView = false;
	}

	OnShowConvProbToInci = () => {
		this._coreSidebarService.getSidebarRegistry("problem-to-incident").open();
		this.showConvProbToInci = true;
	}
	onHideConvProbToInci = () => {
		this._coreSidebarService.getSidebarRegistry("problem-to-incident").close();
		this.showConvProbToInci = false;
	}
	onShowConvProbToInciView = () => {
		this._coreSidebarService.getSidebarRegistry("app-problem-incident-view").open();
		this.showConvProbToInciView = true;
	}
	onHideConvProbToInciView = () => {
		this._coreSidebarService.getSidebarRegistry("app-problem-incident-view").close();
		this.showConvProbToInciView = false;
	}
	onShowProbToChn = () => {
		this._coreSidebarService.getSidebarRegistry("add-problem-to-change").open();
		this.showConvProbToChange = true;
	} 
	onHideConvProbToChange = () => {
		this._coreSidebarService.getSidebarRegistry("add-problem-to-change").close();
		this.showConvProbToChange = false;
	} 

	OnShowConvChnToInci() {
		this._coreSidebarService.getSidebarRegistry("change-to-incident").open();
		this.showConvChnToInci = true;
	}
	onHideConvChnToInci(args) {
		this._coreSidebarService.getSidebarRegistry("change-to-incident").close();
		this.showConvChnToInci = false;
	}
	onShowConvChnToInciView() {
		this._coreSidebarService.getSidebarRegistry("app-change-incident-view").open();
		this.showConvChnToInciView = true;
	}
	onHideConvChnToInciView(args) {
		this._coreSidebarService.getSidebarRegistry("app-change-incident-view").close();
		this.showConvChnToInciView = false;
	}

	getReqID = (req_obj) => {
		let payload = {
			incident_id: this.processID,
			request_display_id: req_obj?.display_id,
		};
		this.commonActionMap[this.moduleName]['conIncidentToRequest'](req_obj?.id, payload)

	};
	getRequestDetails = (request_id) => {
		this.commonActionMap[this.moduleName]['getIncidentRequest'](request_id)
	};
	previous() {
		if (this.prev_incident_id) {
			this._router.navigateByUrl("incident_view/incident-edit/" + this.prev_incident_id);
		}
	}

	next() {
		if (this.next_incident_id) {
			this._router.navigateByUrl("incident_view/incident-edit/" + this.next_incident_id);
		}
	}

	onCheck() {
		// console.log("level", this.dropdown.level);
	}

	onMessageClick() {
		// console.log()
	}

	onMail() {
		// console.log()
	}

	onNotification() {
		// console.log()
	}

	onSearch() {
		// console.log()
	}

	onImportantClick() {
		// console.log()
	}

	onCalenderClick() {
		// console.log()
	}

	onShoppingCart() {
		// console.log()
	}

	toggleReport() {
		this.isReport = !this.isReport;
	}

	checkIncidentState() {
		if (this.process_data !== undefined) {
			const stateObj = this.process_data_copy.basic_info.state;
			const stateId = parseInt(stateObj.id);
			if (
				stateId === 4 ||
				stateId === 5 ||
				stateId === 6 ||
				this.communicationFilterSelection === "email" ||
				this.communicationFilterSelection === "sms"
			) {
				return false;
			}
		}
		return true;
	}

	isIncidentClosed() {
		if (this.process_data !== undefined) {
			const stateObj = this.process_data.basic_info.status;
			const stateId = parseInt(stateObj.state_id);
			if (
				stateId === 5 ||
				this.communicationFilterSelection === "email" ||
				this.communicationFilterSelection === "sms"
			) {
				return false;
			}
		}
		return true;
	}

	addEmailToDropdown(name) {
		return { full_name: name, email: name, tag: true };
	}

	ngAfterViewInit(): void {
		this._wssService
			.getWsDataStore()
			.pipe(takeUntil(this._unsubscribe))
			.subscribe((res: any) => {
				if (res && res?.channel === "incident_management:update") {
					this.getProcessData()
				}
			});
	}
	// ngOnDestroy() {
	// 	this._scrollSubscription.unsubscribe();
	// }
	// toggleStickySec($event): void {
	// 	this.scrollBottom = $event.type == 'mouseover' ? true : false;
	// }
	editRequester = (args): void => {
		this.currentEvent = "Edit";
		this.currentRequester = args.requester_id;
		this._coreSidebarService.getSidebarRegistry("edit-requester").toggleOpen();
		this.hideProfile = true;
		
	};

	onHideProfile = (e) => {
		this.hideProfile = false;
		// this._coreSidebarService.getSidebarRegistry("edit-requester").close();
	};

	showSummaryCard(summaryData, priorityColor, incident_id) {
		// returning from method as on disable we will not allow to change the priority
		if (this.disable_config) {
			return true;
		}
		summaryData["priorityColor"] = priorityColor;
		summaryData["priority"] = summaryData.id;
		this.summaryInput = {
			data: summaryData,
			incident_id: incident_id,
			isLoadSummaryCard: true,
		};
	}

	editRequesterUpdate(requester) {
		this.process_data.requester.email = requester.email;
		this.process_data.requester.contact_number = requester?.phone_number?.e164Number;
		this.process_data.requester.full_name = requester.full_name;
		this.process_data.requester_profile = requester.profile_image;
		this.process_data.requester.avatar_color = requester.avatar_color;
	}

	searchKB(event): void {
		const searchStr = event.target.value;
		if (searchStr) {
			const filteredkb = this.allKBs.filter((config) => {
				let flag = false;
				config?.keywords?.forEach((key) => {
					if (key.toLowerCase().includes(searchStr.toLowerCase())) {
						flag = true;
					}
				});
				return (
					flag ||
					config?.content.toLowerCase().includes(searchStr.toLowerCase()) ||
					config?.created_by.toLowerCase().includes(searchStr.toLowerCase()) ||
					config?.kbtype.toLowerCase().includes(searchStr.toLowerCase()) ||
					config?.title.toLowerCase().includes(searchStr.toLowerCase())
				);
			});
			this.filteredKBs = filteredkb;
		} else {
			this.filteredKBs = this.allKBs;
		}
	}

	ShowAllKb(name: string) {
		this.showAttachedKB = true;
		// this.showKb = !this.showKb;
		this.selectedCount = this.totalCount;
		this.cardCount = this.totalCount;
		this.getAllKB(this.process_data?.summary);
		setTimeout(() => {
			this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
		}, 500);
		// this.suggested_kb = this.kbDataDup;
	}

	getAllKB(filter) {
		const payload = {
			page: this.kbPageNumber,
			items_per_page: this.selectedCount,
			cardCount: this.cardCount,
			sortKey: "creation_time",
			is_external: true,
		};
		if (filter !== null && filter !== undefined && filter !== "") {
			payload["filter"] = filter;
			this._kbService.getKBProfiles(payload).subscribe(
				(response) => {
					if (response) {
						// if (this.ShowAllKb) {
						// 	this.limit = Number(this.limit) + 1;
						// }
						this.suggested_kb = response.results;
						this.totalCount = response.count;
						this.kbCount = this.suggested_kb.length;
						this.filteredKBs.forEach((attach_ele) => {
							this.suggested_kb.forEach((ele, index) => {
								if (attach_ele.kb_id === ele.kb_id) {
									this.suggested_kb.splice(index, 1);
								}
							});
						});
					}
				},
				(error) => {
					// todo
				}
			);
		} else {
			this.suggested_kb = [];
		}
	}

	HideAllKb($event) {
		this.showAttachedKB = false
	}

	info_action = () => {
		if (!this.is_ssp_login){
			if (!this.process_data?.impacted_asset[0]?.track_id?.track_id) {
				window.open("/it-asset/asset/" + this.process_data?.impacted_asset[0]?.ci_id, "_blank");
			} else {
				this.data = { track_id: this.process_data?.impacted_asset[0]?.track_id, from_external: true };
				this.load_alert_details = true;
				this._coreSidebarService.getSidebarRegistry("event-details").toggleOpen();
			}
		}
	};

	viewKB = () => {
		if (this.process_data?.kb_id) {
			window.open("/kb/view/" + this.process_data?.kb_id, "_blank");
		}
	};

	hideAssetSidebar = (reload) => {
		this.load_alert_details = false;
		this.data = {};
		if (reload) {
			this.modifiedtime = new Date().getTime();
		}
	};

	redirectToKb(id) {}

	updateAllKb($event) {
		this.filteredKBs = $event?.attachedKb;
		// this.process_data["attached_kb"] = this.filteredKBs;
		if ($event?.action === "detach") {
			this.process_data["detached_kb"] = [$event?.kb];
		} else {
			this.process_data["attached_kb"] = [$event?.kb];
		}
		this.process_data["attached_kb"] = [$event?.kb];
		this.updateIncident(this.process_data);
	}

	DettachToIncident(item) {
		this.filteredKBs.forEach((ele, index) => {
			if (item.kb_id === ele.kb_id) {
				this.filteredKBs.splice(index, 1);
			}
		});
		this.process_data["detached_kb"] = [item];
		// this.process_data["attached_kb"] = this.filteredKBs;
		this.updateIncident(this.process_data);
	}

	onPriorityChange = ($event) => {
		this.process_data.basic_info.priority["id"] = $event.id;
		this.updateIncident(this.process_data);
	};

	openAssignee($event) {
		this.openModalAD(this.currentAssigneeComp);
	}

	onStageStatusChange($event) {
		this.load_inci_resolve = true;
		this.selected_status = $event;
		if ($event?.state_id >= 4) {
			if(this.moduleName === 'Incident'){
				this.load_inci_resolve = true;
				this._coreSidebarService.getSidebarRegistry("close-resolve-incident").toggleOpen();
			} else if(this.moduleName === 'Request'){
				this.showReqResolve = true
				this._coreSidebarService.getSidebarRegistry("process-detail-request-resolve").toggleOpen();
			} else if(this.moduleName === 'Problem'){
				this.showProbResolve = true
				this._coreSidebarService.getSidebarRegistry("process-detail-problem-resolve").toggleOpen();
			}
			// this._coreSidebarService.getSidebarRegistry("close-resolve-incident").toggleOpen();
		} else {
			this.process_data.basic_info["status"] = $event;
			this.process_data.basic_info["state"] = this.dropdown?.state_id_map[$event?.state_id];
			this.updateIncident(this.process_data);
		}
	}

	onUpdateResolveClose($event) {
		const process_obj = $event;
		this.load_inci_resolve = false;
		if (process_obj.actual_start_time instanceof Array) {
			process_obj.actual_start_time = process_obj.actual_start_time[0];
		}
		if (process_obj.agreed_closure_date instanceof Array) {
			process_obj.agreed_closure_date = process_obj.agreed_closure_date[0];
		}
		if (process_obj.actual_closure_date instanceof Array) {
			process_obj.actual_closure_date = process_obj.actual_closure_date[0];
		}
		if (process_obj.inci_resolution_time instanceof Array) {
			process_obj.inci_resolution_time = process_obj.inci_resolution_time[0];
		}
		if (process_obj.inci_resolution_time && process_obj.resolution_comment) {
			const cf_data = this.process_extra_info?.custom_field_config?.custom_fields?.filter(
				(d) => d?.section === "resolution"
			);
			let payload: any;
			if(this.moduleName === 'Incident'){
				payload = {
					ref_id: process_obj.incident_id,
					display_id: process_obj.display_id,
					rich_text_content: {
						description: process_obj.resolution_comment,
						type: "Resolution",
						is_private: false,
						resolution_date: process_obj.inci_resolution_time,
					},
					custom_fields: cf_data,
					custom_field_data: this.process_data?.custom_field_data,
				};
			} else if(this.moduleName === 'Request'){
				payload = {
					ref_id: process_obj.incident_id,
					display_id: process_obj.display_id,
					rich_text_content: {
						description: process_obj.resolution_comment,
						type: "Resolution",
						is_private: false,
						resolution_date: process_obj.request_resolution_time, 
					},
					custom_fields: cf_data,
					custom_field_data: this.process_data?.custom_field_data,
				};
			}
			this.commonActionMap[this.moduleName]['saveRichTextData'](payload)
			// this._incidentViewService.saveRichTextData(payload).subscribe((response) => {
			// 	if (response["status"] === "success") {
			// 		// saved rich text
			// 	}
			// });
		}
		this.updateIncident($event);
	}

	onHideResolve = ($event) => {
		if(this.moduleName === 'Incident'){
			this.load_inci_resolve = false;
			this._coreSidebarService.getSidebarRegistry("close-resolve-incident").toggleOpen();
		} else if(this.moduleName === 'Request'){
			this.showReqResolve = false;
			this.showReqClose = false;
			this._coreSidebarService.getSidebarRegistry("process-detail-request-resolve").toggleOpen();
		} else if(this.moduleName === 'Problem'){
			this.showProbResolve = false
			this.showProbClose = false;
			this._coreSidebarService.getSidebarRegistry("process-detail-problem-resolve").toggleOpen();
		}
		const that = this;
		this.process_data.basic_info["status"] = this.temp_status;
		this.process_data.basic_info["state"] = this.dropdown?.state_id_map[this.temp_status?.state_id];
		// this._coreSidebarService.getSidebarRegistry('close-resolve-incident').toggleOpen();
		const tempStatusId = that.process_data_copy?.basic_info?.status.id;
		this.sliderStatusPickerComponent?.updateValue(tempStatusId);
		
	}
	onHideClose = () => {
		if(this.moduleName === 'Request'){
			this.showReqClose = false;
			this._coreSidebarService.getSidebarRegistry("process-detail-request-close").toggleOpen();
		} else if(this.moduleName === 'Problem'){
			this.showProbClose = false;
			this._coreSidebarService.getSidebarRegistry("process-detail-problem-close").toggleOpen();
		}else if(this.moduleName === 'Release'){
			this.showReleaseClose = false;
			this._coreSidebarService.getSidebarRegistry("process-detail-release-close").toggleOpen();
		}else if(this.moduleName === 'Change'){
			this.showChangeClose = false;
			this._coreSidebarService.getSidebarRegistry("process-detail-Change-close").toggleOpen();
		}
		const that = this;
		this.process_data.basic_info["status"] = this.temp_status;
		this.process_data.basic_info["state"] = this.dropdown?.state_id_map[this.temp_status?.state_id];
		// this._coreSidebarService.getSidebarRegistry('close-resolve-incident').toggleOpen();
		const tempStatusId = that.process_data_copy?.basic_info?.status.id;
		this.sliderStatusPickerComponent?.updateValue(tempStatusId);
	}
	handleEvent(e) {}

	get_custom_data(config_data, custom_fields) {
		const actual_conf_data = {};
		if (config_data && custom_fields) {
			config_data.forEach((conf) => {
				const data_key = conf?.data_key;
				const label = conf?.label;
				let value = custom_fields[data_key];
				if (conf?.type === "date" || conf?.type === "datetime-local") {
					if (this.process_data.custom_field_data[data_key] instanceof Array) {
						this.process_data.custom_field_data[data_key] =
							this.process_data.custom_field_data[data_key][0];
					}
					if (value instanceof Array) {
						value = value[0];
					}
				}
				if (value) {
					actual_conf_data[label] = value;
				}
			});
		}
		return actual_conf_data;
	}

	customSearchFn(term: string, item: any) {
		return customSearch(term, item, "full_name", "email");
	}

	expectedAssingee(name) {
		this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
		this.showEscalation = true;
	}
	onHideEscalation($event) {
		this.showEscalation = false;
	}

	toggleSideBar = (name: string) => {
		this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
	};

	openChooseExisting = (data: any) => {
		this.toggleSideBar('incident-choose-exist-ticket');
	}

	serviceChangeListener = (args: any) => {
		this.toggleSideBar("incident-service-change-sidebar-inner");
	};

	setRichTextData(payload) {
		this.commonActionMap[this.moduleName]['saveRichTextData'](payload)
		// this._incidentViewService.saveRichTextData(payload).subscribe((response) => {
		// 	if (response["status"] === "success") {
		// 		this._toastr.success(
		// 			this._translateService.instant(response["message"]),
		// 			this._translateService.instant("Message.msg_save_success"),
		// 			{
		// 				toastClass: "toast ngx-toastr",
		// 				closeButton: true,
		// 				positionClass: "toast-top-right",
		// 			}
		// 		);
		// 		//user this method reset communication data and load mail conversation
		// 		this.dnsCommunication.resetCommunicationData();
		// 	} else {
		// 		this._toastr.error(
		// 			this._translateService.instant(response["message"]),
		// 			this._translateService.instant("Error.err_save_failed"),
		// 			{
		// 				toastClass: "toast ngx-toastr",
		// 				closeButton: true,
		// 				positionClass: "toast-top-right",
		// 			}
		// 		);
		// 		this.disableCommentSubmit = false;
		// 	}
		// });
	}
	editProcessData(data, from_close_incident = false, from_assign = false) {
		this.process_data = data.refdata;
		if (data.resolution_date instanceof Array && data.resolution) {
			this.resolution_date = this.resolution_date[0];
			// let date = new Date(this.resolution_date);
			// final_content = data.resolution;
			// final_content = `Resolution Date: ` + date.toLocaleString()+ `<br>` + resolution
			
		}
		if (data.resolution){
			this.process_data.inci_resolution_time = data.resolution_date;
		}
		if (data.inci_resolution_time instanceof Array && data.inci_resolution_time) {
			this.process_data.inci_resolution_time = data.inci_resolution_time[0];
		}
			
		let param = {
			display_id: this.process_data.display_id,
			incident_id: this.processID,
			inci_resolution_time: data.resolution_date,
		};
		if (data.resolution_date !== undefined) {
			this.process_data.basic_info["state"] = { id: 4 };
			let resolved_status_li = this.dropdown?.status.filter((e) => e["state_id"] === this.stateKeyIdMap["Resolved"]);
			if (resolved_status_li) {
				this.process_data.basic_info["status"] = {
					id: resolved_status_li[0]["id"],
				};
			}
			
			this.commonActionMap[this.moduleName]['updateApprovalData'](this.processID, this.process_data, '')
			// this._incidentViewService
			// 	.editIncident(this.processID, this.process_data)
			// 	.pipe(
			// 		map((response: any) => {
			// 			if (response) {
			// 				this._modalService.dismissAll();
			// 				this.selfAssignSel = false;
			// 				this.getProcessData();
			// 			} else {
			// 				this.disableCommentSubmit = false;
			// 				this._toastr.error(
			// 					this._translateService.instant(response["message"]),
			// 					this._translateService.instant("Error.err_save_failed"),
			// 					{
			// 						toastClass: "toast ngx-toastr",
			// 						closeButton: true,
			// 						positionClass: "toast-top-right",
			// 					}
			// 				);
			// 			}
			// 			// Success
			// 		}),
			// 		catchError((error) => {
			// 			return throwError(error.statusText);
			// 		})
			// 	)
			// 	.subscribe();
		} else {
			this._toastr.error(
				this._translateService.instant("Error.err_resolution_date_mandatory_fields"),
				this._translateService.instant("Error.err_save_failed"),
				{
					toastClass: "toast ngx-toastr",
					closeButton: true,
					positionClass: "toast-top-right",
				}
			);
			return false;
		}
		// final_content = `Resolution Date: ` + this.resolution_date['day'] +`/`+ this.resolution_date['month'] + `/` + this.resolution_date['year'] + `<br>Resolution: ` + resolution
	}

	setClosureNoteData(event) {
		let section = "closure";
		this.process_data = event.refdata;
		const cf_data = this.process_extra_info?.custom_field_config?.custom_fields?.filter((d) => d?.section == section);
		if (!this.process_data?.close_info) {
			this.process_data["close_info"] = {};
		}
		// this.process_data.close_info["closure_note"] = this.erichTextData;
		this.process_data.close_info["custom_field_data"] = this.get_custom_data(
			cf_data,
			this.process_data?.custom_field_data
		);
		this.reloadCommunication = true;
		this.showEditor = false;
 		this.saveProcessData(event.form_ref, true,);
		this.dnsCommunication.resetCommunicationData();
		return true;
	}



	setApprovalData(event) {
		let approval_data = {
			approval_comment: event.data,
			approval_state: event.approval_state,
			is_rollback: this.is_rollback,
		};
		this.process_data = event.refdata;
		this.disableCommentSubmit = true;
		this.process_data["approval_data"] = approval_data;
		this.commonActionMap[this.moduleName]['updateApprovalData'](this.processID, this.process_data, 'Approval')

		// this._incidentViewService
		// 	.editIncident(this.processID, this.process_data)
		// 	.pipe(
		// 		map((response: any) => {
		// 			if (response) {
		// 				this._modalService.dismissAll();
		// 				this.selfAssignSel = false;
		// 				this.getProcessData();
		// 				this.dnsCommunication.resetCommunicationData();
		// 				this.disableCommentSubmit = false;
		// 			} else {
		// 				this._toastr.error(
		// 					this._translateService.instant(response["message"]),
		// 					this._translateService.instant("Error.err_save_failed"),
		// 					{
		// 						toastClass: "toast ngx-toastr",
		// 						closeButton: true,
		// 						positionClass: "toast-top-right",
		// 					}
		// 				);
		// 				this.disableCommentSubmit = false;
		// 			}
		// 			// Success
		// 		}),
		// 		catchError((error) => {
		// 			this.disableCommentSubmit = false;

		// 			return throwError(error.statusText);
		// 		})
		// 	)
		// 	.subscribe();

		this.disableCommentSubmit = false;
	}



	handleEditorData = (event) => {
		if (event.type === "Resolution") {
			this.dropdown["filtered_status"] = this.dropdown?.status?.filter(
				(e) => e["state_id"] === this.stateKeyIdMap["Resolved"]
			);
			this.process_data.basic_info.state["id"] = this.dropdown?.filtered_status?.[0]["state_id"];
			this.process_data.basic_info.status["id"] = this.dropdown?.filtered_status?.[0]["id"];
		} else if (event.type === "Approval") {
			this.commonActionMap[this.moduleName]['getApprovalStatus'](null);
			// this._incidentViewService
			// 	.getApprovalStatus({
			// 		incident_id: this.processID,
			// 	})
			// 	.subscribe((res) => {
			// 		if (res?.data?.is_approval_completed !== true) {
			// 			this.dnsCommunication.enableEditor("approvealSet", undefined);
			// 			this.dropdown["filtered_status"] = this.dropdown?.transition_status?.filter((e) => {
			// 				return e["state_id"] === this.stateKeyIdMap["Approval"];
			// 			});
			// 			this.process_data.basic_info.state["id"] = this.dropdown?.filtered_status?.[0]["state_id"];
			// 			this.process_data.basic_info.status["id"] = this.dropdown?.filtered_status?.[0]["id"];
			// 		} else {
			// 			this._toastr.error("Error", this._translateService.instant(res?.message));
			// 		}
			// 	});
		} else {
			this.dropdown["filtered_status"] = this.dropdown?.transition_status?.filter(
				(e) => e["state_id"] === this.stateKeyIdMap["Close"]
			);
				if (this.process_data?.close_info?.closure_note) {
				this.dnsCommunication.enableEditor(
					"closureNoteSet",
					undefined,
					this.process_data?.close_info?.closure_note
				);
			} else {
				this.process_data.basic_info.state["id"] = this.dropdown?.filtered_status?.[0]["state_id"];
				this.process_data.basic_info.status["id"] = this.dropdown?.filtered_status?.[0]["id"];
				this.dnsCommunication.enableEditor("closureNoteSet", undefined);
			}
		}
	};


	onStatusChange = (data) => {
		if (data?.state_id === 6) {
			this.temp_status = data;
			this._modalService.open(this.appovalChangeComp, {
				centered: true,
				animation: false,
				size: "md",
			});
		} else {
			
			if (data?.state_id >= 4 && data?.state_id != 7) {
				this.selected_status = data;
				if(this.moduleName === 'Incident'){
					this.load_inci_resolve = true;
					this._coreSidebarService.getSidebarRegistry("close-resolve-incident").toggleOpen();
				} else if(this.moduleName === 'Request'){
					this.showReqResolve = true
					this._coreSidebarService.getSidebarRegistry("process-detail-request-resolve").toggleOpen();
				}  else if(this.moduleName === 'Problem'){
					this.showProbResolve = true
					this._coreSidebarService.getSidebarRegistry("process-detail-problem-resolve").toggleOpen();
				}else if(this.moduleName === 'Release' && data?.state_id === 5){
					this.showReleaseClose = true
					this._coreSidebarService.getSidebarRegistry("process-detail-release-close").toggleOpen();
				}else if(this.moduleName === 'Change' && data?.state_id === 5){
					this.checkAllTaskClosed({});
				}
				
			} else {
				this.process_data.basic_info["status"] = data;
				this.process_data.basic_info["state"] = this.dropdown?.state_id_map[data?.state_id];
				this.updateIncident(this.process_data);
			}

			// this.process_data.basic_info["status"] = data;
			// this.process_data.basic_info["state"] = this.dropdown?.state_id_map[data?.state_id];
			// if (this.process_data?.basic_info?.state?.id == this.stateKeyIdMap["Resolved"]) {
			// 	this.process_data["inci_resolution_time"] = null;
			// }
			// if (this.process_data?.basic_info?.state?.id != 5) {
			// 	//not close
			// 	this.process_data.close_info = {};
			// 	this.process_data["actual_closure_date"] = null;
			// 	this.process_data["agreed_closure_date"] = null;
			// }
		}
	};


getRefMailConversations = function (evt) {
	this.commonActionMap[this.moduleName]['getRefMailConversations'](evt)
};


	getColorName(percent) {
		if (percent > 75) {
			return "danger";
		} else {
			if (percent > 50 && percent <= 75) {
				return "warning";
			} else {
				return "success";
			}
		}
	}

	getSlaInfoData = () => {
		if (this.commonActionMap[this.moduleName]['getSLAInfoData'] != undefined){
			this.commonActionMap[this.moduleName]?.['getSLAInfoData']();
		}
	}

	menuClick(handle, index, selected_menu): void {
		this.selectedItemName = handle;
		this.selectedItem = index;
		if (selected_menu?.listener != undefined){
			if (handle == "relation"){
				selected_menu.listener(this.moduleName)
			} else if(handle == "communication_tab") {
				selected_menu.listener('getMailConversations')
			} else if(handle == "resent_activity") {
				selected_menu.listener(this.processID, 'getHistoryDetails')
			} else if(handle == "attachment") {
				selected_menu.listener(this.processID, 'getAttachmentDetails')
			} else if(handle == "knowledge_base") {
				selected_menu.listener(this.processID, 'getAllKBs')	
			} else {
				selected_menu.listener()
			}
		}
	}
	incidentDetailSidebar(name): void {
		let sidebarRef = this._coreSidebarService.getSidebarRegistry(this.sidebarName);
		sidebarRef.toggleOpen();
		this.onHideDetailSidebar.emit(false);
	}
	@HostListener('click', ['$event', '$event.target'])
  onClick(event: MouseEvent, targetElement: HTMLElement): void {
    let ele = targetElement.parentElement;
		let parent = ele.parentElement;
    if (ele.className === 'edit-icon') {
      document.querySelectorAll('.edit-hover').forEach((e) => {
				var child = e.children				
				for (var i = 0; i < child.length; i++) {					
					if (child[i].className === "default-value d-none") {
						child[i].classList.remove('d-none');
					}  
					if (child[i].className === "edit-value d-block") {
						child[i].classList.remove('d-block');
					}       
				}
      });
			for (var i = 0; i < parent.children.length; i++) {
        if (parent.children[i].className === 'default-value') {          
          parent.children[i].classList.add('d-none');
        }
        if (parent.children[i].className === 'edit-value') {
          parent.children[i].classList.add('d-block');
        }
      }
      
    } 
		if((ele.closest(".edit-hover") || ele.closest("ng-dropdown-panel")) == null){
			document.querySelectorAll('.edit-hover').forEach((e) => {
				var child = e.children
				for (var i = 0; i < child.length; i++) {					
					if (child[i].className === "default-value d-none") {
						child[i].classList.remove('d-none');
					}  
					if (child[i].className === "edit-value d-block") {
						child[i].classList.remove('d-block');
					}       
			}
			});
		}			
		if(this.selectedItemName === 'communication_tab'){			
			let pad_bottom = document.querySelector('.full-width-fix')?.clientHeight;
			document.getElementById('custom-padding').style.paddingBottom = pad_bottom + 'px';
		}else{
			document.getElementById('custom-padding').style.paddingBottom = 0 + 'px';
		}
  }
	@HostListener('mouseover', ['$event'])
	mouseover(event) {						
		if(event.target.matches('.dot') && !event.target.matches('.stage-current')) {
				document.querySelector('.stage-current')?.classList.add('remove-lable')
		} else{
			document.querySelector('.stage-current')?.classList.remove('remove-lable')
		}
		if(event.target.closest('.dot') !== null) {
			document.querySelector('.stage-current')?.classList.add('remove-lable')
		} else{
			document.querySelector('.stage-current')?.classList.remove('remove-lable')
		}
	}

	ShowAllInteraction() {
		this.displayAllInteraction = !this.displayAllInteraction;
		this.requester_history = this.requester_history_dup;
	}

	HideAllInteraction() {
		this.displayAllInteraction = !this.displayAllInteraction;
		this.requester_history = [];
		for (let i = 0; i < 2; i++) {
			this.requester_history.push(this.requester_history_dup[i]);
		}
	}

	getRequesterHistory= () => {
		this.commonActionMap[this.moduleName]['getRequesterHistory']();
	}

	isObjectEmpty(obj) {
        return Object.keys(obj).length === 0;
    }

	filterRelations = () => {
		this.incidentRelations = this.allRelations?.filter(
			(relation) =>
				relation.parent_module_id !== this.kb_module_id &&
				relation.child_module_id !== this.kb_module_id &&
				((relation?.parent_module_id === this.relationModuleIDSel &&
					relation?.child_module_id === this.relationModuleIDSel) ||
					(this.module_id !== this.relationModuleIDSel &&
						(relation?.parent_module_id === this.relationModuleIDSel ||
							relation?.child_module_id === this.relationModuleIDSel)))
		);
	};

	checkInterModuleAssociation = (reln, inter_module_id) => {
		if ((reln?.parent_module_id === inter_module_id || reln?.child_module_id === inter_module_id) && inter_module_id != this.module_id) return true;
	};

	checkSourceName = (incidentMapping, req, parent_id, module_id) => {
		if (req?.parent_module_id === module_id && req?.child_module_id === module_id) {
			if (
				incidentMapping[req?.parent_ref_id === parent_id ? req?.child_ref_id : req?.parent_ref_id]
					?.incident_source_name
			)
				return true;
		} else if (req?.parent_module_id === this.request_module_id || req?.child_module_id === this.request_module_id) {
			if (
				incidentMapping[req?.parent_ref_id === parent_id ? req?.child_ref_id : req?.parent_ref_id]
					?.request_source_name
			)
				return true;
		} else if (req?.parent_module_id === this.problem_module_id || req?.child_module_id === this.problem_module_id) {
			if (
				incidentMapping[req?.parent_ref_id === parent_id ? req?.child_ref_id : req?.parent_ref_id]
					?.problem_source_name
			)
				return true;
		} else if (req?.parent_module_id === this.change_module_id || req?.child_module_id === this.change_module_id) {
			if (
				incidentMapping[req?.parent_ref_id === parent_id ? req?.child_ref_id : req?.parent_ref_id]
					?.change_source_name
			)
				return true;
		} else if (req?.parent_module_id === this.release_module_id || req?.child_module_id === this.release_module_id) {
			if (
				incidentMapping[req?.parent_ref_id === parent_id ? req?.child_ref_id : req?.parent_ref_id]
					?.release_source_name
			)
				return true;
		}else if(req?.parent_module_id == this.incident_module_id || req?.child_module_id == this.incident_module_id){
			if (incidentMapping[req?.parent_ref_id == parent_id ? req?.child_ref_id : req?.parent_ref_id]?.request_source_name) return true
		}
	};

	relationFilter(id) {
		this.relationModuleIDSel = id;
		this.filterRelations();
	};

    isLoggedInUserAssignee() {
        if (this.process_data?.current_assignment_info?.assignee === this.currentUser?.profile?.profile_id) {
            return true;
        }
    }

	markAsReadUnread(msg_obj) {
		this.commonActionMap[this.moduleName]['updateMsgReadStatus'](msg_obj)
	};

	updateStageInputData() {
		const curr_state_id = this.process_data?.basic_info?.status?.state_id;
		let is_approval_flow = false;
		this.copyOfstageInputData = []
		Object.assign(this.copyOfstageInputData, this.stageInputData)

		this.copyOfstageInputData.forEach((stage_obj) => {
			stage_obj["active"] = false;
			stage_obj["currentStage"] = false;
			if (stage_obj?.id === "logged") {
				stage_obj["active"] = true;
			}
			if (
				stage_obj?.id === "responded" &&
				(this.process_data.current_assignment_info.group ||
					this.process_data?.basic_info?.status?.state_id >= 2)
			) {
				stage_obj["active"] = true;
			}

			// TODO need to update for multi approval
			// console.log(curr_state_id, this.process_data.approval_data);
			if (stage_obj?.id === "approval" && this.process_data?.approval_data?.approval_request_id) {
				if (this.process_data?.approval_data?.is_approval_completed) {
					stage_obj["active"] = true;
				} else {
					stage_obj["active"] = false;
				}
				stage_obj["optionList"] = [];
				stage_obj["is_disabled"] = false;
				is_approval_flow = true;
			}
			if (curr_state_id !== undefined && stage_obj?.check_state_id !== undefined) {
				const temp_opt_list = this.dropdown?.stage_state_option_map[stage_obj?.check_state_id];
				if (temp_opt_list === undefined || temp_opt_list?.length < 1) {
					stage_obj["is_disabled"] = true;
				}
			}

			if (
				curr_state_id !== undefined &&
				stage_obj?.check_state_id !== undefined &&
				stage_obj?.check_state_id <= curr_state_id
			) {
				stage_obj["active"] = true;
				if (
					is_approval_flow &&
					stage_obj?.id === "resolution" &&
					!this.process_data?.is_exit &&
					curr_state_id !== 4
				) {
					stage_obj["active"] = false;
					stage_obj["optionList"] = [];
				}
				if (stage_obj?.id === "approval" && this.process_data?.approval_data?.approval_request_id) {
					if (this.process_data?.approval_data?.is_approved) {
						stage_obj["active"] = true;
					} else {
						stage_obj["active"] = false;
					}
				}
				if (stage_obj?.id === "closure" && !this.process_data?.is_exit) {
					stage_obj["active"] = false;
				}
			}

			stage_obj?.optionList.forEach((option_obj) => {
				if (option_obj?.state_id !== undefined && option_obj?.state_id > 0) {
					const subOptionList = this.dropdown?.stage_state_option_map[option_obj?.state_id];
					option_obj["subOptionList"] = subOptionList;
					if (subOptionList?.length < 1) {
						option_obj["is_disabled"] = true;
					}
				}
			});
		});
		let count = 0
		this.copyOfstageInputData.reverse().forEach((stage_obj) => {
			if (stage_obj?.active && count === 0){
				stage_obj["currentStage"] = true
				count += 1
			}
		});
		this.copyOfstageInputData.reverse();
		setTimeout(() => {
			this.isStageDataLoaded = true;
			this.reloadCommunication = true;
		}, 300);
	}

	onApprovalConfigClose = ($event) => {
		const that = this;
		that.process_data.basic_info["status"] = that.process_data_copy?.basic_info?.status;
		that.process_data.basic_info["state"] = that.process_data_copy?.basic_info?.state;
		const tempStatusId = that.process_data_copy?.basic_info?.status.id;
		this.sliderStatusPickerComponent.updateValue(tempStatusId);
		this._modalService.dismissAll();
		this.temp_status = null;
	};

	onApprovalConfigSubmit = ($event) => {
		this._modalService.dismissAll();
		this.process_data.basic_info["status"] = this.temp_status;
		this.process_data.basic_info["state"] = this.dropdown?.state_id_map[this.temp_status?.state_id];
		if (this.process_data?.basic_info?.state?.id !== 5) {
			//not close
			this.process_data.close_info = {};
			this.process_data["actual_closure_date"] = null;
			this.process_data["agreed_closure_date"] = null;
		}
		this.process_data["approval_config"] = $event;
		this.temp_status = null;
		this.updateIncident(this.process_data);
	};

	statusBadgeChange = (data) => {
		if (data["field"] === "priority") {
			this.is_priority_change = true;
			this.process_data["temp_priority"] = data?.event ? data.event : {};
			this._coreSidebarService.getSidebarRegistry("incident-priority-change-incident-sidebar-inner").toggleOpen();
		} else {
			this.process_data.basic_info[data["field"]] = data?.event ? data.event : {};
			if (this.process_data?.actual_due_date){
				this.process_data["due_date"] = this.process_data?.actual_due_date
			}
			this.updateIncident(this.process_data);
		}
	};

	onPrioritySliderClose = ($event) => {
		if ($event !== "submit") {
			this.is_priority_load = true;
			setTimeout(() => {
				this.process_data.basic_info["priority"] = this.process_data_copy?.basic_info?.priority;
				this.is_priority_load = false;
			}, 10);
		}
		this.is_priority_change = false;
	};

	onPriorityChangeSubmit = ($event) => {
		let temp_process_data = _deepCopyJson(this.process_data);
		temp_process_data["comment"] = $event["comment"];
		temp_process_data.basic_info["priority"] = temp_process_data?.temp_priority;
		this.updateIncident(temp_process_data);
		this.is_priority_change = false;
	};

	onImpactChange = ($event) => {
		this.updateIncident(this.process_data);
	};

	onImpactServiceClick = (event, key) => {
		event.preventDefault();
		if (![1, 2].includes(this.process_data?.basic_info?.state?.id)) {
			return;
		}
		if (key === "impact_service") {
			if (!this.service_config) {
				this.service_config = {};
			}
			this.service_config["is_loading"] = true;
			this.dropdown["formatted_services"] = [];
			this.commonActionMap[this.moduleName]['getRequesterImpactService']();

		} else if (key === "service_classification") {
			if (!this.classification_config) {
				this.classification_config = {};
			}
			this.classification_config["is_loading"] = true;
			this.dropdown["formatted_classification_data"] = [];
			if (this.process_data.basic_info?.impact_service) {
				this.commonActionMap[this.moduleName]['getImpactServiceClassification']();
			} else {
				this._toastr.error(this._translateService.instant("Error.err_select_impact_service"));
				this.classification_config["is_loading"] = false;
			}
		}
	};

	serviceValueChange = (item) => {
		this.handleWorkflowOnServiceChange(item);
	};

	handleWorkflowOnServiceChange = (selected_service) => {
		this.commonActionMap[this.moduleName]['getServiceBaseWorkflow'](selected_service)
	};

	prepareServiceChangeData = (res: any, item) => {
		if (res) {
			const workflow_id = res?.options[0]?.workflow_id;
			const current_item = _deepCopyJson(this.process_data);
			const current_workflow_id = current_item.workflow_id || -1;
			this.dropdown.service_change_status = res?.options.filter((x) => [1, 2].includes(x.state_id)) || [];
			const workflow_name = res?.options[0]?.workflow_name || "Untitled";
			const current_workflow_name = current_item?.workflow_name || "Untitled";
			let basic_info = {
				impact_service: item?.value,
				impact_service_name: item?.text,
				service_classification: "",
				service_classification_name: "",
				service_classification_path: "",
				impact_service_tree_path: item?.full_path_text,
			};
			basic_info["catalogue"] = item?.sel_tree_view_data[item?.sel_tree_view_data?.length - 1]["value"];
			basic_info["catalogue_name"] = item?.sel_tree_view_data[item?.sel_tree_view_data?.length - 1]["text"];
			if (item?.sel_tree_view_data && item?.sel_tree_view_data?.length > 2) {
				basic_info["category"] = item?.sel_tree_view_data[1]["value"];
				basic_info["category_name"] = item?.sel_tree_view_data[1]["text"];
			} else {
				basic_info["category"] = "";
				basic_info["category_name"] = "";
			}
			const obj = {
				value: basic_info,
				key: "basic_info",
				incident_id: current_item?.incident_id,
				element: current_item,
				display_id: current_item?.display_id,
				current_assignment_info: res?.current_assignment_info
			};
			if (workflow_id !== current_workflow_id) {
				this.service_change_data = {
					selected_service_config: {
						status: this.dropdown.service_change_status,
						workflow_title: workflow_name,
						custom_field_config: res?.custom_filed_options || {},
						service_name: item.text,
					},
					current_workflow: {
						status: this.dropdown.status || [],
						workflow_title: current_workflow_name,
						service_name: current_item?.basic_info?.impact_service_name,
					},
					current_status: current_item?.basic_info?.status,
					args: obj,
				};
			} else {
				this.service_change_data = {
					args: obj,
				};
			}
			this.is_service_change = true;
			this._coreSidebarService.getSidebarRegistry("incident-service-change-process-view").toggleOpen();
		} else {
			this.is_service_change = false;
		}
	};

	classificationValueChange = (item) => {
		this.process_data.basic_info.service_classification = item?.value;
		this.process_data.basic_info.service_classification_name = item?.text;
		this.process_data.basic_info.service_classification_path = item?.full_path_text;
		this.updateIncident(this.process_data)
	};

	onServiceSliderClose = () => {
		this.is_service_change = false;
	};

	onServiceChangeSubmit = ($event: any) => {
		let temp_process_data = _deepCopyJson(this.process_data);
		if ($event["is_comment_only"]) {
			temp_process_data["comment"] = $event["comment"];
			this.updateObject(temp_process_data["basic_info"], $event["value"]);
		} else {
			this.updateObject(temp_process_data["basic_info"], $event["value"]);
			this.updateObject(temp_process_data["config"], $event["config"]);

			temp_process_data["state_id"] = $event["state_id"];
			temp_process_data["comment"] = $event["comment"];
			if ($event?.custom_field_data) {
				temp_process_data["custom_field_data"] = $event?.custom_field_data || {};
			}
		}
		if ($event?.current_assignment_info){
			temp_process_data["current_assignment_info"] = $event?.current_assignment_info
		}
		delete temp_process_data["workflow_config"];
		delete temp_process_data["workflow_id"];
		delete temp_process_data["workflow_name"];
		this.updateIncident(temp_process_data);
	};

	updateObject = (inputObject, newObject) => {
		if (!inputObject) {
			inputObject = {};
		}
		for (const key in newObject) {
			if (newObject?.[key] !== undefined) {
				inputObject[key] = newObject[key];
			}
		}
	};

	onResolve = ($event, type) => {
		let filtered_status = []
		if (type === "resolve"){
			filtered_status = this.dropdown?.status?.filter(
				(e) => e["state_id"] === this.stateKeyIdMap["Resolved"]
			);
		} else {
			filtered_status = this.dropdown?.status?.filter(
				(e) => e["state_id"] === this.stateKeyIdMap["Close"]
			);
		}
		if (filtered_status?.length > 0){
			this.temp_status = filtered_status[0];
			this.process_data.basic_info.state["id"] = filtered_status?.[0]["state_id"];
			this.process_data.basic_info.status = filtered_status?.[0];
			if (filtered_status[0]?.state_id >= 4) {
				// this.load_inci_resolve = true;
				this.selected_status = filtered_status[0];
				if(this.moduleName === 'Incident'){
					this.load_inci_resolve = true;
					this._coreSidebarService.getSidebarRegistry("close-resolve-incident").toggleOpen();
				} else if(this.moduleName === 'Request'){
					if(type === 'resolve'){
						this.showReqResolve = true
						this._coreSidebarService.getSidebarRegistry("process-detail-request-resolve").toggleOpen();
					}else if(type === 'close'){
						this.showReqClose = true;
						this._coreSidebarService.getSidebarRegistry("process-detail-request-close").toggleOpen();
					}
				} else if(this.moduleName === 'Problem'){
					if(type === 'resolve'){
						this.showProbResolve = true
						this._coreSidebarService.getSidebarRegistry("process-detail-problem-resolve").toggleOpen();
					}else if(type === 'close'){
						this.showProbClose = true;
						this._coreSidebarService.getSidebarRegistry("process-detail-problem-close").toggleOpen();
					}

				}
				// this._coreSidebarService.getSidebarRegistry("close-resolve-incident").toggleOpen();
			}
		}
	}

	onShowInciToChn() {
		this._coreSidebarService.getSidebarRegistry("convt-incident-to-change").open();
		this.showConvIncidentToChange = true;
	}

	onHideConvInciToChange(args) {
		this.showConvIncidentToChange = false;
		this._coreSidebarService.getSidebarRegistry("convt-incident-to-change").close();
		
	}

	onShowInciToChnView() {
		this._coreSidebarService.getSidebarRegistry("convt-incident-to-change-view").open();
		this.showConvIncidentToChangeView = true;
	}

	onHideConvInciToChangeView(args) {
		this._coreSidebarService.getSidebarRegistry("convt-incident-to-change-view").close();
		this.showConvIncidentToChangeView = false;
	}

	getChangeDetails = (change_id) => {
		this.commonActionMap[this.moduleName]['getProcessChange'](change_id)
	};
	getIncidentDetails = (incident_id) => {
		this.commonActionMap[this.moduleName]['getProcessIncident'](incident_id)
	}
	getChangeID = (change_obj) => {
		this.commonActionMap[this.moduleName]['converProcessToChange'](change_obj)
	};
	getInciID = (inci_obj) => {
		this.commonActionMap[this.moduleName]['converProcessToIncident'](inci_obj)

	};
	onShowInciToProb() {
		this._coreSidebarService.getSidebarRegistry("incident-to-problem").open();
		this.showConvInciToProblem = true;
	}

	onHideConvInciToProblem(args) {
		this._coreSidebarService.getSidebarRegistry("incident-to-problem").close();
		this.showConvInciToProblem = false;
	}

	onShowInciToProbView() {
		this._coreSidebarService.getSidebarRegistry("incident-to-problem-view").open();
		this.showConvInciToProblemView = true;
	}

	onHideConvInciToProblemView(args) {
		this._coreSidebarService.getSidebarRegistry("incident-to-problem-view").close();
		this.showConvInciToProblemView = false;
	}

	getProblemDetails = (problem_id) => {
		this.commonActionMap[this.moduleName]['getIncidentProblem'](problem_id)
	};
	getProblemID = (problem_obj) => {
		this.commonActionMap[this.moduleName]['converIncidentToProblem'](problem_obj)
	};

	getIMAPConfig(imap_list) {
		const items: TreeviewItem[] = [];
		imap_list?.forEach(element => {
			const value: any = element?.config_id;
			const item = new TreeviewItem({
				text: element?.username,
				value,
			});
			items.push(item);
		});
		return items;
	}

	imapChange = (eve) => {
		this.selectedIMAPValue = eve.value;
		this.imapSelectedText = eve.text;
		this.emailContent.imap_config_id = this.selectedIMAPValue;
		this.process_data.imap_config_id = this.selectedIMAPValue;
	};

	signatureChange = (eve) => {
		this.selectedSignatureValue = eve.value;
		let prev_text = this.emailContent.signature;
		this.emailContent.reply_mail = this.emailContent.reply_mail?.replace(prev_text, "");
		if (this.emailContent.reply_mail !== undefined) {
			this.emailContent.reply_mail = this.emailContent.reply_mail + "<p><br></p><p><br></p>" + eve.value;
			this.emailContent.signature = "<p><br></p><p><br></p>" + eve.value;
		} else {
			this.emailContent.reply_mail = "<p><br></p><p><br></p>" + eve.value;
			this.emailContent.signature = "<p><br></p><p><br></p>" + eve.value;
		}
	};

	showSignatureModal = (eve) => {
		this.previewData = eve;
		this._modalService.open(this.previewSignature, {
			centered: true,
			animation: false,
			size: "md",
		});
	};

	editTemplate(data) {
		// this._router.navigate(["/account-settings"]);
		window.open("/account-settings?signature=" + true, "_blank");
	}

	getSignature(signature_list) {
		const items: TreeviewItem[] = [];
		signature_list.forEach((element) => {
			const value: any = element?.content;
			const item = new TreeviewItem({
				text: element?.name,
				value,
			});
			items.push(item);
		});
		return items;
	}

	useItSignature(eve) {
		this.selectedSignatureValue = eve.value;
		let prev_text = this.emailContent.signature;
		this.emailContent.reply_mail = this.emailContent.reply_mail?.replace(prev_text, "");
		if (this.emailContent.reply_mail !== undefined) {
			this.emailContent.reply_mail = this.emailContent.reply_mail + "<p><br></p><p><br></p>" + eve.value;
			this.emailContent.signature = "<p><br></p><p><br></p>" + eve.value;
		} else {
			this.emailContent.reply_mail = "<p><br></p><p><br></p>" + eve.value;
			this.emailContent.signature = "<p><br></p><p><br></p>" + eve.value;
		}
		this._modalService.dismissAll();
	}

	detailsPage(id) {
		let assetType = 'it-asset'
		if (id !== undefined) {
			let url = "/" + assetType + "/asset/";
			this._router.navigateByUrl(url + id.toString());
		}
	}
	onApproval = (data) => {
		this.onApprove.emit(data);
	}


// Incident Action List //
	getIncidentData = () =>{
		let payload = {
			"is_ssp_login": this.is_ssp_login
		}
		this._incidentViewService
			.getIncident(this.processID, payload)
			.pipe(
				map(
					(response) => {
						if (response) {
							this.process_data = response?.incident;
							this.escalataionData = response?.escalation_data;
							this.process_data_copy = cloneDeep(this.process_data);
							this.sourceID = this.process_data?.basic_info?.incident_source.id;
							if (this.module_permissions?.["BASE"]?.["KNOWLEDGE_BASE"]) {
								this.getAllKB(this.process_data_copy?.basic_info?.summary);
							}
							if (this.process_data?.basic_info?.state?.id < 4) {
								this.process_data["inci_resolution_time"] = null;
							}
							// this.disable_config = false;
							if (this.process_data?.basic_info?.status?.state_id === 6 && this.permissions?.edit) {
								this.disable_status_config = true;
								this.disable_config = true;
							}else if(this.process_data?.basic_info?.status?.state_id === 5 && this.permissions?.edit){
								this.disable_config = true;
							}
							this.currentUserProfileId = response?.current_user_id;
							this.team_id = response?.incident?.current_assignment_info?.group;
							this.custom_field_config = response?.custom_field_config;
							this.custom_workflow_fields = [];
							for (let i = 0; i < this.custom_field_config?.custom_fields?.length; i++) {
								const each_custom_data = this.custom_field_config.custom_fields[i];
								if (this.custom_workflow_fields[each_custom_data.section] === undefined) {
									this.custom_workflow_fields[each_custom_data.section] = [];
								}
								this.custom_workflow_fields[each_custom_data.section].push(each_custom_data);
							}
							this.custom_field_data = response?.incident?.custom_field_data;
							this.next_incident_id = response?.incident?.next_incident_id;
							this.prev_incident_id = response?.incident?.prev_incident_id;
							this.dropdown["expertise"] = response?.expertise_list;
							this.dropdown["level"] = response?.level_list;
							this.dropdown["assignee"] = response["assignee_list"];
							this.dropdown["transition_status"] = response["transition_status"];
							this.process_data_copy["expertise_list"] = response?.expertise_list;
							this.process_data_copy["level_list"] = response?.level_list;
							this.process_data_copy["assignee_list"] = response?.assignee_list;
							this.extra_info["selected_assignee_info"] = response?.selected_assignee_info;
							this.process_extra_info["selected_assignee_info"] = this.extra_info?.selected_assignee_info;
							this.extra_info["custom_field_config"] = this.custom_field_config;
							this.extra_info["group_name"] = "";
							this.extra_info["level"] = "";
							this.extra_info["expertise"] = "";
							this.extra_info["level_id"] = "";
							this.extra_info["expertise_id"] = "";
							if (response["group_type"]) {
								this.extra_info["group_type"] = response["group_type"];
							}
							if (this.process_data?.current_assignment_info !== undefined) {
								this.prev_current_assignment_info = this.process_data.current_assignment_info;
								if (this.process_data["current_assignment_info"]["group_name"]) {
									this.extra_info["group_name"] =
										this.process_data["current_assignment_info"]["group_name"];
								}

								if (this.process_data["current_assignment_info"]["level"]) {
									this.extra_info["level"] = this.process_data["current_assignment_info"]["level"];
								}
								if (this.process_data["current_assignment_info"]["expertise"]) {
									this.extra_info["expertise"] =
										this.process_data["current_assignment_info"]["expertise"];
								}
								if (this.process_data["current_assignment_info"]["level_id"]) {
									this.extra_info["level_id"] =
										this.process_data["current_assignment_info"]["level_id"];
								}
								if (this.process_data["current_assignment_info"]["expertise_id"]) {
									this.extra_info["expertise_id"] =
										this.process_data["current_assignment_info"]["expertise_id"];
								}
								this.commonActionMap[this.moduleName]['getTeamUser'](this.process_data?.current_assignment_info?.group)
								// this._incidentViewService
								// 	.getTeamUserMap({ team_id: this.process_data?.current_assignment_info?.group })
								// 	.subscribe((response) => {
								// 		this.dropdown["team_user_profile_map"] = response;
								// 	});
							}
							this.dropdown["services"] = response["service_list"];
							this.dropdown["status"] = response?.status;
							this.dropdown["filtered_status"] = this.dropdown?.status?.filter(
								(status) => status.state_id === this.process_data?.basic_info?.state?.id
							);
							this.dropdown["stage_state_option_map"] = response?.stage_state_option_map || {};
							this.load_inci_resolve = false;
							this.updateStageInputData();
							let signature_list = response?.signature_list;
							this.signatureInputData = this.getSignature(signature_list);
							// IMAP List
							this.imap_list = response?.imap_list;
							this.imapInputData = this.getIMAPConfig(this.imap_list);
							if (response["options"]) {
								const options = response["options"];
								this.module_id = options?.module_id;
								this.request_module_id = options?.request_module_id;
								this.dropdown["incident_prefix_list"] = options?.INCIDENT_PREFIX_LIST;
								this.dropdown["relation_type"] = options?.relation_type;
								this.dropdown["state"] = options["state"];
								// this.stateStatusMap = options["state_status_map"];
								this.stateKeyIdMap = options["state_key_id_map"];
								this.dropdown["state_status_map"] = options["state_status_map"];
								// this.dropdown["hold_status"] = options["hold_status"];
								this.dropdown["urgency"] = options["urgency"];
								this.dropdown["priority"] = options["priority"];
								this.dropdown["severity"] = options["severity"];
								this.dropdown["event_status"] = options["event_status"];
								this.dropdown["type"] = options["type"];
								this.dropdown["impact"] = options["impact"];
								this.dropdown["tagList"] = options["tags"];
								this.dropdown["tagTypeList"] = options["TAG_TYPE_LIST"];
								this.dropdown["users"] = options["users"];
								this.dropdown["teams"] = options["teams"];
								this.dropdown["state_id_map"] = options["state_id_map"];
								if (
									this.process_data?.current_assignment_info &&
									this.process_data?.current_assignment_info?.group === undefined &&
									this.process_data?.current_assignment_info?.assignee !== undefined
								) {
									this.team_id = "self_assign";
									this.selfAssignSel = true;
								}
								// if (options.teams && options.current_user_group.length > 0) {
								this.dropdown["teams_and_self_assign"] = [
									{ team_id: "self_assign", name: "Self Assign" },
								].concat(this.dropdown["teams"]);
								// } else {
								// 	this.dropdown["teams_and_self_assign"] = this.dropdown["teams"];
								// }
								this.dropdown["cc_address"] = [
									{
										full_name: this.process_data?.requester?.unmasked_full_name,
										email: this.process_data?.requester?.unmasked_email,
										avatar_color: this.process_data?.requester?.avatar_color,
										profile_image: this.process_data?.requester_profile,
									},
								];
								this.dropdown["to_address"] = [
									{
										full_name: this.process_data?.requester?.unmasked_full_name,
										email: this.process_data?.requester?.unmasked_email,
										avatar_color: this.process_data?.requester?.avatar_color,
										profile_image: this.process_data?.requester_profile,
									},
								];
								this.dropdown["incident_source"] = options["incident_source"];
								this.dropdown["groupt_type_list"] = options["groupt_type_list"];
								this.dropdown["incident_source"] = options["incident_source"];
								this.dropdown["attach_category_list"] = options["attach_category_list"];
								this.dropdown["attach_filter_category_list"] = [
									{
										name: this._translateService.instant("UI.k_all"),
										id: "all",
									},
								];
								this.dropdown["current_user_group"] = options["current_user_group"];
								const imageDefaults = options["image_upload_defaults"];
								this.ALLOWED_TYPES = imageDefaults["allowed_types"];
								this.MAX_IMAGE_SIZE = imageDefaults["max_size"];
								this.MAX_HEIGHT = imageDefaults["max_height"];
								this.MAX_WIDTH = imageDefaults["max_width"];
								this.dropdown["close_type"] = options["close_type"];
								this.dropdown["closure_category_list"] = options["closure_category"];
								this.dropdown["team_user_profile_map"] = options["team_user_profile_map"];
								options.attach_category_list?.forEach((opt_obj) => {
									this.dropdown.attach_filter_category_list.push(opt_obj);
								});
								// this.dropdown["formatted_services"] = [];
								// response?.formatted_services.forEach((ele) => {
								// 	const data = new TreeviewItem(ele);
								// 	this.dropdown["formatted_services"].push(data);
								// });
								// const class_maps = _.cloneDeep(this.dropdown?.service_category_classification_map);
								// if (class_maps) {
								// 	for (const classs_map in class_maps) {
								// 		this.dropdown["service_category_classification_map"][classs_map][
								// 			"formatted_classification_data"
								// 		] = [];
								// 		class_maps[classs_map]["formatted_classification_data"].forEach((ele) => {
								// 			const data = new TreeviewItem(ele);
								// 			this.dropdown["service_category_classification_map"][classs_map][
								// 				"formatted_classification_data"
								// 			].push(data);
								// 		});
								// 	}
								// }
							}
							this.getRequesterHistory()
							this.dataLoaded = true;
							// let editIncident  = this._incidentViewService.getIncidentSidebar();
							// console.log("jjjjjjjj",editIncident,this.hideIncidentInlineEdit,this.extra_info);

							// if(editIncident === true) {
							// 	this.process_extra_info["selected_assignee_info"] = this.extra_info["selected_assignee_info"];
							// 	this.process_extra_info["custom_field_config"] = this.custom_field_config;
							// 	this.process_extra_info["group_type"] = this.extra_info["group_type"];
							// 	this._coreSidebarService.getSidebarRegistry('incident-edit-sidebar').toggleOpen();
							// 	this.hideIncidentInlineEdit = true;
							// 	console.log("lllllll",this.hideIncidentInlineEdit);

							// }
						}
					},
					(error) => {
						// todo
					}
				)
			)
			.subscribe();
	}
	incidentEdit = (payload, from_close_incident = false, from_assign = false) => {
		this.isStageDataLoaded = false;
		this._incidentViewService
			.editIncident(this.processID, payload)
			.pipe(
				map((response: any) => {
					if (response) {
						if (from_close_incident) {
							this.disableCommentSubmit = true;
						}
						this._toastr.success(
							this._translateService.instant(response["message"]),
							this._translateService.instant("Message.msg_save_success"),
							{
								toastClass: "toast ngx-toastr",
								closeButton: true,
								positionClass: "toast-top-right",
							}
						);
						if (this.reloadCommunication) {
							this.reloadCommunicationData();
							this.reloadCommunication = false;
						}
						this._modalService.dismissAll();
						this.selfAssignSel = false;
						this.service_config = {}
						this.classification_config = {}
						this.getProcessData();
						this.getHistoryDetails(this.processID, 'getHistoryDetails');
						this.process_data_copy = cloneDeep(this.process_data);
						this.isStageDataLoaded = true;
					} else {
						if (from_close_incident) {
							this.disableCommentSubmit = false;
						}
						if (from_assign) {
							this.asgnDisabled = false;
						}
						this._toastr.error(
							this._translateService.instant(response["message"]),
							this._translateService.instant("Error.err_save_failed"),
							{
								toastClass: "toast ngx-toastr",
								closeButton: true,
								positionClass: "toast-top-right",
							}
						);
					}
					// Success
				}),
				catchError((error) => {
					if (from_close_incident) {
						this.disableCommentSubmit = false;
					}
					if (from_assign) {
						this.asgnDisabled = false;
					}
					return throwError(error.statusText);
				})
			)
			.subscribe();
	}
	getIncidentTeamUser = (teamId) => {
		this._incidentViewService
		.getTeamUserMap({ team_id: teamId })
		.subscribe((response) => {
			this.dropdown["team_user_profile_map"] = response;
		});
	}
	getincidentAttachmentDetail = (id) => {
		const payload = { incident_id: id };
		this._incidentViewService.getAttachmentDetails(payload).subscribe((response) => {
			this.allAttachments = response;
			this.selectAttachmentCategory({ id: this.selected_attachment_id, name: this.selected_attachment_name });
		});
	}
	getIncidentHistoryDetail = (id) => {
		const payload = { incident_id: id };
		this._incidentViewService.getHistoryDetails(payload).subscribe((response) => {
			this.historyDataset = response;
			this.historydup = this.historyDataset;
			this.formatHistoryData();
		});
	}
	getIncidentAllKBs = (id) => {
		const payload = { incident_id: id };
		this._incidentViewService.getAllKBs(payload).subscribe((response) => {
			this.allKBs = response;
			this.filteredKBs = response;
			for (let i = 0; i < this.allKBs.length; i++) {
				this.allKBs[i].content = this.allKBs[i].content.replace(/<img[^>]*>/g, "");
				this.filteredKBs[i].content = this.filteredKBs[i].content.replace(/<img[^>]*>/g, "");
			}
			
		});
	}
	getIncidentRelation = (relId) => {
		const payload = {
			relation_id: relId,
			ref_id: this.processID,
		};
		this._incidentViewService
			.processIncidentRelations(payload)
			.pipe(
				map((response: any) => {
					this.incidentRelations = response?.process_relation_data;
					this.allRelations = response?.process_relation_data;
					this.requestProcessMapping = response?.request_process_mapping;
					this.incidentMapping = response?.relation_mapping;
					this.filterRelations();
				})
			)
			.subscribe();
	}
	getIncidentRichTextData = () => {
		const payload = {
			ref_id: this.processID,
		};
		this._incidentViewService.getRichTextData(payload).subscribe((response) => {
			this.rich_text_list = response;
			for (const idx in this.rich_text_list) {
				this.rich_text_list[idx]["without_dom_sanitizer_descr"] = this.rich_text_list[idx]["description"];
				this.rich_text_list[idx]["description"] = this._domSanitizerService.bypassSecurityTrustHtml(
					this.rich_text_list[idx]["description"]
				);
			}
		});
	}
	getIncidentMailConversations = () => {
		const payload = {
			page: this.page,
			items_per_page: this.selectedOption,
			ref_id: this.processID,
			comments: this.showComments,
			emails: this.showEmails,
			sms: this.showSMS,
			comment_type: this.internalNoteFilterSelection,
		};
		this._incidentViewService.getMailConversations(payload).subscribe((response) => {
			if (response) {
				this.mail_list = response?.data;
				this.copyOfAllCard = response?.data;
				this.latestEmail = response?.latest_email;
				this.finished = false;
				if (this.mail_list.length === 0) {
					this.notEmptyCommData = false;
					this.finished = true;
				}
				this.finished = true;
				this.notScrolly = true;
				// this.mail_list.sort(function (a, b) {
				// 	return a.creation_time_int - b.creation_time_int
				//   })
				this.commDataCount = this.mail_list.length;
				this.commDataTotalCount = response["count"];
				setTimeout(() => {
					this.scrolltop = this.scrollMe?.nativeElement.scrollHeight + 100;
				}, 0);
			}
		});
	}
	convertIncidentToKB = (kbID) => {
		const payload = {
			incident_id: this.processID,
		};
		this._incidentViewService.convertIncidentToKB(kbID, payload).subscribe(
			(response) => {
				this.getProcessData();
				// if (response["id"]) this.isConvertedToKB = true;
			},
			(error) => {
				// todo
			}
		);
	}
	getIncidentSLAInfoData = () => {
		const payload = {
			ref_id: this.processID,
			module_id: 10,
		};
		this._incidentViewService.getSLAMatricsData(payload).subscribe(
			(response) => {
				if (response && response.length > 0) {
					this.matricsData = response;
				}
			},
			(error) => {
				//todo
			}
		);
	}
	getRequestSLAInfoData = () => {
		const payload = {
			ref_id: this.processID,
			module_id: 42,
		};
		this._requestViewService.getSlaData(payload).subscribe(
			(response) => {
				if (response && response.length > 0) {
					this.matricsData = response;
				}
			},
			(error) => {
				//todo
			}
		);
	}
	getIncidentUserProfile = () => {
		this._incidentViewService
			.getUserProfile(this.currentAssignmentInfo["assignee"])
			.pipe(
				map((response: any) => {
					if (response) {
						this.current_extra_info["selected_assignee_info"] = response;
						this.currentAssignmentInfo["assignee_profile"] = response;
					}
					// Success
				}),
				catchError((error) => {
					return throwError(error.statusText);
				})
			)
			.subscribe();
	}
	saveIncidentConversation = (messageParam, files) => {
		this._incidentViewService.saveConversation(messageParam).subscribe((response) => {
			if (response["status"] === "success") {
				this.isOpenCC = false;
				this.isOpenBCC = false;
				this._toastr.success(
					this._translateService.instant(response["message"]),
					this._translateService.instant("Message.msg_save_success"),
					{
						toastClass: "toast ngx-toastr",
						closeButton: true,
						positionClass: "toast-top-right",
					}
				);
				this.page = 1;
				this.commDataTotalCount = 0;
				this.getMailConversations('getMailConversations');
				this.showReplyBox = true;
				this.isEmailCompOpen = false;
				setTimeout(() => {
					this.scrolltop = this.scrollMe?.nativeElement.scrollHeight + 100;
				}, 0);
				if (files) {
					this.loadAttachments("");
				}
			} else if (response["status"] === "error") {
				this._toastr.error(this._translateService.instant(response["message"]), "Error", {
					toastClass: "toast ngx-toastr",
					closeButton: true,
					positionClass: "toast-top-right",
				});
			}
		});
	}
	getIncidentApprovalStatus = (editerId) => {
		this._incidentViewService
		.getApprovalStatus({
			incident_id: this.processID,
		})
		.subscribe((res) => {
			if (res?.data?.is_approval_completed !== true) {
				this.dnsCommunication.enableEditor("approvealSet", undefined);
				if(editerId){
					this.editorId = editerId;
				}
				this.showEditor = true;
				this.dropdown["filtered_status"] = this.dropdown?.transition_status?.filter((e) => {
					return e["state_id"] === this.stateKeyIdMap["Approval"];
				});
				this.process_data.basic_info.state["id"] = this.dropdown?.filtered_status?.[0]["state_id"];
				this.process_data.basic_info.status["id"] = this.dropdown?.filtered_status?.[0]["id"];
			} else {
				this._toastr.error("Error", this._translateService.instant(res?.message));
			}
		});
	}
	getIncidentRefMailConversations = (evt) => {
		this._incidentViewService.getMailConversations(evt.payload).subscribe((response) => {
			if (response) {
				this.mail_list = response?.data;
				this.mail_list?.forEach((element) => {
					element.content = element.content.replace(/<a id='ticketURL'.*?<\/a>/i, "");
					element.content = element.content.replace(/<p><a.id='feedbackURL'.*\/a>/i,"");
				});
				this.copyOfAllCard = response?.data;
				this.latestEmail = response?.latest_email;
				this.finished = false;
				if (this.mail_list.length === 0) {
					this.notEmptyCommData = false;
					this.finished = true;
				}
				this.finished = true;
				this.notScrolly = true;
				// this.mail_list.sort(function (a, b) {
				// 	return a.creation_time_int - b.creation_time_int
				//   })
				this.commDataCount = this.mail_list.length;
				this.commDataTotalCount = response["count"];
				this.dnsCommunication.handleMailConversionResponse({ response, ...evt });
			}
			// if (response) {
			// 	this.mail_list = response?.data;
			// 	this.mail_list?.forEach((element) => {
			// 		element.content = element.content.replace(/<a id='ticketURL'.*?<\/a>/i, "");
			// 	});
			// 	this.copyOfAllCard = response?.data;
			// 	this.latestEmail = response?.latest_email;
			// 	this.finished = false;
			// 	if (this.mail_list.length === 0) {
			// 		this.notEmptyCommData = false;
			// 		this.finished = true;
			// 	}
			// 	this.finished = true;
			// 	this.notScrolly = true;
			// 	// this.mail_list.sort(function (a, b) {
			// 	// 	return a.creation_time_int - b.creation_time_int
			// 	//   })
			// 	this.commDataCount = this.mail_list.length;
			// 	this.commDataTotalCount = response["count"];
			// 	setTimeout(() => {
			// 		this.scrolltop = this.scrollMe?.nativeElement.scrollHeight + 100;
			// 	}, 0);
			// }
		});
	}
	getIncidentRequesterHistory = () => {
		let palyload = {
			requester_id: this.process_data?.requester?.requester_id,
			requester_email: this.process_data?.requester?.email,
		};
		this._incidentViewService.getReqLatestIncidents(palyload).subscribe((response) => {
			this.requester_history = response?.requester_info;
			this.requester_history_dup = response?.requester_info;
			if (this.displayAllInteraction && this.requester_history_dup.length !== 0) {
				this.requester_history = [];
				for (let i = 0; i < 2; i++) {
					if (this.requester_history_dup[i]) {
						this.requester_history.push(this.requester_history_dup[i]);
					}
				}
			}
		});
	}
	getIncidentProblem = (problem_id) =>{
		this._incidentViewService
			.getProblem(problem_id, { return_main_info: true })
			.pipe(
				map(
					(response) => {
						this.problem_data = response?.problem;
					},
					(error) => {
						//todo
					}
				)
			)
			.subscribe();
	}
	converIncidentToProblem = (problem_obj) => {
		let payload = {
			incident_id: this.process_data?.incident_id,
			problem_display_id: problem_obj?.display_id,
		};
		this._incidentViewService.convertInciToProblem(problem_obj?.id, payload).subscribe(
			(response) => {
				this.getProcessData();
				this.getProblemDetails(problem_obj?.id);
				this.getRelations(this.moduleName);
			},
			(error) => {
				// todo
			}
		);
	}
	getIncidentChange = (change_id) =>{
		this._incidentViewService
			.getChange(change_id, { return_main_info: true })
			.pipe(
				map(
					(response) => {
						this.change_data = response?.change;
					},
					(error) => {
						//todo
					}
				)
			)
			.subscribe();
	}
	converIncidentToChange = (change_obj) => {
		let payload = {
			incident_id: this.process_data?.incident_id,
			change_display_id: change_obj?.display_id,
		};
		this._incidentViewService.convertInciToChange(change_obj?.id, payload).subscribe(
			(response) => {
				this.getProcessData();
				this.getChangeDetails(change_obj?.id);
				this.getRelations(this.moduleName);
			},
			(error) => {
				// todo
			}
		);
	}
	getIncidentServiceBaseWorkflow = (selected_service) => {
		this.is_service_change = true;
		this._incidentViewService
			.getServiceBaseWorkflowStatus({ service_id: selected_service.value,
				is_service_change: true
			 })
			.subscribe((res) => {
				this.prepareServiceChangeData(res, selected_service);
			});
	}
	getIncidentRequesterImpactService = () => {
		this._incidentViewService
		.getRequesterImpactService({
			requester_id: this.process_data.requester?.requester_id,
			is_published: true,
			bind_classification: false,
			module_name_list: JSON.stringify(["incident"]),
		})
		.subscribe(
			(res) => {
				this.service_config["is_editable"] = true;
				if (this.process_data?.basic_info?.status?.state_id === 6 && this.permissions?.edit) {
					this.service_config["is_editable"] = false;
					this.service_config["is_loading"] = false;
				}else if(this.process_data?.basic_info?.status?.state_id === 5 && this.permissions?.edit){
					this.service_config["is_editable"] = false;
					this.service_config["is_loading"] = false;
				}else if(!this.permissions?.edit){
					this.service_config["is_editable"] = false;
					this.service_config["is_loading"] = false;
				}
				this.dropdown["formatted_services"] = [];
				res?.forEach((ele) => {
					const data = new TreeviewItem(ele);
					this.dropdown["formatted_services"].push(data);
				});
				this.service_config["is_loading"] = false;
			},
			(error) => {
				console.log("error_while_fetching impact service", error);
				this.service_config["is_loading"] = false;
			}
		);
	}
	getIncidentImpactServiceClassification = () => {
		this._incidentViewService
		.getImpactServiceClassification({
			service_id: this.process_data.basic_info?.impact_service,
			format_to_treeview: true,
		})
		.subscribe(
			(res) => {
				this.classification_config["is_editable"] = true;
				if (this.process_data?.basic_info?.status?.state_id === 6 && this.permissions?.edit) {
					this.classification_config["is_editable"] = false;
					this.classification_config["is_loading"] = false;
				}else if(this.process_data?.basic_info?.status?.state_id === 5 && this.permissions?.edit){
					this.classification_config["is_editable"] = false;
					this.classification_config["is_loading"] = false;
				}else if(!this.permissions?.edit){
					this.classification_config["is_editable"] = false;
					this.classification_config["is_loading"] = false;
				}
				this.dropdown["formatted_classification_data"] = [];
				res?.classification?.forEach((ele) => {
					const data = new TreeviewItem(ele);
					this.dropdown["formatted_classification_data"].push(data);
				});
				this.classification_config["is_loading"] = false;
			},
			(error) => {
				console.log("error_while_fetching impact service", error);
				this.classification_config["is_loading"] = false;
			}
		);
	}
	updateIncidentMsgReadStatus = (msg_obj) => {
		if (this.isLoggedInUserAssignee()) {
			msg_obj["is_assignee"] = true;
		}
		this._incidentViewService.updateMsgReadStatus(msg_obj).subscribe(
			(response) => {
				if (response["status"] === "success") {
					this._toastr.success(
						this._translateService.instant(response["message"]),
						this._translateService.instant("Message.msg_success"),
						{
							toastClass: "toast ngx-toastr",
							closeButton: true,
							positionClass: "toast-top-right",
						}
					);
					this.dnsCommunication.resetCommunicationData();
				} else if (response["status"] === "failed") {
					this._toastr.error(
						this._translateService.instant(response["message"]),
						this._translateService.instant("Error.err_fail"),
						{
							toastClass: "toast ngx-toastr",
							closeButton: true,
							positionClass: "toast-top-right",
						}
					);
				}
			},
			(error) => {
				// todo
			}
		);
	}
	saveIncidentRichTextData = (payload) => {
		this._incidentViewService.saveRichTextData(payload).subscribe((response) => {
			if (response["status"] === "success") {
				this._toastr.success(
					this._translateService.instant(response["message"]),
					this._translateService.instant("Message.msg_save_success"),
					{
						toastClass: "toast ngx-toastr",
						closeButton: true,
						positionClass: "toast-top-right",
					}
				);
				this.reloadCommunicationData();
				this.reloadCommunication = true;
				this.dnsCommunication.resetCommunicationData();
			} else {
				this._toastr.error(
					this._translateService.instant(response["message"]),
					this._translateService.instant("Error.err_save_failed"),
					{
						toastClass: "toast ngx-toastr",
						closeButton: true,
						positionClass: "toast-top-right",
					}
				);
			}
			this.disableCommentSubmit = false;
		});
	}
	updateIncidentApprovalData = (processID, process_data, otherAction) =>{
		this._incidentViewService
		.editIncident(processID, process_data)
		.pipe(
			map((response: any) => {
				if (response) {
					this._modalService.dismissAll();
					this.selfAssignSel = false;
					this.getProcessData();
					this.dnsCommunication.resetCommunicationData();
					if(otherAction === 'Approval'){
						this.reloadCommunicationData();
						this.disableCommentSubmit = false;
					}
				} else {
					this._toastr.error(
						this._translateService.instant(response["message"]),
						this._translateService.instant("Error.err_save_failed"),
						{
							toastClass: "toast ngx-toastr",
							closeButton: true,
							positionClass: "toast-top-right",
						}
					);
					this.disableCommentSubmit = false;
				}
				// Success
			}),
			catchError((error) => {
				this.disableCommentSubmit = false;

				return throwError(error.statusText);
			})
		)
		.subscribe();
	}
	getIncidentRequest = (request_id) => {
		this._incidentViewService
			.getRequest(request_id, { return_main_info: true })
			.pipe(
				map(
					(response) => {
						this.request_data = response?.request_process;
					},
					(error) => {
						//todo
					}
				)
			)
			.subscribe();
	}
	conIncidentToRequest = (id, payload) => {
		this._incidentViewService.convertIncidentToRequest(id, payload).subscribe(
			(response) => {
				this.getProcessData();
				this.getRequestDetails(id);
				this.getRelations(this.moduleName);
			},
			(error) => {
				// todo
			}
		);
	}
	deleteIncidentAttachment = (attachment_id, payload) => {
		this._incidentViewService.deleteAttachment(attachment_id, payload).subscribe(
			(response) => {
				// window.location.reload();
				this.getAttachmentDetails(this.processID, 'getAttachmentDetails');
				this.getHistoryDetails(this.processID, 'getHistoryDetails');
				if (response["status"] === "success") {
					this._toastr.success(
						this._translateService.instant(response["message"]),
						this._translateService.instant("Message.msg_success"),
						{
							toastClass: "toast ngx-toastr",
							closeButton: true,
							positionClass: "toast-top-right",
						}
					);
				} else if (response["status"] === "failed") {
					this._toastr.error(
						this._translateService.instant(response["message"]),
						this._translateService.instant("Error.err_fail"),
						{
							toastClass: "toast ngx-toastr",
							closeButton: true,
							positionClass: "toast-top-right",
						}
					);
				}
			},
			(error) => {
				// todo
			}
		);
	}
// End of Incident Action List //


// Request Action List //
	requestEdit = (payload, from_close_incident = false, from_assign = false) => {
		this._requestViewService
			.editRequest(this.processID, payload)
			.pipe(
				map((response: any) => {
					if (response) {
						if (from_close_incident) {
							this.disableCommentSubmit = true;
						}
						this._toastr.success(
							this._translateService.instant(response["message"]),
							this._translateService.instant("Message.msg_save_success"),
							{
								toastClass: "toast ngx-toastr",
								closeButton: true,
								positionClass: "toast-top-right",
							}
						);
						if (this.reloadCommunication) {
							this.reloadCommunicationData();
							this.reloadCommunication = false;
						}
						this._modalService.dismissAll();
						this.selfAssignSel = false;
						this.service_config = {}
						this.classification_config = {}
						this.getProcessData();
						this.getHistoryDetails(this.processID, 'getHistoryDetails');
						this.process_data_copy = cloneDeep(this.process_data);
						this.isStageDataLoaded = true;
					} else {
						if (from_close_incident) {
							this.disableCommentSubmit = false;
						}
						if (from_assign) {
							this.asgnDisabled = false;
						}
						this._toastr.error(
							this._translateService.instant(response["message"]),
							this._translateService.instant("Error.err_save_failed"),
							{
								toastClass: "toast ngx-toastr",
								closeButton: true,
								positionClass: "toast-top-right",
							}
						);
					}
					// Success
				}),
				catchError((error) => {
					if (from_close_incident) {
						this.disableCommentSubmit = false;
					}
					if (from_assign) {
						this.asgnDisabled = false;
					}
					return throwError(error.statusText);
				})
			)
			.subscribe();
	}
	getRrequestData = () => {
		let payload = {
			"is_ssp_login": this.is_ssp_login
		}
		this._requestViewService
			.getRequest(this.processID, payload)
			.pipe(
				map(
					(response) => {
						if (response) {
							this.process_data = response?.request_process;
							this.escalataionData = response?.escalation_data;
							this.process_data_copy = cloneDeep(this.process_data);
							this.sourceID = this.process_data?.basic_info?.request_source.id;
							if (this.module_permissions?.["BASE"]?.["KNOWLEDGE_BASE"]) {
								this.getAllKB(this.process_data_copy?.basic_info?.summary);
							}
							if (this.process_data?.basic_info?.state?.id < 4) {
								this.process_data["inci_resolution_time"] = null;
							}
							// this.disable_config = false;
							if (this.process_data?.basic_info?.status?.state_id === 6 && this.permissions?.edit) {
								this.disable_status_config = true;
								this.disable_config = true;
							}else if(this.process_data?.basic_info?.status?.state_id === 5 && this.permissions?.edit){
								this.disable_config = true;
							}
							this.currentUserProfileId = response?.current_user_id;
							this.team_id = response?.request_process?.current_assignment_info?.group;
							this.custom_field_config = response?.custom_field_config;
							this.custom_workflow_fields = [];
							for (let i = 0; i < this.custom_field_config?.custom_fields?.length; i++) {
								const each_custom_data = this.custom_field_config.custom_fields[i];
								if (this.custom_workflow_fields[each_custom_data.section] === undefined) {
									this.custom_workflow_fields[each_custom_data.section] = [];
								}
								this.custom_workflow_fields[each_custom_data.section].push(each_custom_data);
							}
							this.custom_field_data = response?.request_process?.custom_field_data;
							// this.next_incident_id = response?.incident?.next_incident_id;
							// this.prev_incident_id = response?.incident?.prev_incident_id;

							this.dropdown["expertise"] = response?.expertise_list;
							this.dropdown["level"] = response?.level_list;
							this.dropdown["assignee"] = response["assignee_list"];
							this.dropdown["transition_status"] = response["transition_status"];
							this.process_data_copy["expertise_list"] = response?.expertise_list;
							this.process_data_copy["level_list"] = response?.level_list;
							this.process_data_copy["assignee_list"] = response?.assignee_list;
							this.extra_info["selected_assignee_info"] = response?.selected_assignee_info;
							this.process_extra_info["selected_assignee_info"] = this.extra_info?.selected_assignee_info;
							this.extra_info["custom_field_config"] = this.custom_field_config;
							this.extra_info["group_name"] = "";
							this.extra_info["level"] = "";
							this.extra_info["expertise"] = "";
							this.extra_info["level_id"] = "";
							this.extra_info["expertise_id"] = "";
							if (response["group_type"]) {
								this.extra_info["group_type"] = response["group_type"];
							}
							if (this.process_data?.current_assignment_info !== undefined) {
								this.prev_current_assignment_info = this.process_data.current_assignment_info;
								if (this.process_data["current_assignment_info"]["group_name"]) {
									this.extra_info["group_name"] =
										this.process_data["current_assignment_info"]["group_name"];
								}

								if (this.process_data["current_assignment_info"]["level"]) {
									this.extra_info["level"] = this.process_data["current_assignment_info"]["level"];
								}
								if (this.process_data["current_assignment_info"]["expertise"]) {
									this.extra_info["expertise"] =
										this.process_data["current_assignment_info"]["expertise"];
								}
								if (this.process_data["current_assignment_info"]["level_id"]) {
									this.extra_info["level_id"] =
										this.process_data["current_assignment_info"]["level_id"];
								}
								if (this.process_data["current_assignment_info"]["expertise_id"]) {
									this.extra_info["expertise_id"] =
										this.process_data["current_assignment_info"]["expertise_id"];
								}
								this.commonActionMap[this.moduleName]['getTeamUser'](this.process_data?.current_assignment_info?.group)
								// this._incidentViewService
								// 	.getTeamUserMap({ team_id: this.process_data?.current_assignment_info?.group })
								// 	.subscribe((response) => {
								// 		this.dropdown["team_user_profile_map"] = response;
								// 	});
							}
							this.dropdown["services"] = response["service_list"];
							this.dropdown["status"] = response?.status;
							this.dropdown["filtered_status"] = this.dropdown?.status?.filter(
								(status) => status.state_id === this.process_data?.basic_info?.state?.id
							);
							this.dropdown["stage_state_option_map"] = response?.stage_state_option_map || {};
							this.load_inci_resolve = false;
							this.updateStageInputData();
							let signature_list = response?.signature_list;
							this.signatureInputData = this.getSignature(signature_list);
							// IMAP List
							this.imap_list = response?.imap_list;
							this.imapInputData = this.getIMAPConfig(this.imap_list);
							if (response["options"]) {
								const options = response["options"];
								this.module_id = options?.module_id;
								this.incident_module_id = options?.incident_module_id;
								this.dropdown["change_module_id"] = options?.change_module_id;
								this.kb_module_id = options?.kb_module_id;
								this.dropdown["request_prefix_list"] = options?.REQUEST_PREFIX_LIST;
								this.dropdown["relation_type"] = options?.relation_type;
								this.dropdown["state"] = options["state"];
								// this.stateStatusMap = options["state_status_map"];
								this.stateKeyIdMap = options["state_key_id_map"];
								this.dropdown["state_status_map"] = options["state_status_map"];
								// this.dropdown["hold_status"] = options["hold_status"];
								this.dropdown["request_type"] = options["request_type"];
								this.dropdown["request_source"] = options["request_source"];
								this.dropdown["urgency"] = options["urgency"];
								this.dropdown["priority"] = options["priority"];
								this.dropdown["severity"] = options["severity"];
								this.dropdown["event_status"] = options["event_status"];
								this.dropdown["type"] = options["type"];
								this.dropdown["impact"] = options["impact"];
								this.dropdown["tagList"] = options["tags"];
								this.dropdown["tagTypeList"] = options["TAG_TYPE_LIST"];
								this.dropdown["users"] = options["users"];
								this.dropdown["teams"] = options["teams"];
								this.dropdown["state_id_map"] = options["state_id_map"];
								if (
									this.process_data?.current_assignment_info &&
									this.process_data?.current_assignment_info?.group === undefined &&
									this.process_data?.current_assignment_info?.assignee !== undefined
								) {
									this.team_id = "self_assign";
									this.selfAssignSel = true;
								}
								// if (options.teams && options.current_user_group.length > 0) {
								this.dropdown["teams_and_self_assign"] = [
									{ team_id: "self_assign", name: "Self Assign" },
								].concat(this.dropdown["teams"]);
								// } else {
								// 	this.dropdown["teams_and_self_assign"] = this.dropdown["teams"];
								// }
								this.dropdown["cc_address"] = [
									{
										full_name: this.process_data?.requester?.unmasked_full_name,
										email: this.process_data?.requester?.unmasked_email,
										avatar_color: this.process_data?.requester?.avatar_color,
										profile_image: this.process_data?.requester_profile,
									},
								];
								this.dropdown["to_address"] = [
									{
										full_name: this.process_data?.requester?.unmasked_full_name,
										email: this.process_data?.requester?.unmasked_email,
										avatar_color: this.process_data?.requester?.avatar_color,
										profile_image: this.process_data?.requester_profile,
									},
								];
								this.dropdown["request_type"] = options["request_type"];
								this.dropdown["request_source"] = options["request_source"];
								this.dropdown["groupt_type_list"] = options["groupt_type_list"];
								this.dropdown["incident_source"] = options["incident_source"];
								this.dropdown["attach_category_list"] = options["attach_category_list"];
								this.dropdown["attach_filter_category_list"] = [
									{
										name: this._translateService.instant("UI.k_all"),
										id: "all",
									},
								];
								this.dropdown["current_user_group"] = options["current_user_group"];
								const imageDefaults = options["image_upload_defaults"];
								this.ALLOWED_TYPES = imageDefaults["allowed_types"];
								this.MAX_IMAGE_SIZE = imageDefaults["max_size"];
								this.MAX_HEIGHT = imageDefaults["max_height"];
								this.MAX_WIDTH = imageDefaults["max_width"];
								this.dropdown["close_type"] = options["close_type"];
								this.dropdown["closure_category_list"] = options["closure_category"];
								this.dropdown["team_user_profile_map"] = options["team_user_profile_map"];
								options.attach_category_list?.forEach((opt_obj) => {
									this.dropdown.attach_filter_category_list.push(opt_obj);
								});
								// this.dropdown["formatted_services"] = [];
								// response?.formatted_services.forEach((ele) => {
								// 	const data = new TreeviewItem(ele);
								// 	this.dropdown["formatted_services"].push(data);
								// });
								// const class_maps = _.cloneDeep(this.dropdown?.service_category_classification_map);
								// if (class_maps) {
								// 	for (const classs_map in class_maps) {
								// 		this.dropdown["service_category_classification_map"][classs_map][
								// 			"formatted_classification_data"
								// 		] = [];
								// 		class_maps[classs_map]["formatted_classification_data"].forEach((ele) => {
								// 			const data = new TreeviewItem(ele);
								// 			this.dropdown["service_category_classification_map"][classs_map][
								// 				"formatted_classification_data"
								// 			].push(data);
								// 		});
								// 	}
								// }
							}
							this.getRequesterHistory()
							this.dataLoaded = true;
							// let editIncident  = this._incidentViewService.getIncidentSidebar();
							// console.log("jjjjjjjj",editIncident,this.hideIncidentInlineEdit,this.extra_info);

							// if(editIncident === true) {
							// 	this.process_extra_info["selected_assignee_info"] = this.extra_info["selected_assignee_info"];
							// 	this.process_extra_info["custom_field_config"] = this.custom_field_config;
							// 	this.process_extra_info["group_type"] = this.extra_info["group_type"];
							// 	this._coreSidebarService.getSidebarRegistry('incident-edit-sidebar').toggleOpen();
							// 	this.hideIncidentInlineEdit = true;
							// 	console.log("lllllll",this.hideIncidentInlineEdit);

							// }
						}
					},
					(error) => {
						// todo
					}
				)
			)
			.subscribe();
	}
	getRequestTeamUser = (teamId) => {
		this._requestViewService
		.getTeamUserMap({ team_id: teamId })
		.subscribe((response) => {
			this.dropdown["team_user_profile_map"] = response;
		});
	}
	getRequestAttachmentDetail = (id) => {
		const payload = { request_id: id };
		this._requestViewService.getAttachmentDetails(payload).subscribe((response) => {
			this.allAttachments = response;
			this.selectAttachmentCategory({ id: this.selected_attachment_id, name: this.selected_attachment_name });
		});
	}
	getRequestHistoryDetail = (id) => {
		const payload = { request_id: id };
		this._requestViewService.getHistoryDetails(payload).subscribe((response) => {
			this.historyDataset = response;
			this.historydup = this.historyDataset;
			this.formatHistoryData();
		});
	}
	getRequestAllKBs = (id) => {
		const payload = { request_id: id };
		this._requestViewService.getAllKBs(payload).subscribe((response) => {
			this.allKBs = response;
			this.filteredKBs = response;
			for (let i = 0; i < this.allKBs.length; i++) {
				this.allKBs[i].content = this.allKBs[i].content.replace(/<img[^>]*>/g, "");
				this.filteredKBs[i].content = this.filteredKBs[i].content.replace(/<img[^>]*>/g, "");
			}
			
		});
	}
	getRequestRelation = (relId) => {
		const payload = {
			relation_id: relId,
			ref_id: this.processID,
		};
		this._requestViewService
			.processRequestRelations(payload)
			.pipe(
				map((response: any) => {
					this.incidentRelations = response?.process_relation_data;
					this.allRelations = response?.process_relation_data;
					this.requestProcessMapping = response?.request_process_mapping;
					this.incidentMapping = response?.relation_mapping;
					this.filterRelations();
				})
			)
			.subscribe();
	}
	getRequestRichTextData = () => {
		const payload = {
			ref_id: this.processID,
		};
		this._requestViewService.getRichTextData(payload).subscribe((response) => {
			this.rich_text_list = response;
			for (const idx in this.rich_text_list) {
				this.rich_text_list[idx]["without_dom_sanitizer_descr"] = this.rich_text_list[idx]["description"];
				this.rich_text_list[idx]["description"] = this._domSanitizerService.bypassSecurityTrustHtml(
					this.rich_text_list[idx]["description"]
				);
			}
		});
	}
	getRequestMailConversations = () => {
		const payload = {
			page: this.page,
			items_per_page: this.selectedOption,
			ref_id: this.processID,
			comments: this.showComments,
			emails: this.showEmails,
			sms: this.showSMS,
			comment_type: this.internalNoteFilterSelection,
		};
		this._requestViewService.getMailConversations(payload).subscribe((response) => {
			if (response) {
				this.mail_list = response?.data;
				this.copyOfAllCard = response?.data;
				this.latestEmail = response?.latest_email;
				this.finished = false;
				if (this.mail_list.length === 0) {
					this.notEmptyCommData = false;
					this.finished = true;
				}
				this.finished = true;
				this.notScrolly = true;
				// this.mail_list.sort(function (a, b) {
				// 	return a.creation_time_int - b.creation_time_int
				//   })
				this.commDataCount = this.mail_list.length;
				this.commDataTotalCount = response["count"];
				setTimeout(() => {
					this.scrolltop = this.scrollMe?.nativeElement.scrollHeight + 100;
				}, 0);
			}
		});
	}
	convertRequestToKB = (kbID) => {
		const payload = {
			request_id: this.processID,
		};
		this._requestViewService.convertRequestToKB(kbID, payload).subscribe(
			(response) => {
				this.getProcessData();
				// if (response["id"]) this.isConvertedToKB = true;
			},
			(error) => {
				// todo
			}
		);
	}
	getRequestUserProfile = () => {
		this._requestViewService
			.getUserProfile(this.currentAssignmentInfo["assignee"])
			.pipe(
				map((response: any) => {
					if (response) {
						this.current_extra_info["selected_assignee_info"] = response;
						this.currentAssignmentInfo["assignee_profile"] = response;
					}
					// Success
				}),
				catchError((error) => {
					return throwError(error.statusText);
				})
			)
			.subscribe();
	}
	saveRequestConversation = (messageParam, files) => {
		this._requestViewService.saveConversation(messageParam).subscribe((response) => {
			if (response["status"] === "success") {
				this.isOpenCC = false;
				this.isOpenBCC = false;
				this._toastr.success(
					this._translateService.instant(response["message"]),
					this._translateService.instant("Message.msg_save_success"),
					{
						toastClass: "toast ngx-toastr",
						closeButton: true,
						positionClass: "toast-top-right",
					}
				);
				this.page = 1;
				this.commDataTotalCount = 0;
				this.getMailConversations('getMailConversations');
				this.showReplyBox = true;
				this.isEmailCompOpen = false;
				setTimeout(() => {
					this.scrolltop = this.scrollMe?.nativeElement.scrollHeight + 100;
				}, 0);
				if (files) {
					this.loadAttachments("");
				}
			} else if (response["status"] === "error") {
				this._toastr.error(this._translateService.instant(response["message"]), "Error", {
					toastClass: "toast ngx-toastr",
					closeButton: true,
					positionClass: "toast-top-right",
				});
			}
		});
	}
	getRequestApprovalStatus = (editerId) => {
		this._requestViewService
		.getApprovalStatus({
			request_id: this.processID,
		})
		.subscribe((res) => {
			if (res?.data?.is_approval_completed !== true) {
				this.dnsCommunication.enableEditor("approvealSet", undefined);
				if(editerId){
					this.editorId = editerId;
				}
				this.showEditor = true;
				this.dropdown["filtered_status"] = this.dropdown?.transition_status?.filter((e) => {
					return e["state_id"] === this.stateKeyIdMap["Approval"];
				});
				this.process_data.basic_info.state["id"] = this.dropdown?.filtered_status?.[0]["state_id"];
				this.process_data.basic_info.status["id"] = this.dropdown?.filtered_status?.[0]["id"];
			} else {
				this._toastr.error("Error", this._translateService.instant(res?.message));
			}
		});
	}
	getRequestRefMailConversations = (evt) => {
		this._requestViewService.getMailConversations(evt.payload).subscribe((response) => {
			if (response) {
				this.mail_list = response?.data;
				this.mail_list?.forEach((element) => {
					element.content = element.content.replace(/<a id='requestURL'.*?<\/a>/i,'');
				});
				this.copyOfAllCard = response?.data;
				this.latestEmail = response?.latest_email;
				this.finished = false;
				if (this.mail_list.length === 0) {
					this.notEmptyCommData = false;
					this.finished = true;
				}
				this.finished = true;
				this.notScrolly = true;
				// this.mail_list.sort(function (a, b) {
				// 	return a.creation_time_int - b.creation_time_int
				//   })
				this.commDataCount = this.mail_list.length;
				this.commDataTotalCount = response["count"];
				this.dnsCommunication.handleMailConversionResponse({ response, ...evt });
			}
			// if (response) {
			// 	this.mail_list = response?.data;
			// 	this.mail_list?.forEach((element) => {
			// 		element.content = element.content.replace(/<a id='ticketURL'.*?<\/a>/i, "");
			// 	});
			// 	this.copyOfAllCard = response?.data;
			// 	this.latestEmail = response?.latest_email;
			// 	this.finished = false;
			// 	if (this.mail_list.length === 0) {
			// 		this.notEmptyCommData = false;
			// 		this.finished = true;
			// 	}
			// 	this.finished = true;
			// 	this.notScrolly = true;
			// 	// this.mail_list.sort(function (a, b) {
			// 	// 	return a.creation_time_int - b.creation_time_int
			// 	//   })
			// 	this.commDataCount = this.mail_list.length;
			// 	this.commDataTotalCount = response["count"];
			// 	setTimeout(() => {
			// 		this.scrolltop = this.scrollMe?.nativeElement.scrollHeight + 100;
			// 	}, 0);
			// }
		});
	}
	getRequestRequesterHistory = () => {
		let palyload = {
			requester_id: this.process_data?.requester?.requester_id,
			requester_email: this.process_data?.requester?.email,
		};
		this._requestViewService.getReqLatestRequests(palyload).subscribe((response) => {
			this.requester_history = response?.requester_info;
			this.requester_history_dup = response?.requester_info;
			if (this.displayAllInteraction && this.requester_history_dup.length !== 0) {
				this.requester_history = [];
				for (let i = 0; i < 2; i++) {
					if (this.requester_history_dup[i]) {
						this.requester_history.push(this.requester_history_dup[i]);
					}
				}
			}
		});
	}
	getRequestChange = (change_id) => {
		this._requestViewService
		.getChange(change_id, { return_main_info: true })
		.pipe(
			map(
				(response) => {
					this.change_data = response?.change;
				},
				(error) => {
					//todo
				}
			)
		)
		.subscribe();
	}
	converRequestToChange = (change_obj) => {
		let payload = {
			request_id: this.process_data?.request_id,
			change_display_id: change_obj?.display_id,
		};
		this._requestViewService.convertReqToChange(change_obj?.id, payload).subscribe(
			(response) => {
				this.getProcessData();
				this.getChangeDetails(change_obj?.id);
				this.getRelations(this.moduleName);
			},
			(error) => {
				// todo
			}
		);
	}
	getRequestServiceBaseWorkflow = (selected_service) => {
		this.is_service_change = true;
		this._requestViewService
			.getServiceBaseWorkflowStatus({ service_id: selected_service.value })
			.subscribe((res) => {
				this.prepareServiceChangeData(res, selected_service);
			});
	}
	getRequestRequesterImpactService = () => {
		this._requestViewService
		.getRequesterImpactService({
			requester_id: this.process_data.requester?.requester_id,
			is_published: true,
			bind_classification: false,
			module_name_list: JSON.stringify(["incident"]),
		})
		.subscribe(
			(res) => {
				this.service_config["is_editable"] = true;
				if (this.process_data?.basic_info?.status?.state_id === 6 && this.permissions?.edit) {
					this.service_config["is_editable"] = false;
					this.service_config["is_loading"] = false;
				}else if(this.process_data?.basic_info?.status?.state_id === 5 && this.permissions?.edit){
					this.service_config["is_editable"] = false;
					this.service_config["is_loading"] = false;
				}else if(!this.permissions?.edit){
					this.service_config["is_editable"] = false;
					this.service_config["is_loading"] = false;
				}
				this.dropdown["formatted_services"] = [];
				res?.forEach((ele) => {
					const data = new TreeviewItem(ele);
					this.dropdown["formatted_services"].push(data);
				});
				this.service_config["is_loading"] = false;
			},
			(error) => {
				console.log("error_while_fetching impact service", error);
				this.service_config["is_loading"] = false;
			}
		);
	}
	getRequestImpactServiceClassification = () => {
		this._requestViewService
		.getImpactServiceClassification({
			service_id: this.process_data.basic_info?.impact_service,
			format_to_treeview: true,
		})
		.subscribe(
			(res) => {
				this.classification_config["is_editable"] = true;
				if (this.process_data?.basic_info?.status?.state_id === 6 && this.permissions?.edit) {
					this.classification_config["is_editable"] = false;
					this.classification_config["is_loading"] = false;
				}else if(this.process_data?.basic_info?.status?.state_id === 5 && this.permissions?.edit){
					this.classification_config["is_editable"] = false;
					this.classification_config["is_loading"] = false;
				}else if(!this.permissions?.edit){
					this.classification_config["is_editable"] = false;
					this.classification_config["is_loading"] = false;
				}
				this.dropdown["formatted_classification_data"] = [];
				res?.classification?.forEach((ele) => {
					const data = new TreeviewItem(ele);
					this.dropdown["formatted_classification_data"].push(data);
				});
				this.classification_config["is_loading"] = false;
			},
			(error) => {
				console.log("error_while_fetching impact service", error);
				this.classification_config["is_loading"] = false;
			}
		);
	}
	// updateRequestMsgReadStatus = (msg_obj) => {
	// 	if (this.isLoggedInUserAssignee()) {
	// 		msg_obj["is_assignee"] = true;
	// 	}
	// 	this._requestViewService.updateMsgReadStatus(msg_obj).subscribe(
	// 		(response) => {
	// 			if (response["status"] === "success") {
	// 				this._toastr.success(
	// 					this._translateService.instant(response["message"]),
	// 					this._translateService.instant("Message.msg_success"),
	// 					{
	// 						toastClass: "toast ngx-toastr",
	// 						closeButton: true,
	// 						positionClass: "toast-top-right",
	// 					}
	// 				);
	// 				this.dnsCommunication.resetCommunicationData();
	// 			} else if (response["status"] === "failed") {
	// 				this._toastr.error(
	// 					this._translateService.instant(response["message"]),
	// 					this._translateService.instant("Error.err_fail"),
	// 					{
	// 						toastClass: "toast ngx-toastr",
	// 						closeButton: true,
	// 						positionClass: "toast-top-right",
	// 					}
	// 				);
	// 			}
	// 		},
	// 		(error) => {
	// 			// todo
	// 		}
	// 	);
	// }
	saveRequestRichTextData = (payload) => {
		this._requestViewService.saveRichTextData(payload).subscribe((response) => {
			if (response["status"] === "success") {
				this._toastr.success(
					this._translateService.instant(response["message"]),
					this._translateService.instant("Message.msg_save_success"),
					{
						toastClass: "toast ngx-toastr",
						closeButton: true,
						positionClass: "toast-top-right",
					}
				);
				this.reloadCommunicationData();
				this.reloadCommunication = true;
				this.dnsCommunication.resetCommunicationData();
			} else {
				this._toastr.error(
					this._translateService.instant(response["message"]),
					this._translateService.instant("Error.err_save_failed"),
					{
						toastClass: "toast ngx-toastr",
						closeButton: true,
						positionClass: "toast-top-right",
					}
				);
			}
			this.disableCommentSubmit = false;
		});
	}
	updateRequestApprovalData = (processID, process_data, otherAction) => {
		this._requestViewService
		.editRequest(processID, process_data)
		.pipe(
			map((response: any) => {
				if (response) {
					this._modalService.dismissAll();
					this.selfAssignSel = false;
					this.getProcessData();
					this.dnsCommunication.resetCommunicationData();
					if(otherAction === 'Approval'){
						this.reloadCommunicationData();
						this.disableCommentSubmit = false;
					}
				} else {
					this._toastr.error(
						this._translateService.instant(response["message"]),
						this._translateService.instant("Error.err_save_failed"),
						{
							toastClass: "toast ngx-toastr",
							closeButton: true,
							positionClass: "toast-top-right",
						}
					);
					this.disableCommentSubmit = false;
				}
				// Success
			}),
			catchError((error) => {
				this.disableCommentSubmit = false;

				return throwError(error.statusText);
			})
		)
		.subscribe();
	}
	deleteRequestAttachment = (attachment_id, payload) => {
		this._requestViewService.deleteAttachment(attachment_id, payload).subscribe(
			(response) => {
				// window.location.reload();
				this.getAttachmentDetails(this.processID, 'getAttachmentDetails');
				this.getHistoryDetails(this.processID, 'getHistoryDetails');
				if (response["status"] === "success") {
					this._toastr.success(
						this._translateService.instant(response["message"]),
						this._translateService.instant("Message.msg_success"),
						{
							toastClass: "toast ngx-toastr",
							closeButton: true,
							positionClass: "toast-top-right",
						}
					);
				} else if (response["status"] === "failed") {
					this._toastr.error(
						this._translateService.instant(response["message"]),
						this._translateService.instant("Error.err_fail"),
						{
							toastClass: "toast ngx-toastr",
							closeButton: true,
							positionClass: "toast-top-right",
						}
					);
				}
			},
			(error) => {
				// todo
			}
		);
	}
	convertRequestToIncident = (inci_obj) => {
		let payload = {
			request_id: this.process_data?.request_id,
			incident_display_id: inci_obj?.display_id,
		};
		this._requestViewService.convertRequestToIncident(inci_obj?.id, payload).subscribe(
			(response) => {
				this.getProcessData();
				this.getIncidentDetails(inci_obj?.id);
				this.getRelations(this.moduleName);
			},
			(error) => {
				// todo
			}
		);
	}
	getRequestIncident = (incident_id) => {
		this._requestViewService
			.getIncident(incident_id, { return_main_info: true })
			.pipe(
				map(
					(response) => {
						this.incident_data = response?.incident;
					},
					(error) => {
						//todo
					}
				)
			)
			.subscribe();
	};
// End of Request Action List //

getRelationMap(rel_data, type){
	try {
		let map_data: any = {};
		let ref_module_id = -1;
		let ref_id = "";
		if (rel_data?.parent_ref_id === this.processID){
			map_data =  this.incidentMapping[rel_data?.child_ref_id];
			ref_module_id = rel_data?.child_module_id;
			ref_id = rel_data?.child_ref_id;
		} else{
			map_data =  this.incidentMapping[rel_data?.parent_ref_id];
			ref_module_id = rel_data?.parent_module_id;
			ref_id = rel_data?.parent_ref_id;
		}
		if (type == 'link'){
			return this.router_link_map[ref_module_id] + ref_id;
		} else if (type == 'display_id'){
			return map_data.display_id
		} else if (type == 'source_name'){
			return map_data[this.source_name_map[ref_module_id]]
		} else if (type == 'source_id'){
			return map_data[this.source_id_map[ref_module_id]]
		}
	} catch (error) {
		return ""	
	}	
}

// Problem Action List //
problemEdit = (payload, from_close_incident = false, from_assign = false) => {
	this._problemViewService
		.editProblem(this.processID, payload)
		.pipe(
			map((response: any) => {
				if (response) {
					if (from_close_incident) {
						this.disableCommentSubmit = true;
					}
					this._toastr.success(
						this._translateService.instant(response["message"]),
						this._translateService.instant("Message.msg_save_success"),
						{
							toastClass: "toast ngx-toastr",
							closeButton: true,
							positionClass: "toast-top-right",
						}
					);
					if (this.reloadCommunication) {
						this.reloadCommunicationData();
						this.reloadCommunication = false;
					}
					this._modalService.dismissAll();
					this.selfAssignSel = false;
					this.service_config = {}
					this.classification_config = {}
					this.getProcessData();
					this.getHistoryDetails(this.processID, 'getHistoryDetails');
					this.process_data_copy = cloneDeep(this.process_data);
					this.isStageDataLoaded = true;
				} else {
					if (from_close_incident) {
						this.disableCommentSubmit = false;
					}
					if (from_assign) {
						this.asgnDisabled = false;
					}
					this._toastr.error(
						this._translateService.instant(response["message"]),
						this._translateService.instant("Error.err_save_failed"),
						{
							toastClass: "toast ngx-toastr",
							closeButton: true,
							positionClass: "toast-top-right",
						}
					);
				}
				// Success
			}),
			catchError((error) => {
				if (from_close_incident) {
					this.disableCommentSubmit = false;
				}
				if (from_assign) {
					this.asgnDisabled = false;
				}
				return throwError(error.statusText);
			})
		)
		.subscribe();
}
getProblemData = () => {
	let payload = {
		"is_ssp_login": this.is_ssp_login
	}
	this._problemViewService
		.getProblem(this.processID, payload)
		.pipe(
			map(
				(response) => {
					if (response) {
						this.process_data = response?.problem;
						this.escalataionData = response?.escalation_data;
						this.process_data_copy = cloneDeep(this.process_data);
						this.sourceID = this.process_data?.basic_info?.problem_source.id;
						if (this.module_permissions?.["BASE"]?.["KNOWLEDGE_BASE"]) {
							this.getAllKB(this.process_data_copy?.basic_info?.summary);
						}
						if (this.process_data?.basic_info?.state?.id < 4) {
							this.process_data["inci_resolution_time"] = null;
						}
						// this.disable_config = false;
						if (this.process_data?.basic_info?.status?.state_id === 6) {
							this.disable_status_config = true;
							this.disable_config = true;
						}else if(this.process_data?.basic_info?.status?.state_id === 5){
							this.disable_config = true;
						}
						this.currentUserProfileId = response?.current_user_id;
						this.team_id = response?.problem?.current_assignment_info?.group;
						this.custom_field_config = response?.custom_field_config;
						this.custom_workflow_fields = [];
						for (let i = 0; i < this.custom_field_config?.custom_fields?.length; i++) {
							const each_custom_data = this.custom_field_config.custom_fields[i];
							if (this.custom_workflow_fields[each_custom_data.section] === undefined) {
								this.custom_workflow_fields[each_custom_data.section] = [];
							}
							this.custom_workflow_fields[each_custom_data.section].push(each_custom_data);
						}
						this.custom_field_data = response?.problem?.custom_field_data;
						// this.requester_latest_problems = response?.requester_latest_problems;
						// this.next_problem_id = response?.problem?.next_problem_id;
						// this.prev_problem_id = response?.problem?.prev_problem_id;
						// this.next_incident_id = response?.incident?.next_incident_id;
						// this.prev_incident_id = response?.incident?.prev_incident_id;

						this.dropdown["expertise"] = response?.expertise_list;
						this.dropdown["level"] = response?.level_list;
						this.dropdown["assignee"] = response["assignee_list"];
						this.dropdown["transition_status"] = response["transition_status"];
						this.process_data_copy["expertise_list"] = response?.expertise_list;
						this.process_data_copy["level_list"] = response?.level_list;
						this.process_data_copy["assignee_list"] = response?.assignee_list;
						this.extra_info["selected_assignee_info"] = response?.selected_assignee_info;
						this.process_extra_info["selected_assignee_info"] = this.extra_info?.selected_assignee_info;
						this.extra_info["custom_field_config"] = this.custom_field_config;
						this.extra_info["group_name"] = "";
						this.extra_info["level"] = "";
						this.extra_info["expertise"] = "";
						this.extra_info["level_id"] = "";
						this.extra_info["expertise_id"] = "";
						if (response["group_type"]) {
							this.extra_info["group_type"] = response["group_type"];
						}
						if (this.process_data?.current_assignment_info !== undefined) {
							this.prev_current_assignment_info = this.process_data.current_assignment_info;
							if (this.process_data["current_assignment_info"]["group_name"]) {
								this.extra_info["group_name"] =
									this.process_data["current_assignment_info"]["group_name"];
							}

							if (this.process_data["current_assignment_info"]["level"]) {
								this.extra_info["level"] = this.process_data["current_assignment_info"]["level"];
							}
							if (this.process_data["current_assignment_info"]["expertise"]) {
								this.extra_info["expertise"] =
									this.process_data["current_assignment_info"]["expertise"];
							}
							if (this.process_data["current_assignment_info"]["level_id"]) {
								this.extra_info["level_id"] =
									this.process_data["current_assignment_info"]["level_id"];
							}
							if (this.process_data["current_assignment_info"]["expertise_id"]) {
								this.extra_info["expertise_id"] =
									this.process_data["current_assignment_info"]["expertise_id"];
							}
							this.commonActionMap[this.moduleName]['getTeamUser'](this.process_data?.current_assignment_info?.group)
							// this._incidentViewService
							// 	.getTeamUserMap({ team_id: this.process_data?.current_assignment_info?.group })
							// 	.subscribe((response) => {
							// 		this.dropdown["team_user_profile_map"] = response;
							// 	});
						}
						this.dropdown["services"] = response["service_list"];
						this.dropdown["status"] = response?.status;
						this.dropdown["filtered_status"] = this.dropdown?.status?.filter(
							(status) => status.state_id === this.process_data?.basic_info?.state?.id
						);
						this.dropdown["stage_state_option_map"] = response?.stage_state_option_map || {};
						this.load_inci_resolve = false;
						this.updateStageInputData();
						let signature_list = response?.signature_list;
						this.signatureInputData = this.getSignature(signature_list);
						// IMAP List
						this.imap_list = response?.imap_list;
						this.imapInputData = this.getIMAPConfig(this.imap_list);
						if (response["options"]) {
							const options = response["options"];
							this.module_id = options?.module_id;
							this.incident_module_id = options?.incident_module_id;
							this.dropdown["change_module_id"] = options?.change_module_id;
							this.kb_module_id = options?.kb_module_id;
							this.dropdown["problem_prefix_list"] = options?.PROBLEM_PREFIX_LIST;
							this.dropdown["relation_type"] = options?.relation_type;
							this.dropdown["state"] = options["state"];
							// this.stateStatusMap = options["state_status_map"];
							this.stateKeyIdMap = options["state_key_id_map"];
							this.dropdown["state_status_map"] = options["state_status_map"];
							// this.dropdown["hold_status"] = options["hold_status"];
							this.dropdown["problem_type"] = options["problem_type"];
							this.dropdown["problem_source"] = options["problem_source"];
							this.dropdown["urgency"] = options["urgency"];
							this.dropdown["priority"] = options["priority"];
							this.dropdown["severity"] = options["severity"];
							this.dropdown["event_status"] = options["event_status"];
							this.dropdown["type"] = options["type"];
							this.dropdown["impact"] = options["impact"];
							this.dropdown["tagList"] = options["tags"];
							this.dropdown["tagTypeList"] = options["TAG_TYPE_LIST"];
							this.dropdown["users"] = options["users"];
							this.dropdown["teams"] = options["teams"];
							this.dropdown["state_id_map"] = options["state_id_map"];
							this.dropdown["analysis_technique_list"] = options["analysis_technique_list"];
							this.dropdown["analysis_type_list"] = options["analysis_type_list"];
							this.dropdown["risk_type_list"] = options["risk_type_list"];
							this.dropdown["root_cause_list"] = options["root_cause_list"];
							this.dropdown["solution_type_list"] = options["solution_type_list"];
							this.dropdown["current_user_id"] = response?.current_user_id;
							if (
								this.process_data?.current_assignment_info &&
								this.process_data?.current_assignment_info?.group === undefined &&
								this.process_data?.current_assignment_info?.assignee !== undefined
							) {
								this.team_id = "self_assign";
								this.selfAssignSel = true;
							}
							// if (options.teams && options.current_user_group.length > 0) {
							this.dropdown["teams_and_self_assign"] = [
								{ team_id: "self_assign", name: "Self Assign" },
							].concat(this.dropdown["teams"]);
							// } else {
							// 	this.dropdown["teams_and_self_assign"] = this.dropdown["teams"];
							// }
							this.dropdown["cc_address"] = [
								{
									full_name: this.process_data?.requester?.unmasked_full_name,
									email: this.process_data?.requester?.unmasked_email,
									avatar_color: this.process_data?.requester?.avatar_color,
									profile_image: this.process_data?.requester_profile,
								},
							];
							this.dropdown["to_address"] = [
								{
									full_name: this.process_data?.requester?.unmasked_full_name,
									email: this.process_data?.requester?.unmasked_email,
									avatar_color: this.process_data?.requester?.avatar_color,
									profile_image: this.process_data?.requester_profile,
								},
							];
							this.dropdown["problem_type"] = options["problem_type"];
							this.dropdown["problem_source"] = options["problem_source"];
							this.dropdown["groupt_type_list"] = options["groupt_type_list"];
							this.dropdown["incident_source"] = options["incident_source"];
							this.dropdown["attach_category_list"] = options["attach_category_list"];
							this.dropdown["attach_filter_category_list"] = [
								{
									name: this._translateService.instant("UI.k_all"),
									id: "all",
								},
							];
							this.dropdown["current_user_group"] = options["current_user_group"];
							const imageDefaults = options["image_upload_defaults"];
							this.ALLOWED_TYPES = imageDefaults["allowed_types"];
							this.MAX_IMAGE_SIZE = imageDefaults["max_size"];
							this.MAX_HEIGHT = imageDefaults["max_height"];
							this.MAX_WIDTH = imageDefaults["max_width"];
							this.dropdown["close_type"] = options["close_type"];
							this.dropdown["closure_category_list"] = options["closure_category"];
							this.dropdown["team_user_profile_map"] = options["team_user_profile_map"];
							options.attach_category_list?.forEach((opt_obj) => {
								this.dropdown.attach_filter_category_list.push(opt_obj);
							});
							// this.dropdown["formatted_services"] = [];
							// response?.formatted_services.forEach((ele) => {
							// 	const data = new TreeviewItem(ele);
							// 	this.dropdown["formatted_services"].push(data);
							// });
							// const class_maps = _.cloneDeep(this.dropdown?.service_category_classification_map);
							// if (class_maps) {
							// 	for (const classs_map in class_maps) {
							// 		this.dropdown["service_category_classification_map"][classs_map][
							// 			"formatted_classification_data"
							// 		] = [];
							// 		class_maps[classs_map]["formatted_classification_data"].forEach((ele) => {
							// 			const data = new TreeviewItem(ele);
							// 			this.dropdown["service_category_classification_map"][classs_map][
							// 				"formatted_classification_data"
							// 			].push(data);
							// 		});
							// 	}
							// }
						}
						this.getRequesterHistory()
						this.dataLoaded = true;
						// let editIncident  = this._incidentViewService.getIncidentSidebar();
						// console.log("jjjjjjjj",editIncident,this.hideIncidentInlineEdit,this.extra_info);

						// if(editIncident === true) {
						// 	this.process_extra_info["selected_assignee_info"] = this.extra_info["selected_assignee_info"];
						// 	this.process_extra_info["custom_field_config"] = this.custom_field_config;
						// 	this.process_extra_info["group_type"] = this.extra_info["group_type"];
						// 	this._coreSidebarService.getSidebarRegistry('incident-edit-sidebar').toggleOpen();
						// 	this.hideIncidentInlineEdit = true;
						// 	console.log("lllllll",this.hideIncidentInlineEdit);

						// }
					}
				},
				(error) => {
					// todo
				}
			)
		)
		.subscribe();
}
getProblemTeamUser = (teamId) => {
	this._problemViewService
	.getTeamUserMap({ team_id: teamId })
	.subscribe((response) => {
		this.dropdown["team_user_profile_map"] = response;
	});
}
getProblemAttachmentDetail = (id) => {
	const payload = { problem_id: id };
	this._problemViewService.getAttachmentDetails(payload).subscribe((response) => {
		this.allAttachments = response;
		this.selectAttachmentCategory({ id: this.selected_attachment_id, name: this.selected_attachment_name });
	});
}
getProblemHistoryDetail = (id) => {
	const payload = { problem_id: id };
	this._problemViewService.getHistoryDetails(payload).subscribe((response) => {
		this.historyDataset = response;
		this.historydup = this.historyDataset;
		this.formatHistoryData();
	});
}
getProblemAllKBs = (id) => {
	const payload = { problem_id: id };
	this._problemViewService.getAllKBs(payload).subscribe((response) => {
		this.allKBs = response;
		this.filteredKBs = response;
		for (let i = 0; i < this.allKBs.length; i++) {
			this.allKBs[i].content = this.allKBs[i].content.replace(/<img[^>]*>/g, "");
			this.filteredKBs[i].content = this.filteredKBs[i].content.replace(/<img[^>]*>/g, "");
		}
		
	});
}
getProblemRelation = (relId) => {
	const payload = {
		relation_id: relId,
		ref_id: this.processID,
	};
	this._problemViewService
		.processProblemRelations(payload)
		.pipe(
			map((response: any) => {
				this.incidentRelations = response?.process_relation_data;
				this.allRelations = response?.process_relation_data;
				this.problemProcessMapping = response?.problem_mapping;
				this.incidentMapping = response?.relation_mapping;
				this.filterRelations();
			})
		)
		.subscribe();
}
getProblemRichTextData = () => {
	const payload = {
		ref_id: this.processID,
	};
	this._problemViewService.getRichTextData(payload).subscribe((response) => {
		this.rich_text_list = response;
		for (const idx in this.rich_text_list) {
			this.rich_text_list[idx]["without_dom_sanitizer_descr"] = this.rich_text_list[idx]["description"];
			this.rich_text_list[idx]["description"] = this._domSanitizerService.bypassSecurityTrustHtml(
				this.rich_text_list[idx]["description"]
			);
		}
	});
}
getProblemMailConversations = () => {
	const payload = {
		page: this.page,
		items_per_page: this.selectedOption,
		ref_id: this.processID,
		comments: this.showComments,
		emails: this.showEmails,
		sms: this.showSMS,
		comment_type: this.internalNoteFilterSelection,
	};
	this._problemViewService.getMailConversations(payload).subscribe((response) => {
		if (response) {
			this.mail_list = response?.data;
			this.copyOfAllCard = response?.data;
			this.latestEmail = response?.latest_email;
			this.finished = false;
			if (this.mail_list.length === 0) {
				this.notEmptyCommData = false;
				this.finished = true;
			}
			this.finished = true;
			this.notScrolly = true;
			// this.mail_list.sort(function (a, b) {
			// 	return a.creation_time_int - b.creation_time_int
			//   })
			this.commDataCount = this.mail_list.length;
			this.commDataTotalCount = response["count"];
			setTimeout(() => {
				this.scrolltop = this.scrollMe?.nativeElement.scrollHeight + 100;
			}, 0);
		}
	});
}
convertProblemToKB = (kbID) => {
	const payload = {
		problem_id: this.processID,
	};
	this._problemViewService.convertProblemToKB(kbID, payload).subscribe(
		(response) => {
			this.getProcessData();
			// if (response["id"]) this.isConvertedToKB = true;
		},
		(error) => {
			// todo
		}
	);
}
getProblemUserProfile = () => {
	this._problemViewService
		.getUserProfile(this.currentAssignmentInfo["assignee"])
		.pipe(
			map((response: any) => {
				if (response) {
					this.current_extra_info["selected_assignee_info"] = response;
					this.currentAssignmentInfo["assignee_profile"] = response;
				}
				// Success
			}),
			catchError((error) => {
				return throwError(error.statusText);
			})
		)
		.subscribe();
}
saveProblemConversation = (messageParam, files) => {
	this._problemViewService.saveConversation(messageParam).subscribe((response) => {
		if (response["status"] === "success") {
			this.isOpenCC = false;
			this.isOpenBCC = false;
			this._toastr.success(
				this._translateService.instant(response["message"]),
				this._translateService.instant("Message.msg_save_success"),
				{
					toastClass: "toast ngx-toastr",
					closeButton: true,
					positionClass: "toast-top-right",
				}
			);
			this.page = 1;
			this.commDataTotalCount = 0;
			this.getMailConversations('getMailConversations');
			this.showReplyBox = true;
			this.isEmailCompOpen = false;
			setTimeout(() => {
				this.scrolltop = this.scrollMe?.nativeElement.scrollHeight + 100;
			}, 0);
			if (files) {
				this.loadAttachments("");
			}
		} else if (response["status"] === "error") {
			this._toastr.error(this._translateService.instant(response["message"]), "Error", {
				toastClass: "toast ngx-toastr",
				closeButton: true,
				positionClass: "toast-top-right",
			});
		}
	});
}
getProblemApprovalStatus = (editerId) => {
	this._problemViewService
	.getApprovalStatus({
		problem_id: this.processID,
	})
	.subscribe((res) => {
		if (res?.data?.is_approval_completed !== true) {
			this.dnsCommunication.enableEditor("approvealSet", undefined);
			if(editerId){
				this.editorId = editerId;
			}
			this.showEditor = true;
			this.dropdown["filtered_status"] = this.dropdown?.transition_status?.filter((e) => {
				return e["state_id"] === this.stateKeyIdMap["Approval"];
			});
			this.process_data.basic_info.state["id"] = this.dropdown?.filtered_status?.[0]["state_id"];
			this.process_data.basic_info.status["id"] = this.dropdown?.filtered_status?.[0]["id"];
		} else {
			this._toastr.error("Error", this._translateService.instant(res?.message));
		}
	});
}
getProblemRefMailConversations = (evt) => {
	this._problemViewService.getMailConversations(evt.payload).subscribe((response) => {
		if (response) {
			this.mail_list = response?.data;
			this.mail_list?.forEach((element) => {
				element.content = element.content.replace(/<a id='ticketURL'.*?<\/a>/i, "");
			});
			this.copyOfAllCard = response?.data;
			this.latestEmail = response?.latest_email;
			this.finished = false;
			if (this.mail_list.length === 0) {
				this.notEmptyCommData = false;
				this.finished = true;
			}
			this.finished = true;
			this.notScrolly = true;
			// this.mail_list.sort(function (a, b) {
			// 	return a.creation_time_int - b.creation_time_int
			//   })
			this.commDataCount = this.mail_list.length;
			this.commDataTotalCount = response["count"];
			this.dnsCommunication.handleMailConversionResponse({ response, ...evt });
		}
		// if (response) {
		// 	this.mail_list = response?.data;
		// 	this.mail_list?.forEach((element) => {
		// 		element.content = element.content.replace(/<a id='ticketURL'.*?<\/a>/i, "");
		// 	});
		// 	this.copyOfAllCard = response?.data;
		// 	this.latestEmail = response?.latest_email;
		// 	this.finished = false;
		// 	if (this.mail_list.length === 0) {
		// 		this.notEmptyCommData = false;
		// 		this.finished = true;
		// 	}
		// 	this.finished = true;
		// 	this.notScrolly = true;
		// 	// this.mail_list.sort(function (a, b) {
		// 	// 	return a.creation_time_int - b.creation_time_int
		// 	//   })
		// 	this.commDataCount = this.mail_list.length;
		// 	this.commDataTotalCount = response["count"];
		// 	setTimeout(() => {
		// 		this.scrolltop = this.scrollMe?.nativeElement.scrollHeight + 100;
		// 	}, 0);
		// }
	});
}
getProblemRequesterHistory = () => {
	let palyload = {
		requester_id: this.process_data?.requester?.requester_id,
		requester_email: this.process_data?.requester?.email,
	};
	this._problemViewService.getReqLatestProblems(palyload).subscribe((response) => {
		this.requester_history = response?.requester_info;
		this.requester_history_dup = response?.requester_info;
		if (this.displayAllInteraction && this.requester_history_dup.length !== 0) {
			this.requester_history = [];
			for (let i = 0; i < 2; i++) {
				if (this.requester_history_dup[i]) {
					this.requester_history.push(this.requester_history_dup[i]);
				}
			}
		}
	});
}
getProblemChange = (change_id) => {
	this._problemViewService
	.getChange(change_id, { return_main_info: true })
	.pipe(
		map(
			(response) => {
				this.change_data = response?.change;
			},
			(error) => {
				//todo
			}
		)
	)
	.subscribe();
}
converProblemToChange = (change_obj) => {
	let payload = {
		problem_id: this.process_data?.problem_id,
		change_display_id: change_obj?.display_id,
	};
	this._problemViewService.convertProblemToChange(change_obj?.id, payload).subscribe(
		(response) => {
			this.getProcessData();
			this.getChangeDetails(change_obj?.id);
			this.getRelations(this.moduleName);
		},
		(error) => {
			// todo
		}
	);
}
getProblemServiceBaseWorkflow = (selected_service) => {
	this.is_service_change = true;
	this._problemViewService
		.getServiceBaseWorkflowStatus({ service_id: selected_service.value })
		.subscribe((res) => {
			this.prepareServiceChangeData(res, selected_service);
		});
}
getProblemRequesterImpactService = () => {
	this._problemViewService
	.getRequesterImpactService({
		requester_id: this.process_data.requester?.requester_id,
		is_published: true,
		bind_classification: false,
		module_name_list: JSON.stringify(["incident"]),
	})
	.subscribe(
		(res) => {
			this.service_config["is_editable"] = true;
			if (this.process_data?.basic_info?.status?.state_id === 6 && this.permissions?.edit) {
				this.service_config["is_editable"] = false;
				this.service_config["is_loading"] = false;
			}else if(this.process_data?.basic_info?.status?.state_id === 5 && this.permissions?.edit){
				this.service_config["is_editable"] = false;
				this.service_config["is_loading"] = false;
			}else if(!this.permissions?.edit){
				this.service_config["is_editable"] = false;
				this.service_config["is_loading"] = false;
			}
			this.dropdown["formatted_services"] = [];
			res?.forEach((ele) => {
				const data = new TreeviewItem(ele);
				this.dropdown["formatted_services"].push(data);
			});
			this.service_config["is_loading"] = false;
		},
		(error) => {
			console.log("error_while_fetching impact service", error);
			this.service_config["is_loading"] = false;
		}
	);
}
getProblemImpactServiceClassification = () => {
	this._problemViewService
	.getImpactServiceClassification({
		service_id: this.process_data.basic_info?.impact_service,
		format_to_treeview: true,
	})
	.subscribe(
		(res) => {
			this.classification_config["is_editable"] = true;
			if (this.process_data?.basic_info?.status?.state_id === 6 && this.permissions?.edit) {
				this.classification_config["is_editable"] = false;
				this.classification_config["is_loading"] = false;
			}else if(this.process_data?.basic_info?.status?.state_id === 5 && this.permissions?.edit){
				this.classification_config["is_editable"] = false;
				this.classification_config["is_loading"] = false;
			}else if(!this.permissions?.edit){
				this.classification_config["is_editable"] = false;
				this.classification_config["is_loading"] = false;
			}
			this.dropdown["formatted_classification_data"] = [];
			res?.classification?.forEach((ele) => {
				const data = new TreeviewItem(ele);
				this.dropdown["formatted_classification_data"].push(data);
			});
			this.classification_config["is_loading"] = false;
		},
		(error) => {
			console.log("error_while_fetching impact service", error);
			this.classification_config["is_loading"] = false;
		}
	);
}
// updateProblemMsgReadStatus = (msg_obj) => {
// 	if (this.isLoggedInUserAssignee()) {
// 		msg_obj["is_assignee"] = true;
// 	}
// 	this._problemViewService.updateMsgReadStatus(msg_obj).subscribe(
// 		(response) => {
// 			if (response["status"] === "success") {
// 				this._toastr.success(
// 					this._translateService.instant(response["message"]),
// 					this._translateService.instant("Message.msg_success"),
// 					{
// 						toastClass: "toast ngx-toastr",
// 						closeButton: true,
// 						positionClass: "toast-top-right",
// 					}
// 				);
// 				this.dnsCommunication.resetCommunicationData();
// 			} else if (response["status"] === "failed") {
// 				this._toastr.error(
// 					this._translateService.instant(response["message"]),
// 					this._translateService.instant("Error.err_fail"),
// 					{
// 						toastClass: "toast ngx-toastr",
// 						closeButton: true,
// 						positionClass: "toast-top-right",
// 					}
// 				);
// 			}
// 		},
// 		(error) => {
// 			// todo
// 		}
// 	);
// }
saveProblemRichTextData = (payload) => {
	this._problemViewService.saveRichTextData(payload).subscribe((response) => {
		if (response["status"] === "success") {
			this._toastr.success(
				this._translateService.instant(response["message"]),
				this._translateService.instant("Message.msg_save_success"),
				{
					toastClass: "toast ngx-toastr",
					closeButton: true,
					positionClass: "toast-top-right",
				}
			);
			this.reloadCommunicationData();
			this.reloadCommunication = true;
			this.dnsCommunication.resetCommunicationData();
		} else {
			this._toastr.error(
				this._translateService.instant(response["message"]),
				this._translateService.instant("Error.err_save_failed"),
				{
					toastClass: "toast ngx-toastr",
					closeButton: true,
					positionClass: "toast-top-right",
				}
			);
		}
		this.disableCommentSubmit = false;
	});
}
updateProblemApprovalData = (processID, process_data, otherAction) => {
	this._problemViewService
	.editProblem(processID, process_data)
	.pipe(
		map((response: any) => {
			if (response) {
				this._modalService.dismissAll();
				this.selfAssignSel = false;
				this.getProcessData();
				this.dnsCommunication.resetCommunicationData();
				if(otherAction === 'Approval'){
					this.reloadCommunicationData();
					this.disableCommentSubmit = false;
				}
			} else {
				this._toastr.error(
					this._translateService.instant(response["message"]),
					this._translateService.instant("Error.err_save_failed"),
					{
						toastClass: "toast ngx-toastr",
						closeButton: true,
						positionClass: "toast-top-right",
					}
				);
				this.disableCommentSubmit = false;
			}
			// Success
		}),
		catchError((error) => {
			this.disableCommentSubmit = false;

			return throwError(error.statusText);
		})
	)
	.subscribe();
}
deleteProblemAttachment = (attachment_id, payload) => {
	this._problemViewService.deleteAttachment(attachment_id, payload).subscribe(
		(response) => {
			// window.location.reload();
			this.getAttachmentDetails(this.processID, 'getAttachmentDetails');
			this.getHistoryDetails(this.processID, 'getHistoryDetails');
			if (response["status"] === "success") {
				this._toastr.success(
					this._translateService.instant(response["message"]),
					this._translateService.instant("Message.msg_success"),
					{
						toastClass: "toast ngx-toastr",
						closeButton: true,
						positionClass: "toast-top-right",
					}
				);
			} else if (response["status"] === "failed") {
				this._toastr.error(
					this._translateService.instant(response["message"]),
					this._translateService.instant("Error.err_fail"),
					{
						toastClass: "toast ngx-toastr",
						closeButton: true,
						positionClass: "toast-top-right",
					}
				);
			}
		},
		(error) => {
			// todo
		}
	);
}
convertProblemToIncident = (inci_obj) => {
	let payload = {
		problem_id: this.process_data?.problem_id,
		incident_display_id: inci_obj?.display_id,
	};
	this._problemViewService.convertProblemToIncident(inci_obj?.id, payload).subscribe(
		(response) => {
			this.getProcessData();
			this.getIncidentDetails(inci_obj?.id);
			this.getRelations(this.moduleName);
		},
		(error) => {
			// todo
		}
	);
}
getProblemIncident = (incident_id) => {
	this._problemViewService
		.getIncident(incident_id, { return_main_info: true })
		.pipe(
			map(
				(response) => {
					this.incident_data = response?.incident;
				},
				(error) => {
					//todo
				}
			)
		)
		.subscribe();
};
getFaultSymptomsTextData = (event) => {
	this.process_data.basic_info.fault_symptoms = event.html;
	this.updateIncident(this.process_data);
};
onProblemSourceChange = (event) => {
	this.process_data.basic_info.problem_source = this.process_data_copy.basic_info.problem_source;
	this.problemEdit(this.process_data);
}
knownErrorChange = (event) => {
	this.process_data.is_known_error_enable = this.process_data_copy.is_known_error_enable;
	this.problemEdit(this.process_data);
}
toggleSolutionSideBar = (name: string, id: any, type: any) => {
	this.hideProblemSolutionSideBar = false;
	this.current_root_cause_id = "-1";
	this.current_root_cause_type = type;
	if (id != undefined) {
		this.current_root_cause_id = id;
	}
	this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
}
toggleAnalysisSideBar = (name: string, id: any, type: any) => {
	this.hideProblemSideBar = false;
	this.current_root_cause_id = "-1";
	this.current_root_cause_type = type;
	if (id != undefined) {
		this.current_root_cause_id = id;
	}
	this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
}
hideAnaysisModal($event) {
	this.hideProblemSideBar = true;
	this.getProblemData();
}

toggleAnalysisDraftSideBar = (name: string) => {
	this.hideProblemDraftSideBar = false;
	this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
};

hideAnaysisDraftModal($event) {
	this.hideProblemDraftSideBar = true;
	this.getProblemData();
}
hideSolnModal($event) {
	this.hideProblemSolutionSideBar = true;
	if ($event && 
		this.process_data?.is_known_error_enable != undefined && 
		$event?.is_known_error_enable != undefined
	){
		if (this.process_data?.is_known_error_enable != $event?.is_known_error_enable){
			this.problemEdit($event)
		}
	}
	this.getProblemData();
}
// End of Request Action List //

// Change Action List //
changeEdit = (payload, from_close_request = false, from_assign = false) => {
	if (payload?.due_date instanceof Array) {
		payload.due_date = payload.due_date[0];
	}
	this._changeViewService
		.editChange(this.processID, payload)
		.pipe(
			map((response: any) => {
				if (response) {
					if (from_close_request) {
						this.disableCommentSubmit = true;
					}
					this._toastr.success(
						this._translateService.instant(response["message"]),
						this._translateService.instant("Message.msg_save_success"),
						{
							toastClass: "toast ngx-toastr",
							closeButton: true,
							positionClass: "toast-top-right",
						}
					);
					if (this.reloadCommunication) {
						this.reloadCommunicationData();
						this.reloadCommunication = false;
					}
					this._modalService.dismissAll();
					this.selfAssignSel = false;
					this.service_config = {}
					this.classification_config = {}
					this.getProcessData();
					this.getHistoryDetails(this.processID, 'getHistoryDetails');
					this.process_data_copy = cloneDeep(this.process_data);
					this.isStageDataLoaded = true;
				} else {
					if (from_assign) {
						this.disableCommentSubmit = false;
					}
					if (from_assign) {
						this.asgnDisabled = false;
					}
					this._toastr.error(
						this._translateService.instant(response["message"]),
						this._translateService.instant("Error.err_save_failed"),
						{
							toastClass: "toast ngx-toastr",
							closeButton: true,
							positionClass: "toast-top-right",
						}
					);
				}
				// Success
			}),
			catchError((error) => {
				if (from_close_request) {
					this.disableCommentSubmit = false;
				}
				if (from_assign) {
					this.asgnDisabled = false;
				}
				return throwError(error.statusText);
			})
		)
		.subscribe();
}
getChangeData = () => {
	this._changeViewService
		.getChange(this.processID)
		.pipe(
			map(
				(response) => {
					if (response) {
						this.process_data = response?.change;
						this.escalataionData = response?.escalation_data;
						this.process_data_copy = cloneDeep(this.process_data);
						this.sourceID = this.process_data?.basic_info?.change_source.id;
						if (
							this.process_data?.basic_info?.change_type?.id ==
								this.dropdown?.CHANGE_MAJOR_CHANGE_TYPE ||
							this.process_data?.basic_info?.change_type?.id == this.dropdown?.CHANGE_MINOR_CHANGE_TYPE
						) {
							this.showImpact = true;
							this.showRisk = true;
						} else {
							this.showImpact = false;
							this.showRisk = false;
						}
						if (this.process_data?.due_date) {
							this.plnStartDateOptions["maxDate"] = this.process_data?.due_date;
							this.plnEndDateOptions["maxDate"] = this.process_data?.due_date;
						}
						if (this.process_data?.basic_info?.plan_start_date) {
							this.plnEndDateOptions["minDate"] = this.process_data?.basic_info?.plan_start_date;
						}
						if (this.process_data?.basic_info?.plan_end_date) {
							this.plnStartDateOptions["maxDate"] = this.process_data?.basic_info?.plan_end_date;
						}
						if(this.process_data?.basic_info?.change_type?.name === "Standard"){
							this.configuration_list = this.configuration_list.filter((f) => f.handle !== 'sla' && f.handle !== 'analysis_and_solutions' && f.handle !== 'risk');
							if(this.handelType){
								this.selectedItem = this.configuration_list.findIndex((f) => f.handle === this.handelType)
								this.menuClick(this.handelType, this.selectedItem, this.configuration_list[this.selectedItem])
								this.handelType = null
							}
						}else{
							this.configuration_list = this.configuration_list.filter((f) => f.handle !== 'sla' && f.handle !== 'analysis_and_solutions');
							if(this.handelType){
								this.selectedItem = this.configuration_list.findIndex((f) => f.handle === this.handelType)
								this.menuClick(this.handelType, this.selectedItem, this.configuration_list[this.selectedItem])
								this.handelType = null
							}
						}
						if (this.module_permissions?.["BASE"]?.["KNOWLEDGE_BASE"]) {
							this.getAllKB(this.process_data_copy?.basic_info?.summary);
						}
						if (this.process_data?.basic_info?.state?.id < 4) {
							this.process_data["inci_resolution_time"] = null;
						}
						// this.disable_config = false;
						if ([6, 7].includes(this.process_data?.basic_info?.status?.state_id) && this.permissions?.edit) {
							this.disable_status_config = true;
							this.disable_config = true;
						}else if(this.process_data?.basic_info?.status?.state_id === 5 && this.permissions?.edit){
							this.disable_config = true;
						}
						this.currentUserProfileId = response?.current_user_id;
						this.team_id = response?.change?.current_assignment_info?.group;
						this.custom_field_config = response?.custom_field_config;
						this.custom_workflow_fields = [];
						for (let i = 0; i < this.custom_field_config?.custom_fields?.length; i++) {
							const each_custom_data = this.custom_field_config.custom_fields[i];
							if (this.custom_workflow_fields[each_custom_data.section] === undefined) {
								this.custom_workflow_fields[each_custom_data.section] = [];
							}
							this.custom_workflow_fields[each_custom_data.section].push(each_custom_data);
						}
						this.custom_field_data = response?.change?.custom_field_data;
						// this.requester_latest_changes = response?.requester_latest_changes;
						// this.next_change_id = response?.change?.next_change_id;
						// this.prev_change_id = response?.change?.prev_change_id;
						// this.next_incident_id = response?.incident?.next_incident_id;
						// this.prev_incident_id = response?.incident?.prev_incident_id;

						this.dropdown["expertise"] = response?.expertise_list;
						this.dropdown["level"] = response?.level_list;
						this.dropdown["assignee"] = response["assignee_list"];
						this.dropdown["transition_status"] = response["transition_status"];
						this.process_data_copy["expertise_list"] = response?.expertise_list;
						this.process_data_copy["level_list"] = response?.level_list;
						this.process_data_copy["assignee_list"] = response?.assignee_list;
						this.extra_info["selected_assignee_info"] = response?.selected_assignee_info;
						this.process_extra_info["selected_assignee_info"] = this.extra_info?.selected_assignee_info;
						this.extra_info["custom_field_config"] = this.custom_field_config;
						this.extra_info["group_name"] = "";
						this.extra_info["level"] = "";
						this.extra_info["expertise"] = "";
						this.extra_info["level_id"] = "";
						this.extra_info["expertise_id"] = "";
						if (response["group_type"]) {
							this.extra_info["group_type"] = response["group_type"];
						}
						if (this.process_data?.current_assignment_info !== undefined) {
							this.prev_current_assignment_info = this.process_data.current_assignment_info;
							if (this.process_data["current_assignment_info"]["group_name"]) {
								this.extra_info["group_name"] =
									this.process_data["current_assignment_info"]["group_name"];
							}

							if (this.process_data["current_assignment_info"]["level"]) {
								this.extra_info["level"] = this.process_data["current_assignment_info"]["level"];
							}
							if (this.process_data["current_assignment_info"]["expertise"]) {
								this.extra_info["expertise"] =
									this.process_data["current_assignment_info"]["expertise"];
							}
							if (this.process_data["current_assignment_info"]["level_id"]) {
								this.extra_info["level_id"] =
									this.process_data["current_assignment_info"]["level_id"];
							}
							if (this.process_data["current_assignment_info"]["expertise_id"]) {
								this.extra_info["expertise_id"] =
									this.process_data["current_assignment_info"]["expertise_id"];
							}
							this.commonActionMap[this.moduleName]['getTeamUser'](this.process_data?.current_assignment_info?.group)
							// this._incidentViewService
							// 	.getTeamUserMap({ team_id: this.process_data?.current_assignment_info?.group })
							// 	.subscribe((response) => {
							// 		this.dropdown["team_user_profile_map"] = response;
							// 	});
						}
						this.dropdown["services"] = response["service_list"];
						this.dropdown["status"] = response?.status;
						this.dropdown["filtered_status"] = this.dropdown?.status?.filter(
							(status) => status.state_id === this.process_data?.basic_info?.state?.id
						);
						this.dropdown["stage_state_option_map"] = response?.stage_state_option_map || {};
						this.load_inci_resolve = false;
						this.updateStageInputData();
						let signature_list = response?.signature_list;
						this.signatureInputData = this.getSignature(signature_list);
						// IMAP List
						this.imap_list = response?.imap_list;
						this.imapInputData = this.getIMAPConfig(this.imap_list);
						if (response["options"]) {
							const options = response["options"];
							this.module_id = options?.module_id;
							this.incident_module_id = options?.incident_module_id;
							this.dropdown["change_module_id"] = options?.change_module_id;
							this.kb_module_id = options?.kb_module_id;
							this.dropdown["change_prefix_list"] = options?.PROBLEM_PREFIX_LIST;
							this.dropdown["relation_type"] = options?.relation_type;
							this.dropdown["state"] = options["state"];
							this.dropdown["risk"] = options["risk"];
							this.dropdown["risk_type"] = options["risk_type"];
							this.dropdown["task_status"] = options["task_status"];
							// this.stateStatusMap = options["state_status_map"];
							this.stateKeyIdMap = options["state_key_id_map"];
							this.dropdown["state_status_map"] = options["state_status_map"];
							// this.dropdown["hold_status"] = options["hold_status"];
							this.dropdown["change_type"] = options["change_type"];
							this.dropdown["change_source"] = options["change_source"];
							this.dropdown["urgency"] = options["urgency"];
							this.dropdown["priority"] = options["priority"];
							this.dropdown["severity"] = options["severity"];
							this.dropdown["event_status"] = options["event_status"];
							this.dropdown["type"] = options["type"];
							this.dropdown["impact"] = options["impact"];
							this.dropdown["tagList"] = options["tags"];
							this.dropdown["tagTypeList"] = options["TAG_TYPE_LIST"];
							this.dropdown["users"] = options["users"];
							this.dropdown["teams"] = options["teams"];
							this.dropdown["state_id_map"] = options["state_id_map"];
							this.dropdown["analysis_technique_list"] = options["analysis_technique_list"];
							this.dropdown["analysis_type_list"] = options["analysis_type_list"];
							this.dropdown["risk_type_list"] = options["risk_type_list"];
							this.dropdown["root_cause_list"] = options["root_cause_list"];
							this.dropdown["solution_type_list"] = options["solution_type_list"];
							this.dropdown["current_user_id"] = response?.current_user_id;
							if (
								this.process_data?.current_assignment_info &&
								this.process_data?.current_assignment_info?.group === undefined &&
								this.process_data?.current_assignment_info?.assignee !== undefined
							) {
								this.team_id = "self_assign";
								this.selfAssignSel = true;
							}
							// if (options.teams && options.current_user_group.length > 0) {
							this.dropdown["teams_and_self_assign"] = [
								{ team_id: "self_assign", name: "Self Assign" },
							].concat(this.dropdown["teams"]);
							options?.users.forEach((element) => {
								this.dropdown.user_profile_map[element.profile_id] = element;
							});
							this.dropdown["requesters"] = options["requesters"];
							// } else {
							// 	this.dropdown["teams_and_self_assign"] = this.dropdown["teams"];
							// }
							this.dropdown["cc_address"] = [
								{
									full_name: this.process_data?.requester?.unmasked_full_name,
									email: this.process_data?.requester?.unmasked_email,
									avatar_color: this.process_data?.requester?.avatar_color,
									profile_image: this.process_data?.requester_profile,
								},
							];
							this.dropdown["to_address"] = [
								{
									full_name: this.process_data?.requester?.unmasked_full_name,
									email: this.process_data?.requester?.unmasked_email,
									avatar_color: this.process_data?.requester?.avatar_color,
									profile_image: this.process_data?.requester_profile,
								},
							];
							this.dropdown["change_type"] = options["change_type"];
							this.dropdown["change_source"] = options["change_source"];
							this.dropdown["groupt_type_list"] = options["groupt_type_list"];
							this.dropdown["incident_source"] = options["incident_source"];
							this.dropdown["attach_category_list"] = options["attach_category_list"];
							this.dropdown["attach_filter_category_list"] = [
								{
									name: this._translateService.instant("UI.k_all"),
									id: "all",
								},
							];
							this.dropdown["current_user_group"] = options["current_user_group"];
							const imageDefaults = options["image_upload_defaults"];
							this.ALLOWED_TYPES = imageDefaults["allowed_types"];
							this.MAX_IMAGE_SIZE = imageDefaults["max_size"];
							this.MAX_HEIGHT = imageDefaults["max_height"];
							this.MAX_WIDTH = imageDefaults["max_width"];
							this.dropdown["close_type"] = options["close_type"];
							this.dropdown["closure_category_list"] = options["closure_category"];
							this.dropdown["team_user_profile_map"] = options["team_user_profile_map"];
							options.attach_category_list?.forEach((opt_obj) => {
								this.dropdown.attach_filter_category_list.push(opt_obj);
							});
							this.dropdown["CHANGE_MAJOR_CHANGE_TYPE"] = options?.CHANGE_MAJOR_CHANGE_TYPE;
							this.dropdown["CHANGE_MINOR_CHANGE_TYPE"] = options?.CHANGE_MINOR_CHANGE_TYPE;

							if (
								this.process_data?.basic_info?.change_type?.id ==
									this.dropdown?.CHANGE_MAJOR_CHANGE_TYPE ||
								this.process_data?.basic_info?.change_type?.id ==
									this.dropdown?.CHANGE_MINOR_CHANGE_TYPE
							) {
								this.showImpact = true;
								this.showRisk = true;
							} else {
								this.showImpact = false;
								this.showRisk = false;
							}
							// this.dropdown["formatted_services"] = [];
							// response?.formatted_services.forEach((ele) => {
							// 	const data = new TreeviewItem(ele);
							// 	this.dropdown["formatted_services"].push(data);
							// });
							// const class_maps = _.cloneDeep(this.dropdown?.service_category_classification_map);
							// if (class_maps) {
							// 	for (const classs_map in class_maps) {
							// 		this.dropdown["service_category_classification_map"][classs_map][
							// 			"formatted_classification_data"
							// 		] = [];
							// 		class_maps[classs_map]["formatted_classification_data"].forEach((ele) => {
							// 			const data = new TreeviewItem(ele);
							// 			this.dropdown["service_category_classification_map"][classs_map][
							// 				"formatted_classification_data"
							// 			].push(data);
							// 		});
							// 	}
							// }
						}
						this.getRequesterHistory()
						this.dataLoaded = true;
						// let editIncident  = this._incidentViewService.getIncidentSidebar();
						// console.log("jjjjjjjj",editIncident,this.hideIncidentInlineEdit,this.extra_info);

						// if(editIncident === true) {
						// 	this.process_extra_info["selected_assignee_info"] = this.extra_info["selected_assignee_info"];
						// 	this.process_extra_info["custom_field_config"] = this.custom_field_config;
						// 	this.process_extra_info["group_type"] = this.extra_info["group_type"];
						// 	this._coreSidebarService.getSidebarRegistry('incident-edit-sidebar').toggleOpen();
						// 	this.hideIncidentInlineEdit = true;
						// 	console.log("lllllll",this.hideIncidentInlineEdit);

						// }
					}
				},
				(error) => {
					// todo
				}
			)
		)
		.subscribe();
}
getChangeTeamUser = (teamId) => {
	this._changeViewService
	.getTeamUserMap({ team_id: teamId })
	.subscribe((response) => {
		this.dropdown["team_user_profile_map"] = response;
	});
}
getChangeAttachmentDetail = (id) => {
	const payload = { change_id: id };
	this._changeViewService.getAttachmentDetails(payload).subscribe((response) => {
		this.allAttachments = response;
		this.selectAttachmentCategory({ id: this.selected_attachment_id, name: this.selected_attachment_name });
	});
}
getChangeHistoryDetail = (id) => {
	const payload = { change_id: id };
	this._changeViewService.getHistoryDetails(payload).subscribe((response) => {
		this.historyDataset = response;
		this.historydup = this.historyDataset;
		this.formatHistoryData();
	});
}
getChangeAllKBs = (id) => {
	const payload = { change_id: id };
	this._changeViewService.getAllKBs(payload).subscribe((response) => {
		this.allKBs = response;
		this.filteredKBs = response;
		for (let i = 0; i < this.allKBs.length; i++) {
			this.allKBs[i].content = this.allKBs[i].content.replace(/<img[^>]*>/g, "");
			this.filteredKBs[i].content = this.filteredKBs[i].content.replace(/<img[^>]*>/g, "");
		}
		
	});
}
getChangeRelation = (relId) => {
	const payload = {
		relation_id: relId,
		ref_id: this.processID,
	};
	this._changeViewService
		.processChangeRelations(payload)
		.pipe(
			map((response: any) => {
				this.incidentRelations = response?.process_relation_data;
				this.allRelations = response?.process_relation_data;
				this.changeProcessMapping = response?.change_mapping;
				this.incidentMapping = response?.relation_mapping;
				this.filterRelations();
			})
		)
		.subscribe();
}
getChangeRichTextData = () => {
	const payload = {
		ref_id: this.processID,
	};
	this._changeViewService.getRichTextData(payload).subscribe((response) => {
		this.rich_text_list = response;
		for (const idx in this.rich_text_list) {
			this.rich_text_list[idx]["without_dom_sanitizer_descr"] = this.rich_text_list[idx]["description"];
			this.rich_text_list[idx]["description"] = this._domSanitizerService.bypassSecurityTrustHtml(
				this.rich_text_list[idx]["description"]
			);
		}
	});
}
getChangeMailConversations = () => {
	const payload = {
		page: this.page,
		items_per_page: this.selectedOption,
		ref_id: this.processID,
		comments: this.showComments,
		emails: this.showEmails,
		sms: this.showSMS,
		comment_type: this.internalNoteFilterSelection,
	};
	this._changeViewService.getMailConversations(payload).subscribe((response) => {
		if (response) {
			this.mail_list = response?.data;
			this.copyOfAllCard = response?.data;
			this.latestEmail = response?.latest_email;
			this.finished = false;
			if (this.mail_list.length === 0) {
				this.notEmptyCommData = false;
				this.finished = true;
			}
			this.finished = true;
			this.notScrolly = true;
			// this.mail_list.sort(function (a, b) {
			// 	return a.creation_time_int - b.creation_time_int
			//   })
			this.commDataCount = this.mail_list.length;
			this.commDataTotalCount = response["count"];
			setTimeout(() => {
				this.scrolltop = this.scrollMe?.nativeElement.scrollHeight + 100;
			}, 0);
		}
	});
}
convertChangeToKB = (kbID) => {
	const payload = {
		change_id: this.processID,
	};
	this._changeViewService.convertChangeToKB(kbID, payload).subscribe(
		(response) => {
			this.getProcessData();
			// if (response["id"]) this.isConvertedToKB = true;
		},
		(error) => {
			// todo
		}
	);
}
getChangeUserProfile = () => {
	this._changeViewService
		.getUserProfile(this.currentAssignmentInfo["assignee"])
		.pipe(
			map((response: any) => {
				if (response) {
					this.current_extra_info["selected_assignee_info"] = response;
					this.currentAssignmentInfo["assignee_profile"] = response;
				}
				// Success
			}),
			catchError((error) => {
				return throwError(error.statusText);
			})
		)
		.subscribe();
}
saveChangeConversation = (messageParam, files) => {
	this._changeViewService.saveConversation(messageParam).subscribe((response) => {
		if (response["status"] === "success") {
			this.isOpenCC = false;
			this.isOpenBCC = false;
			this._toastr.success(
				this._translateService.instant(response["message"]),
				this._translateService.instant("Message.msg_save_success"),
				{
					toastClass: "toast ngx-toastr",
					closeButton: true,
					positionClass: "toast-top-right",
				}
			);
			this.page = 1;
			this.commDataTotalCount = 0;
			this.getMailConversations('getMailConversations');
			this.showReplyBox = true;
			this.isEmailCompOpen = false;
			setTimeout(() => {
				this.scrolltop = this.scrollMe?.nativeElement.scrollHeight + 100;
			}, 0);
			if (files) {
				this.loadAttachments("");
			}
		} else if (response["status"] === "error") {
			this._toastr.error(this._translateService.instant(response["message"]), "Error", {
				toastClass: "toast ngx-toastr",
				closeButton: true,
				positionClass: "toast-top-right",
			});
		}
	});
}
getChangeApprovalStatus = (editerId) => {
	this._changeViewService
	.getApprovalStatus({
		change_id: this.processID,
	})
	.subscribe((res) => {
		if (res?.data?.is_approval_completed !== true) {
			this.dnsCommunication.enableEditor("approvealSet", undefined);
			if(editerId){
				this.editorId = editerId;
			}
			this.showEditor = true;
			this.dropdown["filtered_status"] = this.dropdown?.transition_status?.filter((e) => {
				return e["state_id"] === this.stateKeyIdMap["Approval"];
			});
			this.process_data.basic_info.state["id"] = this.dropdown?.filtered_status?.[0]["state_id"];
			this.process_data.basic_info.status["id"] = this.dropdown?.filtered_status?.[0]["id"];
		} else {
			this._toastr.error("Error", this._translateService.instant(res?.message));
		}
	});
}
getChangeRefMailConversations = (evt) => {
	this._changeViewService.getMailConversations(evt.payload).subscribe((response) => {
		if (response) {
			this.mail_list = response?.data;
			this.mail_list?.forEach((element) => {
				element.content = element.content.replace(/<a id='ticketURL'.*?<\/a>/i, "");
			});
			this.copyOfAllCard = response?.data;
			this.latestEmail = response?.latest_email;
			this.finished = false;
			if (this.mail_list.length === 0) {
				this.notEmptyCommData = false;
				this.finished = true;
			}
			this.finished = true;
			this.notScrolly = true;
			// this.mail_list.sort(function (a, b) {
			// 	return a.creation_time_int - b.creation_time_int
			//   })
			this.commDataCount = this.mail_list.length;
			this.commDataTotalCount = response["count"];
			this.dnsCommunication.handleMailConversionResponse({ response, ...evt });
		}
		// if (response) {
		// 	this.mail_list = response?.data;
		// 	this.mail_list?.forEach((element) => {
		// 		element.content = element.content.replace(/<a id='ticketURL'.*?<\/a>/i, "");
		// 	});
		// 	this.copyOfAllCard = response?.data;
		// 	this.latestEmail = response?.latest_email;
		// 	this.finished = false;
		// 	if (this.mail_list.length === 0) {
		// 		this.notEmptyCommData = false;
		// 		this.finished = true;
		// 	}
		// 	this.finished = true;
		// 	this.notScrolly = true;
		// 	// this.mail_list.sort(function (a, b) {
		// 	// 	return a.creation_time_int - b.creation_time_int
		// 	//   })
		// 	this.commDataCount = this.mail_list.length;
		// 	this.commDataTotalCount = response["count"];
		// 	setTimeout(() => {
		// 		this.scrolltop = this.scrollMe?.nativeElement.scrollHeight + 100;
		// 	}, 0);
		// }
	});
}
getChangeRequesterHistory = () => {
	let palyload = {
		requester_id: this.process_data?.requester?.requester_id,
		requester_email: this.process_data?.requester?.email,
	};
	this._changeViewService.getReqLatestChanges(palyload).subscribe((response) => {
		this.requester_history = response?.requester_info;
		this.requester_history_dup = response?.requester_info;
		if (this.displayAllInteraction && this.requester_history_dup.length !== 0) {
			this.requester_history = [];
			for (let i = 0; i < 2; i++) {
				if (this.requester_history_dup[i]) {
					this.requester_history.push(this.requester_history_dup[i]);
				}
			}
		}
	});
}
getChangeChange = (change_id) => {
	this._changeViewService
	.getChange(change_id, { return_main_info: true })
	.pipe(
		map(
			(response) => {
				this.change_data = response?.change;
			},
			(error) => {
				//todo
			}
		)
	)
	.subscribe();
}

getChangeServiceBaseWorkflow = (selected_service) => {
	this.is_service_change = true;
	this._changeViewService
		.getChangeTypeBaseWorkflowStatus({ change_type_id: this.change_data?.basic_info?.change_type?.id })
		.subscribe((res) => {
			this.prepareServiceChangeData(res, selected_service);
		});
}
getChangeRequesterImpactService = () => {
	this._changeViewService
	.getRequesterImpactService({
		requester_id: this.process_data.requester?.requester_id,
		is_published: true,
		bind_classification: false,
		module_name_list: JSON.stringify(["incident"]),
	})
	.subscribe(
		(res) => {
			this.service_config["is_editable"] = true;
			if (this.process_data?.basic_info?.status?.state_id === 6 && this.permissions?.edit) {
				this.service_config["is_editable"] = false;
				this.service_config["is_loading"] = false;
			}else if(this.process_data?.basic_info?.status?.state_id === 5 && this.permissions?.edit){
				this.service_config["is_editable"] = false;
				this.service_config["is_loading"] = false;
			}else if(!this.permissions?.edit){
				this.service_config["is_editable"] = false;
				this.service_config["is_loading"] = false;
			}
			this.dropdown["formatted_services"] = [];
			res?.forEach((ele) => {
				const data = new TreeviewItem(ele);
				this.dropdown["formatted_services"].push(data);
			});
			this.service_config["is_loading"] = false;
		},
		(error) => {
			console.log("error_while_fetching impact service", error);
			this.service_config["is_loading"] = false;
		}
	);
}
getChangeImpactServiceClassification = () => {
	this._changeViewService
	.getImpactServiceClassification({
		service_id: this.process_data.basic_info?.impact_service,
		format_to_treeview: true,
	})
	.subscribe(
		(res) => {
			this.classification_config["is_editable"] = true;
			if (this.process_data?.basic_info?.status?.state_id === 6 && this.permissions?.edit) {
				this.classification_config["is_editable"] = false;
				this.classification_config["is_loading"] = false;
			}else if(this.process_data?.basic_info?.status?.state_id === 5 && this.permissions?.edit){
				this.classification_config["is_editable"] = false;
				this.classification_config["is_loading"] = false;
			}else if(!this.permissions?.edit){
				this.classification_config["is_editable"] = false;
				this.classification_config["is_loading"] = false;
			}
			this.dropdown["formatted_classification_data"] = [];
			res?.classification?.forEach((ele) => {
				const data = new TreeviewItem(ele);
				this.dropdown["formatted_classification_data"].push(data);
			});
			this.classification_config["is_loading"] = false;
		},
		(error) => {
			console.log("error_while_fetching impact service", error);
			this.classification_config["is_loading"] = false;
		}
	);
}

saveChangeRichTextData = (payload) => {
	this._changeViewService.saveRichTextData(payload).subscribe((response) => {
		if (response["status"] === "success") {
			this._toastr.success(
				this._translateService.instant(response["message"]),
				this._translateService.instant("Message.msg_save_success"),
				{
					toastClass: "toast ngx-toastr",
					closeButton: true,
					positionClass: "toast-top-right",
				}
			);
			this.reloadCommunicationData();
			this.reloadCommunication = true;
			this.dnsCommunication.resetCommunicationData();
		} else {
			this._toastr.error(
				this._translateService.instant(response["message"]),
				this._translateService.instant("Error.err_save_failed"),
				{
					toastClass: "toast ngx-toastr",
					closeButton: true,
					positionClass: "toast-top-right",
				}
			);
		}
		this.disableCommentSubmit = false;
	});
}
updateChangeApprovalData = (processID, process_data, otherAction) => {
	this._changeViewService
	.editChange(processID, process_data)
	.pipe(
		map((response: any) => {
			if (response) {
				this._modalService.dismissAll();
				this.selfAssignSel = false;
				this.getProcessData();
				this.dnsCommunication.resetCommunicationData();
				if(otherAction === 'Approval'){
					this.reloadCommunicationData();
					this.disableCommentSubmit = false;
				}
			} else {
				this._toastr.error(
					this._translateService.instant(response["message"]),
					this._translateService.instant("Error.err_save_failed"),
					{
						toastClass: "toast ngx-toastr",
						closeButton: true,
						positionClass: "toast-top-right",
					}
				);
				this.disableCommentSubmit = false;
			}
			// Success
		}),
		catchError((error) => {
			this.disableCommentSubmit = false;

			return throwError(error.statusText);
		})
	)
	.subscribe();
}
deleteChangeAttachment = (attachment_id, payload) => {
	this._changeViewService.deleteAttachment(attachment_id, payload).subscribe(
		(response) => {
			// window.location.reload();
			this.getAttachmentDetails(this.processID, 'getAttachmentDetails');
			this.getHistoryDetails(this.processID, 'getHistoryDetails');
			if (response["status"] === "success") {
				this._toastr.success(
					this._translateService.instant(response["message"]),
					this._translateService.instant("Message.msg_success"),
					{
						toastClass: "toast ngx-toastr",
						closeButton: true,
						positionClass: "toast-top-right",
					}
				);
			} else if (response["status"] === "failed") {
				this._toastr.error(
					this._translateService.instant(response["message"]),
					this._translateService.instant("Error.err_fail"),
					{
						toastClass: "toast ngx-toastr",
						closeButton: true,
						positionClass: "toast-top-right",
					}
				);
			}
		},
		(error) => {
			// todo
		}
	);
}
convertChangeToIncident = (inci_obj) => {
	let payload = {
		change_id: this.process_data?.change_id,
		incident_display_id: inci_obj?.display_id,
	};
	this._changeViewService.convertChangeToIncident(inci_obj?.id, payload).subscribe(
		(response) => {
			this.getProcessData();
			this.getIncidentDetails(inci_obj?.id);
			this.getRelations(this.moduleName);
		},
		(error) => {
			// todo
		}
	);
}
getChangeIncident = (incident_id) => {
	this._changeViewService
		.getIncident(incident_id, { return_main_info: true })
		.pipe(
			map(
				(response) => {
					this.incident_data = response?.incident;
				},
				(error) => {
					//todo
				}
			)
		)
		.subscribe();
};

onChangeSourceChange = (event) => {
	this.process_data.basic_info.change_source = this.process_data_copy.basic_info.change_source;
	this.changeEdit(this.process_data);
}

// End of Change Action List //
// Release Action List //
releaseEdit = (payload, from_close_incident = false, from_assign = false) => {
	if (payload.due_date instanceof Array) {
		payload.due_date = payload.due_date[0];
	}
	this._releaseViewService
		.editRelease(this.processID, payload)
		.pipe(
			map((response: any) => {
				if (response) {
					if (from_close_incident) {
						this.disableCommentSubmit = true;
					}
					this._toastr.success(
						this._translateService.instant(response["message"]),
						this._translateService.instant("Message.msg_save_success"),
						{
							toastClass: "toast ngx-toastr",
							closeButton: true,
							positionClass: "toast-top-right",
						}
					);
					if (this.reloadCommunication) {
						this.reloadCommunicationData();
						this.reloadCommunication = false;
					}
					this._modalService.dismissAll();
					this.selfAssignSel = false;
					this.service_config = {}
					this.classification_config = {}
					this.getProcessData();
					this.getHistoryDetails(this.processID, 'getHistoryDetails');
					this.process_data_copy = cloneDeep(this.process_data);
					this.isStageDataLoaded = true;
				} else {
					if (from_close_incident) {
						this.disableCommentSubmit = false;
					}
					if (from_assign) {
						this.asgnDisabled = false;
					}
					this._toastr.error(
						this._translateService.instant(response["message"]),
						this._translateService.instant("Error.err_save_failed"),
						{
							toastClass: "toast ngx-toastr",
							closeButton: true,
							positionClass: "toast-top-right",
						}
					);
				}
				// Success
			}),
			catchError((error) => {
				if (from_close_incident) {
					this.disableCommentSubmit = false;
				}
				if (from_assign) {
					this.asgnDisabled = false;
				}
				return throwError(error.statusText);
			})
		)
		.subscribe();
}
getReleaseData = () => {
	this._releaseViewService
		.getRelease(this.processID)
		.pipe(
			map(
				(response) => {
					if (response) {
						this.process_data = response?.release;
						this.escalataionData = response?.escalation_data;
						this.process_data_copy = cloneDeep(this.process_data);
						this.sourceID = this.process_data?.basic_info?.release_source?.id;
						if (this.module_permissions?.["BASE"]?.["KNOWLEDGE_BASE"]) {
							this.getAllKB(this.process_data_copy?.basic_info?.summary);
						}
						if (this.process_data?.basic_info?.state?.id < 4) {
							this.process_data["inci_resolution_time"] = null;
						}
						// this.disable_config = false;
						if ([6, 7].includes(this.process_data?.basic_info?.status?.state_id) && this.permissions?.edit) {
							this.disable_status_config = true;
							this.disable_config = true;
						}else if(this.process_data?.basic_info?.status?.state_id === 5 && this.permissions?.edit){
							this.disable_config = true;
						}
						this.currentUserProfileId = response?.current_user_id;
						this.team_id = response?.release?.current_assignment_info?.group;
						this.custom_field_config = response?.custom_field_config;
						this.custom_workflow_fields = [];
						for (let i = 0; i < this.custom_field_config?.custom_fields?.length; i++) {
							const each_custom_data = this.custom_field_config.custom_fields[i];
							if (this.custom_workflow_fields[each_custom_data.section] === undefined) {
								this.custom_workflow_fields[each_custom_data.section] = [];
							}
							this.custom_workflow_fields[each_custom_data.section].push(each_custom_data);
						}
						this.custom_field_data = response?.release?.custom_field_data;
						// this.requester_latest_releases = response?.requester_latest_releases;
						// this.next_release_id = response?.release?.next_release_id;
						// this.prev_release_id = response?.release?.prev_release_id;
						// this.next_incident_id = response?.incident?.next_incident_id;
						// this.prev_incident_id = response?.incident?.prev_incident_id;

						this.dropdown["expertise"] = response?.expertise_list;
						this.dropdown["level"] = response?.level_list;
						this.dropdown["assignee"] = response["assignee_list"];
						this.dropdown["transition_status"] = response["transition_status"];
						this.process_data_copy["expertise_list"] = response?.expertise_list;
						this.process_data_copy["level_list"] = response?.level_list;
						this.process_data_copy["assignee_list"] = response?.assignee_list;
						this.extra_info["selected_assignee_info"] = response?.selected_assignee_info;
						this.process_extra_info["selected_assignee_info"] = this.extra_info?.selected_assignee_info;
						this.extra_info["custom_field_config"] = this.custom_field_config;
						this.extra_info["group_name"] = "";
						this.extra_info["level"] = "";
						this.extra_info["expertise"] = "";
						this.extra_info["level_id"] = "";
						this.extra_info["expertise_id"] = "";
						if (response["group_type"]) {
							this.extra_info["group_type"] = response["group_type"];
						}
						if (this.process_data?.current_assignment_info !== undefined) {
							this.prev_current_assignment_info = this.process_data.current_assignment_info;
							if (this.process_data["current_assignment_info"]["group_name"]) {
								this.extra_info["group_name"] =
									this.process_data["current_assignment_info"]["group_name"];
							}

							if (this.process_data["current_assignment_info"]["level"]) {
								this.extra_info["level"] = this.process_data["current_assignment_info"]["level"];
							}
							if (this.process_data["current_assignment_info"]["expertise"]) {
								this.extra_info["expertise"] =
									this.process_data["current_assignment_info"]["expertise"];
							}
							if (this.process_data["current_assignment_info"]["level_id"]) {
								this.extra_info["level_id"] =
									this.process_data["current_assignment_info"]["level_id"];
							}
							if (this.process_data["current_assignment_info"]["expertise_id"]) {
								this.extra_info["expertise_id"] =
									this.process_data["current_assignment_info"]["expertise_id"];
							}
							this.commonActionMap[this.moduleName]['getTeamUser'](this.process_data?.current_assignment_info?.group)
							// this._incidentViewService
							// 	.getTeamUserMap({ team_id: this.process_data?.current_assignment_info?.group })
							// 	.subscribe((response) => {
							// 		this.dropdown["team_user_profile_map"] = response;
							// 	});
						}
						this.dropdown["services"] = response["service_list"];
						this.dropdown["status"] = response?.status;
						this.dropdown["filtered_status"] = this.dropdown?.status?.filter(
							(status) => status.state_id === this.process_data?.basic_info?.state?.id
						);
						this.dropdown["stage_state_option_map"] = response?.stage_state_option_map || {};
						this.load_inci_resolve = false;
						this.updateStageInputData();
						let signature_list = response?.signature_list;
						this.signatureInputData = this.getSignature(signature_list);
						// IMAP List
						this.imap_list = response?.imap_list;
						this.imapInputData = this.getIMAPConfig(this.imap_list);
						if (response["options"]) {
							const options = response["options"];
							this.module_id = options?.module_id;
							this.incident_module_id = options?.incident_module_id;
							this.dropdown["release_module_id"] = options?.release_module_id;
							this.kb_module_id = options?.kb_module_id;
							this.dropdown["release_prefix_list"] = options?.PROBLEM_PREFIX_LIST;
							this.dropdown["relation_type"] = options?.relation_type;
							this.dropdown["state"] = options["state"];
							// this.stateStatusMap = options["state_status_map"];
							this.stateKeyIdMap = options["state_key_id_map"];
							this.dropdown["state_status_map"] = options["state_status_map"];
							// this.dropdown["hold_status"] = options["hold_status"];
							this.dropdown["release_type"] = options["release_type"];
							this.dropdown["release_source"] = options["release_source"];
							this.dropdown["urgency"] = options["urgency"];
							this.dropdown["priority"] = options["priority"];
							this.dropdown["severity"] = options["severity"];
							this.dropdown["event_status"] = options["event_status"];
							this.dropdown["type"] = options["type"];
							this.dropdown["impact"] = options["impact"];
							this.dropdown["tagList"] = options["tags"];
							this.dropdown["tagTypeList"] = options["TAG_TYPE_LIST"];
							this.dropdown["users"] = options["users"];
							this.dropdown["teams"] = options["teams"];
							this.dropdown["state_id_map"] = options["state_id_map"];
							this.dropdown["analysis_technique_list"] = options["analysis_technique_list"];
							this.dropdown["analysis_type_list"] = options["analysis_type_list"];
							this.dropdown["risk_type_list"] = options["risk_type_list"];
							this.dropdown["root_cause_list"] = options["root_cause_list"];
							this.dropdown["solution_type_list"] = options["solution_type_list"];
							this.dropdown["task_status"] = options["task_status"];
							this.dropdown["current_user_id"] = response?.current_user_id;
							if (
								this.process_data?.current_assignment_info &&
								this.process_data?.current_assignment_info?.group === undefined &&
								this.process_data?.current_assignment_info?.assignee !== undefined
							) {
								this.team_id = "self_assign";
								this.selfAssignSel = true;
							}
							// if (options.teams && options.current_user_group.length > 0) {
							this.dropdown["teams_and_self_assign"] = [
								{ team_id: "self_assign", name: "Self Assign" },
							].concat(this.dropdown["teams"]);
							// this.dropdown.user_profile_map = {};
							options?.users.forEach((element) => {
								this.dropdown.user_profile_map[element.profile_id] = element;
							});
							// } else {
							// 	this.dropdown["teams_and_self_assign"] = this.dropdown["teams"];
							// }
							this.dropdown["cc_address"] = [
								{
									full_name: this.process_data?.requester?.unmasked_full_name,
									email: this.process_data?.requester?.unmasked_email,
									avatar_color: this.process_data?.requester?.avatar_color,
									profile_image: this.process_data?.requester_profile,
								},
							];
							this.dropdown["to_address"] = [
								{
									full_name: this.process_data?.requester?.unmasked_full_name,
									email: this.process_data?.requester?.unmasked_email,
									avatar_color: this.process_data?.requester?.avatar_color,
									profile_image: this.process_data?.requester_profile,
								},
							];
							this.dropdown["release_type"] = options["release_type"];
							this.dropdown["release_source"] = options["release_source"];
							this.dropdown["groupt_type_list"] = options["groupt_type_list"];
							this.dropdown["incident_source"] = options["incident_source"];
							this.dropdown["attach_category_list"] = options["attach_category_list"];
							this.dropdown["attach_filter_category_list"] = [
								{
									name: this._translateService.instant("UI.k_all"),
									id: "all",
								},
							];
							this.dropdown["current_user_group"] = options["current_user_group"];
							const imageDefaults = options["image_upload_defaults"];
							this.ALLOWED_TYPES = imageDefaults["allowed_types"];
							this.MAX_IMAGE_SIZE = imageDefaults["max_size"];
							this.MAX_HEIGHT = imageDefaults["max_height"];
							this.MAX_WIDTH = imageDefaults["max_width"];
							this.dropdown["close_type"] = options["close_type"];
							this.dropdown["closure_category_list"] = options["closure_category"];
							this.dropdown["team_user_profile_map"] = options["team_user_profile_map"];
							options.attach_category_list?.forEach((opt_obj) => {
								this.dropdown.attach_filter_category_list.push(opt_obj);
							});
							// this.dropdown["formatted_services"] = [];
							// response?.formatted_services.forEach((ele) => {
							// 	const data = new TreeviewItem(ele);
							// 	this.dropdown["formatted_services"].push(data);
							// });
							// const class_maps = _.cloneDeep(this.dropdown?.service_category_classification_map);
							// if (class_maps) {
							// 	for (const classs_map in class_maps) {
							// 		this.dropdown["service_category_classification_map"][classs_map][
							// 			"formatted_classification_data"
							// 		] = [];
							// 		class_maps[classs_map]["formatted_classification_data"].forEach((ele) => {
							// 			const data = new TreeviewItem(ele);
							// 			this.dropdown["service_category_classification_map"][classs_map][
							// 				"formatted_classification_data"
							// 			].push(data);
							// 		});
							// 	}
							// }
						}
						this.getRequesterHistory()
						this.dataLoaded = true;
						// let editIncident  = this._incidentViewService.getIncidentSidebar();
						// console.log("jjjjjjjj",editIncident,this.hideIncidentInlineEdit,this.extra_info);

						// if(editIncident === true) {
						// 	this.process_extra_info["selected_assignee_info"] = this.extra_info["selected_assignee_info"];
						// 	this.process_extra_info["custom_field_config"] = this.custom_field_config;
						// 	this.process_extra_info["group_type"] = this.extra_info["group_type"];
						// 	this._coreSidebarService.getSidebarRegistry('incident-edit-sidebar').toggleOpen();
						// 	this.hideIncidentInlineEdit = true;
						// 	console.log("lllllll",this.hideIncidentInlineEdit);

						// }
					}
				},
				(error) => {
					// todo
				}
			)
		)
		.subscribe();
}
getReleaseTeamUser = (teamId) => {
	this._releaseViewService
	.getTeamUserMap({ team_id: teamId })
	.subscribe((response) => {
		this.dropdown["team_user_profile_map"] = response;
	});
}
getReleaseAttachmentDetail = (id) => {
	const payload = { release_id: id };
	this._releaseViewService.getAttachmentDetails(payload).subscribe((response) => {
		this.allAttachments = response;
		this.selectAttachmentCategory({ id: this.selected_attachment_id, name: this.selected_attachment_name });
	});
}
getReleaseHistoryDetail = (id) => {
	const payload = { release_id: id };
	this._releaseViewService.getHistoryDetails(payload).subscribe((response) => {
		this.historyDataset = response;
		this.historydup = this.historyDataset;
		this.formatHistoryData();
	});
}
getReleaseAllKBs = (id) => {
	const payload = { release_id: id };
	this._releaseViewService.getAllKBs(payload).subscribe((response) => {
		this.allKBs = response;
		this.filteredKBs = response;
		for (let i = 0; i < this.allKBs.length; i++) {
			this.allKBs[i].content = this.allKBs[i].content.replace(/<img[^>]*>/g, "");
			this.filteredKBs[i].content = this.filteredKBs[i].content.replace(/<img[^>]*>/g, "");
		}
		
	});
}
getReleaseRelation = (relId) => {
	const payload = {
		relation_id: relId,
		ref_id: this.processID,
	};
	this._releaseViewService
		.processReleaseRelations(payload)
		.pipe(
			map((response: any) => {
				this.incidentRelations = response?.process_relation_data;
				this.allRelations = response?.process_relation_data;
				this.releaseProcessMapping = response?.release_mapping;
				this.incidentMapping = response?.relation_mapping;
				this.filterRelations();
			})
		)
		.subscribe();
}
getReleaseRichTextData = () => {
	const payload = {
		ref_id: this.processID,
	};
	this._releaseViewService.getRichTextData(payload).subscribe((response) => {
		this.rich_text_list = response;
		for (const idx in this.rich_text_list) {
			this.rich_text_list[idx]["without_dom_sanitizer_descr"] = this.rich_text_list[idx]["description"];
			this.rich_text_list[idx]["description"] = this._domSanitizerService.bypassSecurityTrustHtml(
				this.rich_text_list[idx]["description"]
			);
		}
	});
}
getReleaseMailConversations = () => {
	const payload = {
		page: this.page,
		items_per_page: this.selectedOption,
		ref_id: this.processID,
		comments: this.showComments,
		emails: this.showEmails,
		sms: this.showSMS,
		comment_type: this.internalNoteFilterSelection,
	};
	this._releaseViewService.getMailConversations(payload).subscribe((response) => {
		if (response) {
			this.mail_list = response?.data;
			this.copyOfAllCard = response?.data;
			this.latestEmail = response?.latest_email;
			this.finished = false;
			if (this.mail_list.length === 0) {
				this.notEmptyCommData = false;
				this.finished = true;
			}
			this.finished = true;
			this.notScrolly = true;
			// this.mail_list.sort(function (a, b) {
			// 	return a.creation_time_int - b.creation_time_int
			//   })
			this.commDataCount = this.mail_list.length;
			this.commDataTotalCount = response["count"];
			setTimeout(() => {
				this.scrolltop = this.scrollMe?.nativeElement.scrollHeight + 100;
			}, 0);
		}
	});
}
convertReleaseToKB = (kbID) => {
	const payload = {
		release_id: this.processID,
	};
	this._releaseViewService.convertReleaseToKB(kbID, payload).subscribe(
		(response) => {
			this.getProcessData();
			// if (response["id"]) this.isConvertedToKB = true;
		},
		(error) => {
			// todo
		}
	);
}
getReleaseUserProfile = () => {
	this._releaseViewService
		.getUserProfile(this.currentAssignmentInfo["assignee"])
		.pipe(
			map((response: any) => {
				if (response) {
					this.current_extra_info["selected_assignee_info"] = response;
					this.currentAssignmentInfo["assignee_profile"] = response;
				}
				// Success
			}),
			catchError((error) => {
				return throwError(error.statusText);
			})
		)
		.subscribe();
}
saveReleaseConversation = (messageParam, files) => {
	this._releaseViewService.saveConversation(messageParam).subscribe((response) => {
		if (response["status"] === "success") {
			this.isOpenCC = false;
			this.isOpenBCC = false;
			this._toastr.success(
				this._translateService.instant(response["message"]),
				this._translateService.instant("Message.msg_save_success"),
				{
					toastClass: "toast ngx-toastr",
					closeButton: true,
					positionClass: "toast-top-right",
				}
			);
			this.page = 1;
			this.commDataTotalCount = 0;
			this.getMailConversations('getMailConversations');
			this.showReplyBox = true;
			this.isEmailCompOpen = false;
			setTimeout(() => {
				this.scrolltop = this.scrollMe?.nativeElement.scrollHeight + 100;
			}, 0);
			if (files) {
				this.loadAttachments("");
			}
		} else if (response["status"] === "error") {
			this._toastr.error(this._translateService.instant(response["message"]), "Error", {
				toastClass: "toast ngx-toastr",
				closeButton: true,
				positionClass: "toast-top-right",
			});
		}
	});
}
getReleaseApprovalStatus = (editerId) => {
	this._releaseViewService
	.getApprovalStatus({
		release_id: this.processID,
	})
	.subscribe((res) => {
		if (res?.data?.is_approval_completed !== true) {
			this.dnsCommunication.enableEditor("approvealSet", undefined);
			if(editerId){
				this.editorId = editerId;
			}
			this.showEditor = true;
			this.dropdown["filtered_status"] = this.dropdown?.transition_status?.filter((e) => {
				return e["state_id"] === this.stateKeyIdMap["Approval"];
			});
			this.process_data.basic_info.state["id"] = this.dropdown?.filtered_status?.[0]["state_id"];
			this.process_data.basic_info.status["id"] = this.dropdown?.filtered_status?.[0]["id"];
		} else {
			this._toastr.error("Error", this._translateService.instant(res?.message));
		}
	});
}
getReleaseRefMailConversations = (evt) => {
	this._releaseViewService.getMailConversations(evt.payload).subscribe((response) => {
		if (response) {
			this.mail_list = response?.data;
			this.mail_list?.forEach((element) => {
				element.content = element.content.replace(/<a id='ticketURL'.*?<\/a>/i, "");
			});
			this.copyOfAllCard = response?.data;
			this.latestEmail = response?.latest_email;
			this.finished = false;
			if (this.mail_list.length === 0) {
				this.notEmptyCommData = false;
				this.finished = true;
			}
			this.finished = true;
			this.notScrolly = true;
			// this.mail_list.sort(function (a, b) {
			// 	return a.creation_time_int - b.creation_time_int
			//   })
			this.commDataCount = this.mail_list.length;
			this.commDataTotalCount = response["count"];
			this.dnsCommunication.handleMailConversionResponse({ response, ...evt });
		}
		// if (response) {
		// 	this.mail_list = response?.data;
		// 	this.mail_list?.forEach((element) => {
		// 		element.content = element.content.replace(/<a id='ticketURL'.*?<\/a>/i, "");
		// 	});
		// 	this.copyOfAllCard = response?.data;
		// 	this.latestEmail = response?.latest_email;
		// 	this.finished = false;
		// 	if (this.mail_list.length === 0) {
		// 		this.notEmptyCommData = false;
		// 		this.finished = true;
		// 	}
		// 	this.finished = true;
		// 	this.notScrolly = true;
		// 	// this.mail_list.sort(function (a, b) {
		// 	// 	return a.creation_time_int - b.creation_time_int
		// 	//   })
		// 	this.commDataCount = this.mail_list.length;
		// 	this.commDataTotalCount = response["count"];
		// 	setTimeout(() => {
		// 		this.scrolltop = this.scrollMe?.nativeElement.scrollHeight + 100;
		// 	}, 0);
		// }
	});
}
getReleaseRequesterHistory = () => {
	let palyload = {
		requester_id: this.process_data?.requester?.requester_id,
		requester_email: this.process_data?.requester?.email,
	};
	this._releaseViewService.getReqLatestReleases(palyload).subscribe((response) => {
		this.requester_history = response?.requester_info;
		this.requester_history_dup = response?.requester_info;
		if (this.displayAllInteraction && this.requester_history_dup.length !== 0) {
			this.requester_history = [];
			for (let i = 0; i < 2; i++) {
				if (this.requester_history_dup[i]) {
					this.requester_history.push(this.requester_history_dup[i]);
				}
			}
		}
	});
}
getReleaseRelease = (release_id) => {
	this._releaseViewService
	.getRelease(release_id)
	.pipe(
		map(
			(response) => {
				this.release_data = response?.release;
			},
			(error) => {
				//todo
			}
		)
	)
	.subscribe();
}

getReleaseServiceBaseWorkflow = (selected_service) => {
	this.is_service_change = true;
	this._releaseViewService
		.getReleaseTypeBaseWorkflowStatus({ release_type_id: this.release_data?.basic_info?.release_type?.id  })
		.subscribe((res) => {
			this.prepareServiceChangeData(res, selected_service);
		});
}
getReleaseRequesterImpactService = () => {
	this._releaseViewService
	.getRequesterImpactService({
		requester_id: this.process_data.requester?.requester_id,
		is_published: true,
		bind_classification: false,
		module_name_list: JSON.stringify(["incident"]),
	})
	.subscribe(
		(res) => {
			this.service_config["is_editable"] = true;
			if (this.process_data?.basic_info?.status?.state_id === 6 && this.permissions?.edit) {
				this.service_config["is_editable"] = false;
				this.service_config["is_loading"] = false;
			}else if(this.process_data?.basic_info?.status?.state_id === 5 && this.permissions?.edit){
				this.service_config["is_editable"] = false;
				this.service_config["is_loading"] = false;
			}else if(!this.permissions?.edit){
				this.service_config["is_editable"] = false;
				this.service_config["is_loading"] = false;
			}
			this.dropdown["formatted_services"] = [];
			res?.forEach((ele) => {
				const data = new TreeviewItem(ele);
				this.dropdown["formatted_services"].push(data);
			});
			this.service_config["is_loading"] = false;
		},
		(error) => {
			console.log("error_while_fetching impact service", error);
			this.service_config["is_loading"] = false;
		}
	);
}
getReleaseImpactServiceClassification = () => {
	this._releaseViewService
	.getImpactServiceClassification({
		service_id: this.process_data.basic_info?.impact_service,
		format_to_treeview: true,
	})
	.subscribe(
		(res) => {
			this.classification_config["is_editable"] = true;
			if (this.process_data?.basic_info?.status?.state_id === 6 && this.permissions?.edit) {
				this.classification_config["is_editable"] = false;
				this.classification_config["is_loading"] = false;
			}else if(this.process_data?.basic_info?.status?.state_id === 5 && this.permissions?.edit){
				this.classification_config["is_editable"] = false;
				this.classification_config["is_loading"] = false;
			}else if(!this.permissions?.edit){
				this.classification_config["is_editable"] = false;
				this.classification_config["is_loading"] = false;
			}
			this.dropdown["formatted_classification_data"] = [];
			res?.classification?.forEach((ele) => {
				const data = new TreeviewItem(ele);
				this.dropdown["formatted_classification_data"].push(data);
			});
			this.classification_config["is_loading"] = false;
		},
		(error) => {
			console.log("error_while_fetching impact service", error);
			this.classification_config["is_loading"] = false;
		}
	);
}
// updateReleaseMsgReadStatus = (msg_obj) => {
// 	if (this.isLoggedInUserAssignee()) {
// 		msg_obj["is_assignee"] = true;
// 	}
// 	this._releaseViewService.updateMsgReadStatus(msg_obj).subscribe(
// 		(response) => {
// 			if (response["status"] === "success") {
// 				this._toastr.success(
// 					this._translateService.instant(response["message"]),
// 					this._translateService.instant("Message.msg_success"),
// 					{
// 						toastClass: "toast ngx-toastr",
// 						closeButton: true,
// 						positionClass: "toast-top-right",
// 					}
// 				);
// 				this.dnsCommunication.resetCommunicationData();
// 			} else if (response["status"] === "failed") {
// 				this._toastr.error(
// 					this._translateService.instant(response["message"]),
// 					this._translateService.instant("Error.err_fail"),
// 					{
// 						toastClass: "toast ngx-toastr",
// 						closeButton: true,
// 						positionClass: "toast-top-right",
// 					}
// 				);
// 			}
// 		},
// 		(error) => {
// 			// todo
// 		}
// 	);
// }
saveReleaseRichTextData = (payload) => {
	this._releaseViewService.saveRichTextData(payload).subscribe((response) => {
		if (response["status"] === "success") {
			this._toastr.success(
				this._translateService.instant(response["message"]),
				this._translateService.instant("Message.msg_save_success"),
				{
					toastClass: "toast ngx-toastr",
					closeButton: true,
					positionClass: "toast-top-right",
				}
			);
			this.reloadCommunicationData();
			this.reloadCommunication = true;
		   	this.dnsCommunication.resetCommunicationData();
		} else {
			this._toastr.error(
				this._translateService.instant(response["message"]),
				this._translateService.instant("Error.err_save_failed"),
				{
					toastClass: "toast ngx-toastr",
					closeButton: true,
					positionClass: "toast-top-right",
				}
			);
		}
		this.disableCommentSubmit = false;
	});
}
updateReleaseApprovalData = (processID, process_data, otherAction) => {
	this._releaseViewService
	.editRelease(processID, process_data)
	.pipe(
		map((response: any) => {
			if (response) {
				this._modalService.dismissAll();
				this.selfAssignSel = false;
				this.getProcessData();
				this.dnsCommunication.resetCommunicationData();
				if(otherAction === 'Approval'){
					this.reloadCommunicationData();
					this.disableCommentSubmit = false;
				}
			} else {
				this._toastr.error(
					this._translateService.instant(response["message"]),
					this._translateService.instant("Error.err_save_failed"),
					{
						toastClass: "toast ngx-toastr",
						closeButton: true,
						positionClass: "toast-top-right",
					}
				);
				this.disableCommentSubmit = false;
			}
			// Success
		}),
		catchError((error) => {
			this.disableCommentSubmit = false;

			return throwError(error.statusText);
		})
	)
	.subscribe();
}
deleteReleaseAttachment = (attachment_id, payload) => {
	this._releaseViewService.deleteAttachment(attachment_id, payload).subscribe(
		(response) => {
			// window.location.reload();
			this.getAttachmentDetails(this.processID, 'getAttachmentDetails');
			this.getHistoryDetails(this.processID, 'getHistoryDetails');
			if (response["status"] === "success") {
				this._toastr.success(
					this._translateService.instant(response["message"]),
					this._translateService.instant("Message.msg_success"),
					{
						toastClass: "toast ngx-toastr",
						closeButton: true,
						positionClass: "toast-top-right",
					}
				);
			} else if (response["status"] === "failed") {
				this._toastr.error(
					this._translateService.instant(response["message"]),
					this._translateService.instant("Error.err_fail"),
					{
						toastClass: "toast ngx-toastr",
						closeButton: true,
						positionClass: "toast-top-right",
					}
				);
			}
		},
		(error) => {
			// todo
		}
	);
}
convertReleaseToIncident = (inci_obj) => {
	let payload = {
		release_id: this.process_data?.release_id,
		incident_display_id: inci_obj?.display_id,
	};
	this._releaseViewService.convertReleaseToIncident(inci_obj?.id, payload).subscribe(
		(response) => {
			this.getProcessData();
			this.getIncidentDetails(inci_obj?.id);
			this.getRelations(this.moduleName);
		},
		(error) => {
			// todo
		}
	);
}

onReleaseSourceRelease = (event) => {
	this.process_data.basic_info.release_source = this.process_data_copy.basic_info.release_source;
	this.releaseEdit(this.process_data);
}

// End of Release Action List //
validateDateFields() {
	this.startEndDateInvalid = false;
	this.startEndDateToaster = "";
	if (
		Date.parse(this.process_data?.basic_info?.plan_start_date) >
		Date.parse(this.process_data?.basic_info?.plan_end_date)
	) {
		this.startEndDateToaster =
			this._translateService.instant("Message.msg_plan_start_end_date_validation") + "</br>";
		this.startEndDateInvalid = true;
	}
	if (Date.parse(this.process_data?.basic_info?.plan_end_date) > Date.parse(this.process_data?.due_date)) {
		this.startEndDateToaster +=
			this._translateService.instant("Message.msg_plan_end_due_date_validation") + "</br>";
		this.startEndDateInvalid = true;
	}
	if (Date.parse(this.process_data?.basic_info?.plan_start_date) > Date.parse(this.process_data?.due_date)) {
		this.startEndDateToaster +=
			this._translateService.instant("Message.msg_plan_start_due_date_validation") + "</br>";
		this.startEndDateInvalid = true;
	}
	if (this.startEndDateInvalid) {
		return false;
	}
	return true;
}
checkApproveCloseState() {
	if (
		this.disable_config == false &&
		!(this.process_data?.is_exit && this.process_data?.basic_info?.status?.state_id == 5)
	) {
		return true;
	} else {
		return false;
	}
}
openImpactSidebar = (args): void => {
	// this.currentRiskEvent = "Edit";
	this.showImpactAdd = true;
	this.impact_id = args?.impact_id || "-1";
	this._coreSidebarService.getSidebarRegistry("change-impact").open();
};
getImpactList() {
	let disable_config = this.disable_config;
	if (this.process_data?.is_exit && this.process_data?.basic_info?.status?.state_id == 5) {
		disable_config = true;
	}
	const payload = {
		page: 1,
		items_per_page: 200,
		sort: this.impactSortKey,
		reverse: this.impactReverse,
		filter: JSON.stringify(this.riskFilter),
		module: "impact",
		disable_config: disable_config,
	};
	this._changeViewService.getChangeImpactList(payload, this.processID).subscribe(
		(response) => {
			if (response) {
				this.impactData = response["results"];
				// this.impactCount = response["count"];
				this.showData = true;
			}
		},
		(error) => {
			//todo
		}
	);
}
deleteImpact = (row) => {
	Swal.fire({
		title: this._translateService.instant("Message.msg_delete_title"),
		text: this._translateService.instant("Message.msg_delete_text"),
		icon: "warning",
		showCancelButton: true,
		confirmButtonText: this._translateService.instant("Message.msg_delete_confirm"),
		cancelButtonText: this._translateService.instant("Message.msg_delete_cancel"),
		customClass: {
			confirmButton: "btn btn-primary",
			cancelButton: "btn btn-danger ml-1",
		},
	}).then((result) => {
		if (result.value) {
			this._changeViewService
				.deleteChangeImpact({}, row.impact_id)
				.pipe(
					map((response: any) => {
						if (response.status == "success") {
							this.getImpactList();
							this._toastr.success(
								this._translateService.instant(response?.message),
								this._translateService.instant("Message.msg_delete_success"),
								{
									toastClass: "toast ngx-toastr",
									closeButton: true,
									positionClass: "toast-top-right",
								}
							);
						} else {
							this._toastr.error(
								this._translateService.instant(response["message"]),
								this._translateService.instant("Error.err_delete_failed"),
								{
									toastClass: "toast ngx-toastr",
									closeButton: true,
									positionClass: "toast-top-right",
								}
							);
						}
						// Success
					}),
					catchError((error) => {
						this._toastr.error(
							this._translateService.instant("Error.err_delete_failed"),
							this._translateService.instant("Error.err_fail"),
							{
								toastClass: "toast ngx-toastr",
								closeButton: true,
								positionClass: "toast-top-right",
							}
						);
						return throwError(error.statusText);
					})
				)
				.subscribe();
		}
	});
};
saveChangeData(data, from_close_request = false, from_assign = false, from_planning = false) {
	if (from_assign) {
		this.asgnDisabled = true;
	}
	if (data.form.valid === true && this.validateDateFields()) {
		if (from_planning && this.process_data?.due_date){
			this.process_data.due_date = undefined
		}
		this.process_data["current_assignment_info"] = this.currentAssignmentInfo;
		this.get_custom_data(
			this.process_extra_info?.custom_field_config?.custom_fields,
			this.process_data?.custom_field_data
		);
		this.process_data["custom_field"] = this.process_extra_info?.custom_field_config?.custom_fields;
		if (this.process_data.actual_start_time instanceof Array) {
			this.process_data.actual_start_time = this.process_data.actual_start_time[0];
		}
		if (this.process_data.agreed_closure_date instanceof Array) {
			this.process_data.agreed_closure_date = this.process_data.agreed_closure_date[0];
		}
		if (this.process_data.actual_closure_date instanceof Array) {
			this.process_data.actual_closure_date = this.process_data.actual_closure_date[0];
		}
		if (this.process_data.due_date instanceof Array) {
			this.process_data.due_date = this.process_data.due_date[0];
			// this.process_data.due_date = this.process_data.due_date.toDateString();
		}
		if (this.process_data?.basic_info?.plan_start_date instanceof Array) {
			this.process_data.basic_info.plan_start_date = this.process_data?.basic_info?.plan_start_date[0];
			// this.change_data.basic_info.plan_start_date =
				// this.change_data?.basic_info?.plan_start_date.toDateString();
		}
		if (this.process_data?.basic_info?.plan_end_date instanceof Array) {
			this.process_data.basic_info.plan_end_date = this.process_data?.basic_info?.plan_end_date[0];
			// this.change_data.basic_info.plan_end_date = this.change_data?.basic_info?.plan_end_date.toDateString();
		}
		this.process_data.actual_start_time = convertToCalendarTime(this.process_data.actual_start_time);
		this.process_data.agreed_closure_date = convertToCalendarTime(this.process_data.agreed_closure_date);
		this.process_data.actual_closure_date = convertToCalendarTime(this.process_data.actual_closure_date);
		this.changeEdit(this.process_data, (from_close_request = from_close_request), (from_assign = from_assign));
	}
}
onHideImpact = (e) => {
	this._coreSidebarService.getSidebarRegistry("change-impact").close();
	this.showImpactAdd = false;
	this.impact_id = "-1";
	this.getImpactList();
};
getRiskList = () => {
	let disable_config = this.disable_config;
	if (this.process_data?.is_exit && this.process_data?.basic_info?.status?.state_id == 5) {
		disable_config = true;
	}
	const payload = {
		page: this.riskPageNumber + 1,
		items_per_page:
			this.riskShowAll && this.currentRiskEvent === "Add"
				? Number(this.riskLimit) + 1
				: Number(this.riskLimit),
		sort: this.riskSortKey,
		reverse: this.riskReverse,
		filter: JSON.stringify(this.riskFilter),
		module: "risk",
		disable_config: disable_config,
	};
	this.is_risk_loading = true;
	this._changeViewService.getChangeRiskList(payload, this.processID).subscribe(
		(response) => {
			if (response) {
				this.riskchangeData = response["results"];
				this.riskCount = response["count"];
				this.showData = true;
				// this.filterData = false;
				if (this.riskShowAll && this.currentRiskEvent === "Add") {
					this.riskLimit = Number(this.riskLimit) + 1;
				}
				this.is_risk_loading = false;
				this.commonActionMap[this.moduleName]['getFilterOptions']()
				// this.getFilterOptions();
				this.getSavedFilters('risk');
			}
		},
		(error) => {
			this.is_risk_loading = false;
			//todo
		}
	);
}

setRiskPage(pageInfo) {
	this.riskPageNumber = pageInfo.offset;
	this.getRiskList();
}

onRiskSort = (args): void => {
	if (args.sorts) {
		this.riskPageNumber = 0;
		this.riskSortKey = args.sorts[0].prop;
		if (this.riskSortKey == "risk_type") {
			this.riskSortKey = "type__name";
		}
		if (args.sorts[0].dir == "desc") {
			this.riskReverse = "-";
		} else {
			this.riskReverse = "";
		}
	}

	this.getRiskList();
};

setRiskLimit(limit): void {
	this.riskPageNumber = 0;
	// this.limit = args.target.value;
	if (limit === "all") {
		this.riskShowAll = true;
		this.riskLimit = this.riskCount;
	} else {
		this.riskLimit = Number(limit);
		this.riskShowAll = false;
	}
	this.getRiskList();
}

toggleRiskSideBar = (name: string) => {
	this.hideRiskProfile = true;
	this.currentRiskEvent = "Add";
	this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
};

genericSearchFilter = ($event) => {
	let search_str = $event.userSearchData.trim();
	let search_db_cols = [
		{ db_col: "name", cond: "contains" },
		{ db_col: "type__name", cond: "contains" },
	];
	let filter_options = [];
	search_db_cols.forEach((item) => {
		filter_options.push({
			condition: item.cond,
			db_col: item.db_col,
			value: search_str,
			relation: "or",
		});

		if (filter_options.length == search_db_cols.length) {
			let dict = {};
				dict["ui_display"] = $event.userSearchData;
				dict["filter_string"] = filter_options;
				dict["filter_item"] = $event.filterItem;
				dict["type"] = $event.type;
				dict["module"] = "risk";
			for (let i = 0; i < this.riskFilter.length; i++) {
				dict["filter_string"].push(this.riskFilter[i]);
			}

			this.riskFilter = [];
			this.riskFilter.push(dict);
			this.riskPageNumber = 0;
			let disable_config = this.disable_config;
			if (this.process_data?.is_exit && this.process_data?.basic_info?.status?.state_id == 5) {
				disable_config = true;
			}

			const payload = {
				page: 1,
				items_per_page: this.riskLimit,
				sort: this.riskSortKey,
				reverse: this.riskReverse,
				filter: JSON.stringify(this.riskFilter),
				module: "risk",
				disable_config: disable_config,
			};
			this.getFilteredRiskList(payload);
		}
	});
};

getFilterSearch = ($event) => {
	this.riskPageNumber = 0;
	let dict = {};
	this.riskFilter = [];
	dict["ui_display"] = $event.userSearchData;
	dict["filter_string"] = $event.userSearchString;
	dict["filter_item"] = $event.filterItem;
	dict["type"] = $event.type;
	dict["module"] = "risk";
	this.riskFilter.push(dict);
	let disable_config = this.disable_config;
	if (this.process_data?.is_exit && this.process_data?.basic_info?.status?.state_id == 5) {
		disable_config = true;
	}
	const payload = {
		page: 1,
		items_per_page: this.riskLimit,
		sort: this.riskSortKey,
		reverse: this.riskReverse,
		filter: JSON.stringify(this.riskFilter),
		module: "risk",
		disable_config: disable_config,
	};
	this.getFilteredRiskList(payload);
};

getFilteredRiskList(payload) {
	this.is_risk_loading = true;
	this._changeViewService.getChangeRiskList(payload, this.processID).subscribe(
		(response) => {
			if (response) {
				this.riskchangeData = response["results"];
				this.riskCount = response["count"];
				this.showData = true;
				this.filterData = true;
				if (this.riskShowAll && this.currentRiskEvent === "Add") {
					this.riskLimit = Number(this.riskLimit) + 1;
				}
				this.is_risk_loading = false;
				this.getSavedFilters('risk');
			}
		},
		(error) => {
			this.is_risk_loading = false;
			//todo
		}
	);
}

getSavedFilters(module): void {
	this.commonActionMap[this.moduleName]['getSavedFilters'](module)
}

toggleEditRisk = (args): void => {
	this.hideRiskProfile = true;
	this.risk_id = args.risk_id;
	this._coreSidebarService.getSidebarRegistry("risk-sidebar").toggleOpen();
};

deleteRisk = (row) => {
	Swal.fire({
		title: this._translateService.instant("Message.msg_delete_title"),
		text: this._translateService.instant("Message.msg_delete_text"),
		icon: "warning",
		showCancelButton: true,
		confirmButtonText: this._translateService.instant("Message.msg_delete_confirm"),
		cancelButtonText: this._translateService.instant("Message.msg_delete_cancel"),
		customClass: {
			confirmButton: "btn btn-primary",
			cancelButton: "btn btn-danger ml-1",
		},
	}).then((result) => {
		if (result.value) {
			this._changeViewService
				.deleteChangeRisk({}, row.risk_id)
				.pipe(
					map((response: any) => {
						if (response.status == "success") {
							if (
								this.riskCount != 1 &&
								this.riskCount - 1 < this.riskPageNumber * this.riskLimit + 1
							) {
								this.riskPageNumber = this.riskPageNumber - 1;
							}
							this.getRiskList();
							this._toastr.success(
								this._translateService.instant(response?.message),
								this._translateService.instant("Message.msg_delete_success"),
								{
									toastClass: "toast ngx-toastr",
									closeButton: true,
									positionClass: "toast-top-right",
								}
							);
						} else {
							this._toastr.error(
								this._translateService.instant(response["message"]),
								this._translateService.instant("Error.err_delete_failed"),
								{
									toastClass: "toast ngx-toastr",
									closeButton: true,
									positionClass: "toast-top-right",
								}
							);
						}
						// Success
					}),
					catchError((error) => {
						this._toastr.error(
							this._translateService.instant("Error.err_delete_failed"),
							this._translateService.instant("Error.err_fail"),
							{
								toastClass: "toast ngx-toastr",
								closeButton: true,
								positionClass: "toast-top-right",
							}
						);
						return throwError(error.statusText);
					})
				)
				.subscribe();
		}
	});
};
onHideRisk = (e) => {
	this.hideRiskProfile = false;
	this.risk_id = "-1";
	this.getRiskList();
};
getSearchWidth = (e) => {
	if(e){
		this.isExpandSearchWidth = true;
	}
	else{
		this.isExpandSearchWidth = false;
	}
}
toggleTaskSideBar = (name: string) => {
	this.hideTaskProfile = true;
	this.currentTaskEvent = "Add";
	this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
};
onHideTask = (e) => {
	this.hideTaskProfile = false;
	this.hideViewTaskProfile = false;
	this.isAddTask = false;
	this.task_id = "-1";
	this.getTaskList();
};

toggleEditTask = (args): void => {
	this.currentTaskEvent = "Edit";
	this.hideViewTaskProfile = true;
	this.task_id = args.task_id;
	this._coreSidebarService.getSidebarRegistry("view-task-sidebar").toggleOpen();
};
deleteTask = (row) => {
	this.commonActionMap[this.moduleName]['deleteTask'](row)
};

getTaskList = () => {
	this.commonActionMap[this.moduleName]['getTaskList']()
}
isChangeManagerLogin = () => {
	if (this.currentUser?.profile?.profile_id == this.process_data_copy?.change_manager?.profile_id) {
		return true;
	}
	return false;
};
updateChangeDueDate(date, fDate) {
	if (date instanceof Array) {
		date = date[0];
	}
	let formatted_date = moment(date).format("YYYY-MM-DDTHH:mm:ssZ");
	if (formatted_date != "Invalid date"){
		fDate = formatted_date;
	}
	this.process_data['due_date'] = fDate
	this.updateIncident(this.process_data)
};







getReleaseFilterOptions = () =>{
	this._releaseViewService.getFilterOptions({ moduleName: "task" }).subscribe(
		(response) => {
			if (response) {
				this.taskColumnOptions = response;
			} else {
				this.taskColumnOptions = [];
			}
		},
		(error) => {
			this.taskColumnOptions = [];
		}
	);
}
getChangeFilterOptions = () => {
	this._changeViewService.getFilterOptions({ moduleName: "risk" }).subscribe(
		(response) => {
			if (response) {
				this.columnOptions = response;
			} else {
				this.columnOptions = [];
			}
		},
		(error) => {
			this.columnOptions = [];
		}
	);
}
getTaskFilterSearch = ($event) => {
	this.taskPageNumber = 1;
	let dict = {};
	this.taskFilter = [];
	dict["ui_display"] = $event.userSearchData;
	dict["filter_string"] = $event.userSearchString;
	dict["filter_item"] = $event.filterItem;
	dict["type"] = $event.type;
	dict["module"] = "task";
	this.taskFilter.push(dict);
	let disable_config = this.disable_config;
	if (this.release_data?.is_exit && this.release_data?.basic_info?.status?.state_id == 5) {
		disable_config = true;
	}
	const payload = {
		page: 1,
		items_per_page: this.taskLimit,
		sort: this.taskSortKey,
		reverse: this.taskReverse,
		filter: JSON.stringify(this.taskFilter),
		module: "task",
		disable_config: disable_config,
	};
	this.getFilteredTaskList(payload);
};

getTaskSavedFilters(): void {
	this._releaseViewService
		.getSavedFilter({ module: "task" })
		.pipe(
			map((response: any) => {
				if (response) {
					this.savedTaskData = response;
				}
			})
		)
		.subscribe();
}
getFilteredTaskList(payload) {
	this.is_task_loading = true;
	this._releaseViewService.getReleaseTaskList(payload, this.processID).subscribe(
		(response) => {
			if (response) {
				this.taskData = response["results"];
				this.taskCount = response["count"];
				this.showTaskData = true;
				this.taskFilterData = true;
				if (this.taskShowAll && this.currentTaskEvent === "Add") {
					this.taskLimit = Number(this.taskLimit) + 1;
				}
				this.is_task_loading = false;
				this.getTaskSavedFilters();
			}
		},
		(error) => {
			this.is_task_loading = false;
			//todo
		}
	);
}

genericTaskSearchFilter = (search_str) => {
	search_str = search_str.trim();
	let filter_options = [];
	this.search_db_cols.forEach((item) => {
		filter_options.push({
			condition: item.cond,
			db_col: item.db_col,
			value: search_str,
			relation: "or",
		});

		if (filter_options.length == this.search_db_cols.length) {
			let dict = { filter_string: filter_options };
			for (let i = 0; i < this.taskFilter.length; i++) {
				dict["filter_string"].push(this.taskFilter[i]);
			}

			this.taskFilter = [];
			this.taskFilter.push(dict);
			this.taskPageNumber = 1;
			let disable_config = this.disable_config;
			if (this.release_data?.is_exit && this.release_data?.basic_info?.status?.state_id == 5) {
				disable_config = true;
			}

			const payload = {
				page: 1,
				items_per_page: this.taskLimit,
				sort: this.taskSortKey,
				reverse: this.taskFilter,
				filter: JSON.stringify(this.taskFilter),
				module: "task",
				disable_config: disable_config,
			};
			this.getFilteredTaskList(payload);
		}
	});
};
taskHandler(item: any) {
	this.isAddTask = true;
	this.task_type_id = item?.type_id;
	this._coreSidebarService.getSidebarRegistry("add-task").open();
 }


 getReleaseTask = () => {
	let disable_config = this.disable_config;
	if (this.release_data?.is_exit && this.release_data?.basic_info?.status?.state_id == 5) {
		disable_config = true;
	}
	const payload = {
		page: this.taskPageNumber,
		items_per_page: this.taskLimit,
		sort: this.taskSortKey,
		reverse: this.taskReverse,
		filter: JSON.stringify(this.taskFilter),
		module: "task",
		disable_config: disable_config,
	};
	this.is_task_loading = true;
	this._releaseViewService.getReleaseTaskList(payload, this.processID).subscribe(
		(response) => {
			if (response) {
				this.taskData = response["results"];
				//take task max due date
				for (let index = 0; index < this.taskData.length; index++) {
					if (this.taskData[index]?.due_date) {
						if (!this.taskMaxDueDate) {
							this.taskMaxDueDate = this.taskData[index]?.due_date;
						}
						if (Date.parse(this.taskMaxDueDate) < Date.parse(this.taskData[index]?.due_date)) {
							this.taskMaxDueDate = this.taskData[index]?.due_date;
						}
					}
				}
				this.taskCount = response["count"];
				this.showTaskData = true;
				this.is_task_loading = false;
				this.commonActionMap[this.moduleName]['getFilterOptions']()
				this.getSavedFilters('task');
			}
		},
		(error) => {
			this.is_task_loading = false;
			//todo
		}
	);
 }
 getChangeTask = () => {
	let disable_config = this.disable_config;
	if (this.process_data?.is_exit && this.process_data?.basic_info?.status?.state_id == 5) {
		disable_config = true;
	}
	const payload = {
		page: this.taskPageNumber,
		items_per_page: this.taskLimit,
		sort: "name",
		reverse: "",
		filter: JSON.stringify(this.taskFilter),
		module: "task",
		disable_config: disable_config,
	};
	this.is_task_loading = true;
	this._changeViewService.getChangeTaskList(payload, this.processID).subscribe(
		(response) => {
			if (response) {
				this.taskData = response["results"];
				//take task max due date
				for (let index = 0; index < this.taskData.length; index++) {
					if (this.taskData[index]?.due_date) {
						if (!this.taskMaxDueDate) {
							this.taskMaxDueDate = this.taskData[index]?.due_date;
						}
						if (Date.parse(this.taskMaxDueDate) < Date.parse(this.taskData[index]?.due_date)) {
							this.taskMaxDueDate = this.taskData[index]?.due_date;
						}
					}
				}
				this.taskCount = response["count"];
				this.showData = true;
				// this.filterData = false;
				// if (this.taskShowAll && this.currentTaskEvent === "Add") {
				// 	this.taskLimit = Number(this.taskLimit) + 1;
				// }
				this.is_task_loading = false;
				this.commonActionMap[this.moduleName]['getFilterOptions']()
				// this.getFilterOptions();
				this.getSavedFilters('task');
			}
		},
		(error) => {
			this.is_task_loading = false;
			//todo
		}
	);
 }
 getChangeSavedFilters = (module) => {
	this._changeViewService
		.getSavedFilter({ module: module })
		.pipe(
			map((response: any) => {
				if (response) {
					this.savedData = response;
				}
			})
		)
		.subscribe();
 }
 getReleaseSavedFilters = (module) => {
	this._changeViewService
		.getSavedFilter({ module: module })
		.pipe(
			map((response: any) => {
				if (response) {
					this.savedData = response;
				}
			})
		)
		.subscribe();
 }
 taskEditHandler(item: any) {
	this.isViewTask = true;
	this._coreSidebarService.getSidebarRegistry("view-task").open();
	this.featureViewTaskData = item;
	this.featureViewTaskData["permissions"] = this.permissions;
}
onHideQuickEdit() {
	// this.isRiskLevel = false;
	this.isAddTask = false;
	this.isViewTask = false;
}
isReleaseManagerLogin = () => {
	if (this.currentUser?.profile?.profile_id === this.process_data_copy?.release_manager?.profile_id) {
		return true;
	}
	return false;
};
deleteReleaseTask = (row) => {
	Swal.fire({
		title: this._translateService.instant("Message.msg_delete_title"),
		text: this._translateService.instant("Message.msg_delete_text"),
		icon: "warning",
		showCancelButton: true,
		confirmButtonText: this._translateService.instant("Message.msg_delete_confirm"),
		cancelButtonText: this._translateService.instant("Message.msg_delete_cancel"),
		customClass: {
			confirmButton: "btn btn-primary",
			cancelButton: "btn btn-danger ml-1",
		},
	}).then((result) => {
		if (result.value) {
			this._releaseViewService.deleteReleaseTask({}, row.task_id)
				.pipe(
					map((response: any) => {
						if (response.status == "success") {
							this.getTaskList();
							this._toastr.success(
								this._translateService.instant(response?.message),
								this._translateService.instant("Message.msg_delete_success"),
								{
									toastClass: "toast ngx-toastr",
									closeButton: true,
									positionClass: "toast-top-right",
								}
							);
						} else {
							this._toastr.error(
								this._translateService.instant(response["message"]),
								this._translateService.instant("Error.err_delete_failed"),
								{
									toastClass: "toast ngx-toastr",
									closeButton: true,
									positionClass: "toast-top-right",
								}
							);
						}
						// Success
					}),
					catchError((error) => {
						this._toastr.error(
							this._translateService.instant("Error.err_delete_failed"),
							this._translateService.instant("Error.err_fail"),
							{
								toastClass: "toast ngx-toastr",
								closeButton: true,
								positionClass: "toast-top-right",
							}
						);
						return throwError(error.statusText);
					})
				)
				.subscribe();
		}
	});
}
deleteChangeTask = (row) => {
	Swal.fire({
		title: this._translateService.instant("Message.msg_delete_title"),
		text: this._translateService.instant("Message.msg_delete_text"),
		icon: "warning",
		showCancelButton: true,
		confirmButtonText: this._translateService.instant("Message.msg_delete_confirm"),
		cancelButtonText: this._translateService.instant("Message.msg_delete_cancel"),
		customClass: {
			confirmButton: "btn btn-primary",
			cancelButton: "btn btn-danger ml-1",
		},
	}).then((result) => {
		if (result.value) {
			this._changeViewService
			.deleteChangeTask({}, row.task_id)
			.pipe(
				map((response: any) => {
					if (response.status == "success") {
						this.getTaskList();
						this._toastr.success(
							this._translateService.instant(response?.message),
							this._translateService.instant("Message.msg_delete_success"),
							{
								toastClass: "toast ngx-toastr",
								closeButton: true,
								positionClass: "toast-top-right",
							}
						);
					} else {
						this._toastr.error(
							this._translateService.instant(response["message"]),
							this._translateService.instant("Error.err_delete_failed"),
							{
								toastClass: "toast ngx-toastr",
								closeButton: true,
								positionClass: "toast-top-right",
							}
						);
					}
					// Success
				}),
				catchError((error) => {
					this._toastr.error(
						this._translateService.instant("Error.err_delete_failed"),
						this._translateService.instant("Error.err_fail"),
						{
							toastClass: "toast ngx-toastr",
							closeButton: true,
							positionClass: "toast-top-right",
						}
					);
					return throwError(error.statusText);
				})
			)
			.subscribe();
		}
	});
}
// isChangeManagerLogin = () => {
// 	if (this.currentUser?.profile?.profile_id == this.process_data_copy?.change_manager?.profile_id) {
// 		return true;
// 	}
// 	return false;
// };
// updateChangeDueDate(date, fDate) {
// 	console.log("Date", fDate, this.process_data?.due_date )
// 	this.process_data['due_date'] = fDate
// 	this.updateIncident(this.process_data)
// };

saveReleaseData(data, from_close_release = false, from_assign = false) {
	if (from_assign) {
		this.asgnDisabled = true;
	}
	if (data.form.valid === true && this.validateDateFields()) {
		this.process_data["current_assignment_info"] = this.currentAssignmentInfo;
		this.get_custom_data(
			this.process_extra_info?.custom_field_config?.custom_fields,
			this.process_data?.custom_field_data
		);
		if (this.process_data.actual_start_time instanceof Array) {
			this.process_data.actual_start_time = this.process_data.actual_start_time[0];
		}
		if (this.process_data.agreed_closure_date instanceof Array) {
			this.process_data.agreed_closure_date = this.process_data.agreed_closure_date[0];
		}
		if (this.process_data.actual_closure_date instanceof Array) {
			this.process_data.actual_closure_date = this.process_data.actual_closure_date[0];
		}
		if (this.process_data?.basic_info?.plan_start_date instanceof Array) {
			this.process_data.basic_info.plan_start_date = this.process_data?.basic_info?.plan_start_date[0];
		}
		if (this.process_data?.basic_info?.plan_end_date instanceof Array) {
			this.process_data.basic_info.plan_end_date = this.process_data?.basic_info?.plan_end_date[0];
		}
		if (this.process_data.due_date instanceof Array) {
			this.process_data.due_date = this.process_data.due_date[0];
		}
		this.process_data.actual_start_time = convertToCalendarTime(this.process_data.actual_start_time);
		this.process_data.agreed_closure_date = convertToCalendarTime(this.process_data.agreed_closure_date);
		this.process_data.actual_closure_date = convertToCalendarTime(this.process_data.actual_closure_date);

		this.releaseEdit(
			this.process_data,
			(from_close_release = from_close_release),
			(from_assign = from_assign)
		);
	}
}

updateVersion = (event) => {
	clearTimeout(this.timeout);
	this.timeout = setTimeout(() => {
		if (event.keyCode != 13){
			this._releaseViewService
			.getRelease(this.processID)
			.pipe(
				map(
					(response) => {
						if (response) {
							let process_data = response?.release;
							let process_data_copy = this.process_data
							process_data_copy['basic_info']['version'] = event.target.value
							this.updateIncident(process_data_copy);
						}
					},
					(error) => {
						// todo
					})).subscribe()
		}
	}, 2000);
}
ngOnChanges(changes: SimpleChanges) {
	if(changes.updateProcessData?.currentValue){
		this.getProcessData();
	}
}
setReviewData(event) {
	let review_data = {
		review_comment: event.data,
		review_state: event.review_state,
		is_rollback: this.is_rollback,
	};
	this.process_data = event.refdata;
	this.disableCommentSubmit = true;
	this.process_data["review_data"] = review_data;
	this.commonActionMap[this.moduleName]['updateReviewData'](this.processID, this.process_data, 'Review')
}

updateChangeReviewData = (processID, process_data, otherAction) => {
	this._changeViewService
	.editChange(processID, process_data)
	.pipe(
		map((response: any) => {
			if (response) {
				this._modalService.dismissAll();
				this.selfAssignSel = false;
				this.reloadCommunication = true
				this.getProcessData();
				this.dnsCommunication.resetCommunicationData();
			} else {
				this._toastr.error(
					this._translateService.instant(response["message"]),
					this._translateService.instant("Error.err_save_failed"),
					{
						toastClass: "toast ngx-toastr",
						closeButton: true,
						positionClass: "toast-top-right",
					}
				);
				this.disableCommentSubmit = false;
			}
			// Success
		}),
		catchError((error) => {
			this.disableCommentSubmit = false;

			return throwError(error.statusText);
		})
	)
	.subscribe();
}

addAsset = ($event) => {
	this.showAssetListView = !this.showAssetListView;
	this._coreSidebarService.getSidebarRegistry("asset-select-view").toggleOpen();
	if ($event?.selected_asset != undefined){
		this.process_data["asset"] = {
			'asset_id': $event?.selected_asset.asset_id,
			'ci_id': $event?.selected_asset.ci_id,
			'ci_name': $event?.selected_asset.ci_name,
			'ip_address': $event?.selected_asset.ip_address,
			'parent_ci_id': $event?.selected_asset.parent_ci_id,
			'hostname': $event?.selected_asset.hostname,
		}
		this.updateIncident(this.process_data);
	}
}
onShowAIKBSolution = () => {
	let solutions = []
	this.rich_text_list.forEach(function (value, i) {
		if (value["type"] == "Resolution") {
			solutions.push(value["without_dom_sanitizer_descr"]);
		}
	})
	this.kbAISolution = {
		content: this.process_data.basic_info.summary,
		solutions: solutions,
		ref_id : this.processID,
		module_id: this.module_id
	};
	if(this.moduleName  === 'Problem'){
		this.kbAISolution['analysis'] = this.process_data?.root_cause,
		this.kbAISolution['submission_solution'] = this.process_data?.root_cause_solution,
		this.kbAISolution['submission_workaround'] = this.process_data?.root_cause_workaround,
		this.kbAISolution['fault_symptoms'] = this.process_data?.basic_info?.fault_symptoms
	}
	
	this.showAIKKSolution = true;
	if(!this._coreSidebarService.getSidebarRegistry('app-ai-kb-component')?.isOpened){
		this._coreSidebarService.getSidebarRegistry('app-ai-kb-component').open();
	}
};
onHideAIKBSolution = () => {
	this.showAIKKSolution = false;
	if(this._coreSidebarService.getSidebarRegistry('app-ai-kb-component')?.isOpened){
		this._coreSidebarService.getSidebarRegistry('app-ai-kb-component').close();
	}
};
openKbDraftSidebar = (data) =>{
	this.onHideAIKBSolution()
		this.kbEvent = "add";
		this.kbSolution = data;
		if (this.process_data?.is_known_error_enable){
			this.kbCurrentItem = "known_error"
			this.kbSolution['kbtype'] = "known_error"
			let kbError = {}
			kbError['error_detail'] = this.kbSolution?.content;
			kbError['root_cause'] = this.kbSolution?.solutions?.[0]?.['root_cause'];
			kbError['workaround'] = this.kbSolution?.solutions?.[0]?.['solution'];
			this.kbSolution['known_error'] = kbError
			delete this.kbSolution?.solutions;
			delete this.kbSolution?.content;
		}else{
			this.kbCurrentItem = "solution"
			this.kbSolution['kbtype'] = "solution"				
		}
		this.kbHideProfile = true;
		if(!this._coreSidebarService.getSidebarRegistry('app-dns-kb-add')?.isOpened){
			this._coreSidebarService.getSidebarRegistry('app-dns-kb-add').open();
		}
	}

	toggleSSPCloseTicketSidebar = (action) => {
		
		this.resolve_action = action
		if (this.moduleName == "Request"){
			this.closeSSPSidebarName = 'close-request'
			this.showCloseRequestSidebar = !this.showCloseRequestSidebar;
		}
		else{
			this.closeSSPSidebarName = 'close-ticket'
			this.showCloseTicketSidebar = !this.showCloseTicketSidebar;
		}
		this._coreSidebarService.getSidebarRegistry(this.closeSSPSidebarName).toggleOpen();
	};

	onHideSSPClose= (e) => {
		if (this.moduleName == "Request"){
			this.showCloseRequestSidebar = false
		} else {
			this.showCloseTicketSidebar = false;
		}
		this.resolve_action = null
	};	

	checkAllTaskClosed = (data) => {
		const payload = {
			page: 1,
			items_per_page: 1000,
			sort: "name",
			reverse: "",
			module: "task",
			only_open_task: true,
		};
		const that = this;
		this._changeViewService.getChangeTaskList(payload, this.processID).subscribe(
			(response) => {
				if (response) {
					this.notClosedTask = response["results"];
					if (this.notClosedTask?.length > 0) {
						that.process_data.basic_info["status"] = that.process_data_copy?.basic_info?.status;
						that.process_data.basic_info["state"] = that.process_data_copy?.basic_info?.state;
						const tempStatusId = that.process_data_copy?.basic_info?.status.id;
						this.sliderStatusPickerComponent?.updateValue(tempStatusId);
						let notClosedTaskDisplayIDs = "";
						for (let index = 0; index < this.notClosedTask.length; index++) {
							notClosedTaskDisplayIDs += this.notClosedTask[index]?.display_id + ", ";
						}
						notClosedTaskDisplayIDs = notClosedTaskDisplayIDs.replace(/, ([^, ]*)$/, "$1");
						Swal.fire({
							title: this._translateService.instant("Message.msg_task_not_completed"),
							text: notClosedTaskDisplayIDs,
							icon: "warning",
							confirmButtonText: this._translateService.instant("UI.k_ok"),
							customClass: {
								confirmButton: "btn btn-primary",
								cancelButton: "btn btn-danger ml-1",
							},
						});
					} else {
						this.showChangeClose = true
						this._coreSidebarService.getSidebarRegistry("process-detail-Change-close").toggleOpen();
					}
				}
			},
			(error) => {
				//todo
			}
		);
	}
	changeRequester = (openModel) => {
		// this.getIncidentDetails(this.process_data?.incident_id);
		this.getHistoryDetails(this.processID, 'getHistoryDetails');
		if (openModel === true){
			this.incident_data_temp = cloneDeep(this.process_data)
			this.isShownRequester = false
			this.modalOpenReq()
		}
		else{
			this.isShownRequester = false
		}
	};

	modalOpenReq() {
		this._modalService.open(this.modalReq, {
			centered: true,
			animation: false,
			windowClass: 'requester-modal'
		});
		this.requesterSearchStr = "";
	}

	shownRequester(requester, data) {
		this.isShownRequester = true;
		this.requester_temp = requester;
		this.sameImpactService()
		let palyload = {
			requester_id: this.requester_temp.requester_id,
			requester_email: this.requester_temp.email,
		};
		this.incident_data_temp.to_address = this.requester_temp.email;
		this.incident_data_temp.impacted_asset = this.requester_temp?.impacted_asset || {};
		this._incidentViewService.getReqLatestIncidents(palyload).subscribe((response) => {
			this.dataDup = response?.requester_info;
		});
		this._incidentViewService
			.getRequesterImpactService({
				requester_id: this.requester_temp?.requester_id,
				is_published: true,
				bind_classification: false,
				module_name_list: JSON.stringify(["incident"]),
			})
			.subscribe(
				(res) => {
					this.service_config["is_editable"] = true;
					this.dropdown["formatted_services"] = [];
					const impact_service = this.incident_data_temp.basic_info['impact_service']
					if (impact_service && this.requester_temp?.requester_id){
						this.setImpactService = this.findValue(res, impact_service);
					}
					res?.forEach((ele) => {
						const data = new TreeviewItem(ele);
						this.dropdown["formatted_services"].push(data);
					});
					this.service_config["is_loading"] = false;

					
				},
				(error) => {
					//console.log("error_while_fetching impact service", error);
					this.service_config["is_loading"] = false;
				}
			);
		this.setImpactService = true

		this.onSave(data);
	}

	sameImpactService(){
		this._incidentViewService
			.getRequesterImpactService({
				requester_id: this.requester_temp?.requester_id,
				is_published: true,
				bind_classification: false,
				module_name_list: JSON.stringify(["incident"]),
			})
			.subscribe(
				(res) => {
					if(this.incident_data?.basic_info?.impact_service){
						this.setImpactService = this.findValue(res, this.incident_data?.basic_info?.impact_service);
					}
					else{
						this.setImpactService = true;
					}
			},
		);
	}

	onSave = (data) => {
		if (data === false ||data?.form?.valid === true) {
			let palyload = {
				requester_id: this.requester_temp.requester_id,
				requester_email: this.requester_temp.email,
			};
			this.incident_data_temp["requester"] = this.requester_temp
			this.incident_data_temp.impacted_asset = [this.requester_temp?.impacted_asset || {}]
			if (this.setImpactService === false) {
				this.service_config = {}
				this.dropdown['formatted_services']= [];
				this.dropdown['formatted_classification_data']= [];
				this.incident_data_temp.basic_info["category"] = "";
				this.incident_data_temp.basic_info["category_name"] = "";
				this.incident_data_temp.basic_info['service_category'] = "";
				this.incident_data_temp.basic_info['impact_service'] = "";
				this.incident_data_temp.basic_info['impact_service_name'] = "";
				this.incident_data_temp.basic_info['impact_service_tree_path'] = "";
				this.incident_data_temp.basic_info['service_classification'] = "";
				this.incident_data_temp.basic_info['service_classification_name'] = "";
				this.incident_data_temp.basic_info['service_classification_path'] = "";
				this.incident_data_temp.basic_info['catalogue'] = "",
				this.incident_data_temp.basic_info['catalogue_name'] = ""
				this.custom_field_config = {};
				this.custom_field_data = {};
			// this.incident_data_temp['basic_info']['impact_service_tree_path'] = null
			}
			if (this.requester_temp?.impacted_asset){
				let impacted_asset = this.requester_temp?.impacted_asset
				this.incident_data_temp["asset"] = {
					'asset_id': impacted_asset.asset_id,
					'ci_id': impacted_asset.ci_id,
					'ci_name': impacted_asset.ci_name,
					'ip_address': impacted_asset.ip_address,
					'parent_ci_id': impacted_asset.parent_ci_id,
					'hostname': impacted_asset.hostname,
				}
			}
			this._incidentViewService.getReqLatestIncidents(palyload).subscribe((response) => {
				if (response.hasOwnProperty("custom_filed_options")) {
					this.custom_field_config = response.custom_filed_options;
				}
				this.dataDup = response["requester_info"];
			});
		}
	};

	findValue(data: any[], targetValue: string): boolean {
		for (const node of data) {
			if (node.value === targetValue) {
					return true;
			}
			if (node.children) {
				const foundInChildren = this.findValue(node.children, targetValue);
				if (foundInChildren) {
					return true;
				}
		  	}
		}
		return false;
	}
	onSubmitRequester(){
		if(!this.requester_temp?.requester_id){
			this.incident_data_temp['requester_id'] = ''
		}
		this.updateIncident(this.incident_data_temp)
	}
	searchRequester(searchStr, key) {
		this.requesterList = [];
		this.searcRequestLen = searchStr.length;
		if (searchStr == searchStr.length < 0) {
			this.searchFalse = true;
		}
		if (searchStr != undefined && searchStr.length > 2) {
			this.searchFalse = false;
			this._incidentViewService
				.getRequesterList({ full_name: encodeURIComponent(searchStr) })
				.subscribe((response) => {
					this.requesterList = response;
				});
		}
	}
	cancelRequester(){
		this._modalService.dismissAll();
	}
}
