import { Component, ElementRef, EventEmitter, Input, OnInit, Optional, Output, ViewChild } from "@angular/core";
import { RingCentralService } from "app/admin/service/ringcentral.service";
import { CommonService } from "app/services/common.service";
// import WebPhone from "ringcentral-web-phone/lib/src/index";
import { SessionState } from "sip.js";
import CryptoJS from "crypto-js";
import cloneDeep from "lodash-es/cloneDeep";
import { decryptAngularData } from "app/common/utils/utils";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
@Component({
	selector: "app-requester-calling-modal",
	templateUrl: "./requester-calling-modal.component.html",
	styleUrls: ["./requester-calling-modal.component.scss"],
})
export class RequesterCallingModalComponent implements OnInit {
	@Input() requester: any = {};
	webPhone: any;
	session: any;
	call_on_mute: boolean = false;
	start_time: any;
	primaryNumber: any;
	extension: any;
	sender_number: any;

	constructor(
		private _commonService: CommonService, 
		private _ringCentral: RingCentralService,
		private _toastr: ToastrService,
		private _translateService: TranslateService
	) {}

	ngOnInit(): void {
		this.primaryNumber = this.requester?.primary_number;
		this.sender_number = cloneDeep(this.requester?.requester?.contact);
		if (this.requester?.requester?.contact) {
			this.sender_number = this.decryptContactData(this.sender_number);
		}
		let webPhone = this.requester?.webPhone;
		webPhone.userAgent.audioHelper.setVolume(0.5);
		// webPhone.userAgent.on("invite", this.onInvite.bind(this));
		this.webPhone = webPhone;
		webPhone.userAgent.transport.on("connected", () => {
			this.create();
			this.webPhone.userAgent.transport.reconnectAttempts = 0;
		});
		this.webPhone.userAgent.transport.on('disconnected', () => {
			// Disable further reconnection attempts
			this.webPhone.userAgent.transport.reconnectAttempts = 0;
		});
	}

	// disconnect the call
	hangUp = (type:any="") => {
		this.session?.dispose();
		if (type == "terminate" && this._commonService.requester?.value?.["req"]){
			this._toastr.warning(
				this._translateService.instant("Error.err_ring_central_busy"),
				this._translateService.instant("Message.msg_warning"),
				{
					toastClass: "toast ngx-toastr",
					closeButton: true,
					positionClass: "toast-top-right",
				}
			);
		}
		this._commonService.requester.next(null);
		console.log(this._commonService.requester.value, "req---------------")
	};

	// make call to requester
	makeCall(number, homeCountryId) {
		let outboundCall = true;
		homeCountryId = homeCountryId || null;
		this.session = this.webPhone.userAgent.invite(number, {
			fromNumber: this.primaryNumber,
			homeCountryId: homeCountryId,
		});
		this.onAccepted(this.session);
	}
	// on accepting the call
	onAccepted(session) {
		console.log("EVENT: Accepted", session.request);
		console.log("To", session.request.to.displayName, session.request.to.friendlyName);
		console.log("From", session.request.from.displayName, session.request.from.friendlyName);
		session.stateChange.addListener((newState: SessionState) => {
			switch (newState) {
				case SessionState.Initial: {
					console.log("Initial");
					break;
				}
				case SessionState.Establishing: {
					console.log("Establishing");
					this.checkPopup();
					break;
				}
				case SessionState.Established: {
					console.log("Established");
					this.checkPopup();
					this.start_time = new Date();
					break;
				}
				case SessionState.Terminating: {
					console.log("Terminating");
					this.start_time = undefined;
					// Handle session terminating
					break;
				}
				case SessionState.Terminated: {
					console.log("Terminated");
					this.start_time = undefined;
					this.hangUp("terminate");
					// Handle session terminated
					break;
				}
			}
		});
	}
	// create call session
	create = (
		unsavedRingout = {
			from: { phoneNumber: this.primaryNumber },
			to: { phoneNumber: this.sender_number?.number },
			callerId: { phoneNumber: "" }, // optional,
			playPrompt: false, // optional
		}
	) => {
		if (this._commonService.requester?.value?.["req"] != undefined){
			this.makeCall(this.cleanPhoneNumber(unsavedRingout.to.phoneNumber), this.sender_number?.dialCode);
		}
	};
	// Incoming call (not used currently)
	onInvite(session) {
		this.session = session;
		// session.stateChange.addListener((newState: SessionState) => {
		// 	switch (newState) {
		// 		case SessionState.Initial: {
		// 			console.log("Initial");
		// 			break;
		// 		}
		// 		case SessionState.Establishing: {
		// 			console.log("Establishing");
		// 			break;
		// 		}
		// 		case SessionState.Established: {
		// 			console.log("Established");
		// 			break;
		// 		}
		// 		case SessionState.Terminating: {
		// 			console.log("Terminating");
		// 			// Handle session terminating
		// 			break;
		// 		}
		// 		case SessionState.Terminated: {
		// 			console.log("Terminated");
		// 			// Handle session terminated
		// 			break;
		// 		}
		// 	}
		// });
	}

	// on click og hangup
	closeRequesterCalling = () => {
		this._commonService.requester.next(null);
		this.hangUp();
	};

	// function to mute the call
	onMute = () => {
		if (this.call_on_mute) {
			this.session?.unmute();
		} else {
			this.session?.mute();
		}
		this.call_on_mute = !this.call_on_mute;
	};

	// function used to get call interval
	showCallInterval() {
		if (this.start_time) {
			// Calculate the difference between end time and start time in milliseconds
			let endTime = new Date();
			const intervalMs = endTime.getTime() - this.start_time.getTime();
			// Convert milliseconds to seconds for readability
			const intervalSeconds = intervalMs / 1000;
			return this.formatTimeFromSeconds(intervalSeconds);
		} else {
			return "Connecting...";
		}
	}

	formatTimeFromSeconds(totalSeconds: number): string {
		// Calculate hours, minutes, and remaining seconds
		const hours = Math.floor(totalSeconds / 3600);
		const minutes = Math.floor((totalSeconds % 3600) / 60);
		const seconds = Math.floor(totalSeconds % 60);

		// Construct the formatted time string
		const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds
			.toString()
			.padStart(2, "0")}`;

		return formattedTime;
	}

	cleanPhoneNumber(phoneNumber: string): string {
		// Replace any non-digit character with an empty string
		const cleanedPhoneNumber = phoneNumber.replace(/\D/g, "");
		return cleanedPhoneNumber;
	}

	ngOnDestroy() {
		this.hangUp();
		// this._ringCentral
		// 	.logout()
	}

	decryptContactData(data: any): any {
		let enc_contact = data?.number;
		data["number"] = decryptAngularData(enc_contact);
		return data;
	}

	checkPopup(){
		console.log(this._commonService.requester?.value?.["req"])
		if (this._commonService.requester?.value?.["req"] == undefined){
			this.session?.dispose();
		}
	}

	increaseVolume(){
		this.session.userAgent.audioHelper.setVolume(
			(this.session.userAgent.audioHelper.volume !== null ? this.session.userAgent.audioHelper.volume : 0.5) + 0.1,
		);
	}

	decreaseVolume(){
		this.session.userAgent.audioHelper.setVolume(
			(this.session.userAgent.audioHelper.volume !== null ? this.session.userAgent.audioHelper.volume : 0.5) - 0.1,
		);
	}
}
